import {
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
} from '@chakra-ui/react';
import FileUpload from 'components/FileUploader';
import { StepComponentProps } from 'onboarding/onboarding.model';
import { OnboardingContext } from 'onboarding/OnboardingContext';
import { ReactElement, useContext } from 'react';
import { Alert } from 'ui/components/Alert';
import { FlexCol } from 'ui/loulaFlex';
import PX from 'ui/px';

export const DocumentationSubstepThree = ({
    commonStyling,
}: StepComponentProps): ReactElement => {
    const onboardingContext = useContext(OnboardingContext);

    const { formInstance, formData, saveField } = onboardingContext ?? {};

    const { formState } = formInstance ?? {};
    const { errors } = formState ?? {};

    return (
        <Flex {...commonStyling}>
            <FlexCol gap={PX.SPACING.PX.XL} className="internalWrapper">
                <Alert content="These documents were not required for Medi-Cal enrollment, but they are required to get credentialed with Managed Care Plans" />
                <FormControl
                    isInvalid={!!errors?.proofOfLiabilityInsurance}
                    mt={4}
                    isRequired
                >
                    <FormLabel display="none">
                        Proof of Liability Insurance
                    </FormLabel>

                    <FlexCol gap={PX.SPACING.PX.XL}>
                        <FileUpload
                            label="Upload a copy of your practice’s professional liability coverage (with coverage limits). "
                            data-cy="proof-of-liability-insurance"
                            fieldName="proofOfLiabilityInsurance"
                            maxFiles={1}
                            fieldValue={
                                formData?.['proofOfLiabilityInsurance'] ?? []
                            }
                            formInstance={formInstance}
                            saveField={saveField}
                        />
                        <FormErrorMessage>
                            {errors?.proofOfLiabilityInsurance &&
                                errors?.proofOfLiabilityInsurance.message?.toString()}
                        </FormErrorMessage>

                        <FormControl
                            isRequired
                            isInvalid={
                                !!formInstance?.formState.errors
                                    .hipaaCertificate
                            }
                        >
                            <FormLabel display="none">
                                Upload a certificate of completion for HIPAA
                                Training
                            </FormLabel>

                            <FileUpload
                                label="Upload a certificate of completion for HIPAA Training"
                                fieldName="proofOfHIPAATraining"
                                fieldValue={
                                    formData?.['proofOfHIPAATraining'] ?? []
                                }
                                formInstance={formInstance}
                                saveField={saveField}
                                maxFiles={1}
                            />

                            <FormErrorMessage>
                                {formInstance?.formState.errors
                                    .hipaaCertificate &&
                                    formInstance?.formState.errors.hipaaCertificate.message?.toString()}
                            </FormErrorMessage>
                        </FormControl>
                    </FlexCol>
                </FormControl>
            </FlexCol>
        </Flex>
    );
};
