import { Center, Flex, Heading } from '@chakra-ui/react';
import React from 'react';

export class ComingSoon extends React.Component {
    render() {
        return (
            <Flex
                width={'100vw'}
                height={'100vh'}
                alignContent={'center'}
                justifyContent={'center'}
            >
                <Center>
                    <Heading size="lg">Coming soon</Heading>
                </Center>
            </Flex>
        );
    }
}
