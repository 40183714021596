import { Textarea, TextareaProps } from '@chakra-ui/react';
import { forwardRef } from 'react';
import FONTS from 'ui/fonts';
import { FlexCol } from 'ui/loulaFlex';
import PX from 'ui/px';
import { LoulaTextProps, TextInputStyling } from './TextInput';

type TextAreaInputProps = TextareaProps & LoulaTextProps;

export const TextAreaInput = forwardRef<TextareaProps, TextAreaInputProps>(
    ({ label, subLabel, ...areaProps }, ref) => (
        <FlexCol gap={PX.SPACING.REM.XS}>
            {!!label && <FONTS.P1>{label}</FONTS.P1>}
            <Textarea {...TextInputStyling} {...areaProps} ref={ref} />
            {!!subLabel && <FONTS.P2>{subLabel}</FONTS.P2>}
        </FlexCol>
    ),
);
