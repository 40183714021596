import FONTS from 'ui/fonts';
import { SPACING } from 'ui/px';
import { ChangeEvent, useContext, useState } from 'react';
import { FlexCol, FlexRow } from 'ui/loulaFlex';
import { OnboardingContext } from 'onboarding/OnboardingContext';
import { CheckBox } from 'ui/components/Checkbox';
import { nameToShow } from 'util/Utils';
import { ProofOfTrainingPathway } from 'models/constants';

export const OnboardingAttestationScreen = ({
    onAttestationsUpdate,
}: {
    onAttestationsUpdate: (_isComplete: boolean) => void;
}) => {
    const onboardingContext = useContext(OnboardingContext);
    const { formData } = onboardingContext ?? {};

    const [attestationState, setAttestationState] = useState({
        truth: false,
        min_three: false,
        xp: false,
        bg_check: false,
    });

    const showXPAttestation =
        formData?.proofOfTrainingPathway === ProofOfTrainingPathway.XP;

    const onCheckBox =
        (id: keyof typeof attestationState) =>
        (e: ChangeEvent<HTMLInputElement>) => {
            setAttestationState((prevState) => {
                const newState = { ...prevState, [id]: e.target.checked };

                onAttestationsUpdate(
                    newState.truth &&
                        newState.min_three &&
                        newState.bg_check &&
                        (!showXPAttestation || newState.xp),
                );

                return newState;
            });
        };

    return (
        <FlexCol gap={SPACING.PX.M}>
            <FlexRow align="start" gap={SPACING.PX.L}>
                <CheckBox
                    mt={1}
                    isChecked={attestationState.truth}
                    onChange={onCheckBox('truth')}
                />
                <FONTS.P1>
                    I,{' '}
                    <b>
                        {nameToShow(
                            formData?.firstName,
                            formData?.lastName,
                            formData?.preferredName,
                        )}
                    </b>
                    , have reviewed my application and believe all information
                    and attachments are correct, to the best of my knowledge
                </FONTS.P1>
            </FlexRow>

            <FlexRow align="start" gap={SPACING.PX.L}>
                <CheckBox
                    mt={1}
                    isChecked={attestationState.min_three}
                    onChange={onCheckBox('min_three')}
                />
                <FONTS.P1>
                    I,{' '}
                    <b>
                        {nameToShow(
                            formData?.firstName,
                            formData?.lastName,
                            formData?.preferredName,
                        )}
                    </b>
                    , attest that I have provided support at{' '}
                    <b>three or more births</b> in the capacity of a birth
                    doula.
                </FONTS.P1>
            </FlexRow>
            {showXPAttestation && (
                <FlexRow align="start" gap={SPACING.PX.L}>
                    <CheckBox
                        mt={1}
                        isChecked={attestationState.xp}
                        onChange={onCheckBox('xp')}
                    />
                    <FlexCol gap={SPACING.PX.M}>
                        <FONTS.P1>
                            I,{' '}
                            <b>
                                {nameToShow(
                                    formData?.firstName,
                                    formData?.lastName,
                                    formData?.preferredName,
                                )}
                            </b>
                            , attest that I have provided services in the
                            capacity as a Doula in either a paid or volunteer
                            capacity <b>for at least five years</b>.
                        </FONTS.P1>
                        <FONTS.P1>
                            The five years in the capacity as a Doula has
                            occurred within the last seven years from today.{' '}
                        </FONTS.P1>
                    </FlexCol>
                </FlexRow>
            )}

            <FlexRow align="start" gap={SPACING.PX.L}>
                <CheckBox
                    mt={1}
                    isChecked={attestationState.bg_check}
                    onChange={onCheckBox('bg_check')}
                />
                <FlexCol>
                    <FONTS.P1>
                        I consent to an Office of Inspector General screening
                        and applicable state background screening as required.
                    </FONTS.P1>
                </FlexCol>
            </FlexRow>
        </FlexCol>
    );
};

export const useOnboardingAttestations = () => {
    const [showAttestations, setShowAttestations] = useState<boolean>(false);
    const [canSubmitAttestations, setCanSubmitAttestations] =
        useState<boolean>(false);

    return {
        showAttestations,
        setShowAttestations,
        canSubmitAttestations,
        setCanSubmitAttestations,
    };
};
