import { Box, BoxProps } from '@chakra-ui/react';
import COLORS from 'ui/colors';
import PX from 'ui/px';

export const Sheet = ({ children, ...props }: BoxProps) => {
    return (
        <Box
            width="100%"
            height="fit-content"
            background={COLORS.UTIL.Gray.WHITE}
            borderRadius={PX.RADII.LG}
            box-shadow="-2px 3px 3px 3px rgba(186, 186, 186, 0.10)"
            border={`1px solid ${COLORS.UTIL.Gray[500]}`}
            {...props}
        >
            {children}
        </Box>
    );
};
