import {
    Button,
    ButtonGroup,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Tab,
    TabList,
    Tabs,
    useDisclosure,
} from '@chakra-ui/react';
import MainLayout from '../layouts/MainLayout';
import {
    Link,
    Outlet,
    useLocation,
    useNavigate,
    useOutletContext,
    useParams,
} from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { authorize } from '../api/API';
import { getNameToShow } from '../util/Utils';
import { FiEdit2, FiUserX } from 'react-icons/fi';

export function PatientDetail(): JSX.Element | null {
    const { getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();
    const { patientID } = useParams();
    const tabs = [
        {
            name: 'Overview',
            path: 'overview',
        },
        {
            name: 'Claims',
            path: 'visits',
        },
    ];
    const [tabIndex, setTabIndex] = useState(0);
    const [patient, setPatient] = useState<any>(null);

    const location = useLocation();

    const { pathname } = location;
    useEffect(() => {
        const tab = pathname.split('/').slice(-1)[0];
        setTabIndex(tabs.findIndex((t) => t.path === tab));
    });

    useEffect(() => {
        (async () => {
            const token = await getAccessTokenSilently({});
            authorize(token)
                .get(`/patients/${patientID}`)
                .then((res): void => {
                    const patient = res.data;
                    //console.log('patient ', patient);
                    setPatient(res.data);
                });
        })();
    }, []);

    const deactivatePatient = async () => {
        const token = await getAccessTokenSilently({});
        authorize(token)
            .get(`/patients/${patientID}/deactivate`)
            .then((res): void => {
                //console.log('patient ', res.data);
                navigate('/clients');
            });
    };

    const InactivateModal = ({
        patient,
        handleSubmit,
    }: {
        patient: any;
        handleSubmit: () => void;
    }) => {
        const { isOpen, onOpen, onClose } = useDisclosure();
        return (
            <>
                <Button
                    colorScheme="teal"
                    borderColor={'white.500'}
                    onClick={onOpen}
                >
                    <FiUserX />
                </Button>

                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>
                            Mark {patient && getNameToShow(patient.user)} as
                            inactive?
                        </ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            You will no longer be able to log visits against
                            this patient. Any billing in progress will continue,
                            but no new claims will be generated once a patient
                            is inactive.
                        </ModalBody>

                        <ModalFooter>
                            <Button
                                size={'md'}
                                variant="ghost"
                                mr={3}
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                size={'md'}
                                colorScheme="teal"
                                onClick={() => {
                                    handleSubmit();
                                    onClose();
                                }}
                            >
                                Mark Inactive
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </>
        );
    };

    return (
        <MainLayout
            backButtonRoute={'/patients'}
            headerText={patient && getNameToShow(patient.user)}
        >
            <Tabs index={tabIndex} mb={4}>
                <HStack borderBottom={'2px solid'} borderColor={'inherit'}>
                    <TabList w="100%" borderBottom={0}>
                        <Tab as={Link} to="./overview">
                            Overview
                        </Tab>
                        <Tab as={Link} to="./visits">
                            Visits
                        </Tab>
                    </TabList>
                    <ButtonGroup size={'sm'} isAttached mb={2}>
                        <Button
                            colorScheme="teal"
                            onClick={() =>
                                navigate(`/patients/${patient.user.id}/edit`)
                            }
                        >
                            <FiEdit2 />
                        </Button>
                        <InactivateModal
                            patient={patient}
                            handleSubmit={deactivatePatient}
                        />
                    </ButtonGroup>
                </HStack>
            </Tabs>
            <Outlet context={{ patient }} />
        </MainLayout>
    );
}

export function usePatient() {
    return useOutletContext<any>();
}
