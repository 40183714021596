import { Box } from '@chakra-ui/react';
import COLORS from 'ui/colors';

import { LandingGraphic } from './LandingGraphic';
import { LandingDirections } from './LandingDirections';
import { ColRow } from 'ui/loulaFlex';
import { MobileOnlyScrollable } from 'util/Utils';

export const OnboardingLandingPage = () => {
    return (
        <ColRow
            className="onboardingLandingPage"
            width="100vw"
            height="100vh"
            position="fixed"
            background={COLORS.UTIL.Gray.WHITE}
            overflowY={MobileOnlyScrollable}
            overflowX="hidden"
        >
            <LandingGraphic />

            <Box className="landingContentContainer">
                <LandingDirections />
            </Box>
        </ColRow>
    );
};
