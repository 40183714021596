import { HStack, Text, VStack } from '@chakra-ui/react';
import { ReactElement } from 'react';
import {
    useAllContracts,
    useAllPatients,
    useAllProviders,
} from '../../hooks/adonisHooks';

export function BasicDevPortal(): ReactElement | null {
    const { allProviders } = useAllProviders();
    const { allContracts } = useAllContracts();
    const { allPatients } = useAllPatients();

    return (
        <HStack w="100%" alignItems="start">
            <VStack>
                <Text fontWeight="bold">All Test Patients</Text>

                {allPatients
                    ?.sort((a, b) => (a.first_name > b.first_name ? 1 : -1))
                    .map((provider, idx) => {
                        return (
                            <Text key={`provider${idx}`}>
                                {provider.first_name}
                            </Text>
                        );
                    })}
            </VStack>
            <VStack>
                <Text fontWeight="bold">Adonis IDs of Patients</Text>

                {allPatients
                    ?.sort((a, b) => (a.first_name > b.first_name ? 1 : -1))
                    .map((provider, idx) => {
                        return (
                            <Text key={`provider${idx}`}>{provider.id}</Text>
                        );
                    })}
            </VStack>
            <VStack>
                <Text fontWeight="bold">All Providers</Text>

                {allProviders
                    ?.sort((a, b) => (a.npi > b.npi ? 1 : -1))
                    .map((provider, idx) => {
                        return (
                            <Text key={`provider${idx}`}>
                                {provider.first_name}
                            </Text>
                        );
                    })}
            </VStack>
            <VStack>
                <Text fontWeight="bold"> Providers Assignments</Text>

                {allProviders
                    ?.sort((a, b) => (a.npi > b.npi ? 1 : -1))
                    .map((provider, idx) => {
                        return (
                            <Text key={`provider${idx}`}>
                                {provider.contract_assignments
                                    .map(
                                        (fullAssignment) =>
                                            fullAssignment.assignment_id,
                                    )
                                    .join(', ')}
                            </Text>
                        );
                    })}
            </VStack>

            <VStack>
                <Text fontWeight="bold">All Contracts</Text>

                {allContracts?.map((payer, idx) => (
                    <Text key={`payer${idx}`}>{payer.contract_id}</Text>
                ))}
            </VStack>
        </HStack>
    );
}
