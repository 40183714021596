import { Flex } from '@chakra-ui/react';
import { useState } from 'react';
import { PrimaryButton } from 'ui/components/Button';
import { TextInput } from 'ui/components/TextInput';
import PX from 'ui/px';

export const LoginPage = (): React.ReactElement => {
    //Just placeholder state, we'd obv not really store this info like this
    const [email, setEmail] = useState('');
    const [pwd, setPwd] = useState('');

    const handleLogin = () => {
        //TODO
        //console.log('email', email, 'pwd', pwd);
    };

    //console.log('email', email, 'pwd', pwd);

    return (
        <Flex
            className="loginPage"
            width="100vw"
            height="100vh"
            direction="column"
            padding={PX.SPACING.REM.XXL}
            justifyContent="center"
            alignItems="center"
        >
            <TextInput
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <TextInput
                placeholder="Password"
                value={pwd}
                onChange={(e) => setPwd(e.target.value)}
            />
            <PrimaryButton onClick={handleLogin}>Log In</PrimaryButton>
        </Flex>
    );
};
