import { useAuth0 } from '@auth0/auth0-react';
import { useMemo } from 'react';

export function useIsDev() {
    const { user } = useAuth0();

    //TODO: there's a security concern here isn't there...
    const isDev = useMemo(() => {
        const isStaging = import.meta.env.DEV || import.meta.env.VITE_VERCEL_ENV === 'preview';
        return isStaging && user?.email_verified && user?.email?.includes('@joinloula.com');
    }, [user]);

    return isDev;
}
