import { Center, Image } from '@chakra-ui/react';

export function DevOnlyTag({
    width = '24px',
    height = '24px',
}: {
    width?: string;
    height?: string;
}) {
    return (
        <Center width={width} height={height}>
            <Image
                width="100%"
                height="100%"
                src="/icons/do-not-enter.png"
                alt="Dev-Only Tag"
            />
        </Center>
    );
}
