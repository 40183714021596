import { Flex } from '@chakra-ui/react';
import { StepComponentProps } from 'onboarding/onboarding.model';
import { ReactElement, useContext } from 'react';
import AddressForm from 'components/AddressForm';
import { FlexCol, FlexRow } from 'ui/loulaFlex';
import PX from 'ui/px';
import FONTS from 'ui/fonts';
import NPISearch from 'components/NPISearch';
import { FormTextInput } from 'ui/components/Form/FormTextInput';
import {
    FormMultiselect,
    MultiselectOptionType,
} from 'ui/components/Form/FormMultiselect';
import { Pronouns } from 'models/constants';
import { OnboardingContext } from 'onboarding/OnboardingContext';

export const InfoStep = ({
    commonStyling,
}: StepComponentProps): ReactElement => {
    const onboardingContext = useContext(OnboardingContext);

    const { formInstance, formData } = onboardingContext ?? {};

    const { register, formState, setValue, watch } = formInstance ?? {};

    const { errors } = formState ?? {};

    return (
        <Flex {...commonStyling}>
            <FlexCol gap={PX.SPACING.PX.XL} className="internalWrapper">
                <FONTS.H2>Let’s collect some information about you. </FONTS.H2>
                <FlexRow
                    wrap="wrap"
                    gap={PX.SPACING.REM.M}
                    marginBottom={PX.SPACING.REM.XL}
                >
                    <FormTextInput
                        register={register}
                        fieldName="firstName"
                        label="Legal First Name"
                        blockSpecialChars={true}
                        maxLength={32}
                        subLabel="Make sure this name matches what’s on your state-issued ID."
                        errors={errors}
                    />
                    <FormTextInput
                        register={register}
                        fieldName="lastName"
                        blockSpecialChars={true}
                        maxLength={32}
                        label="Legal Last Name"
                        errors={errors}
                    />
                </FlexRow>

                <FlexCol gap={PX.SPACING.REM.XL} width={['100%', null, '50%']}>
                    <FormTextInput
                        register={register}
                        fieldName="middleName"
                        blockSpecialChars={true}
                        label="Middle Name (Optional)"
                        errors={errors}
                        maxLength={32}
                        required={false}
                    />

                    <FormTextInput
                        register={register}
                        fieldName="preferredName"
                        blockSpecialChars={true}
                        required={false}
                        label="Preferred Name (Optional)"
                        maxLength={32}
                        subLabel="We’ll use this·name on our platform when we communicate with you."
                        errors={errors}
                    />

                    <FormMultiselect
                        fieldName="pronouns"
                        label="Pronouns"
                        errors={errors}
                        subLabel="We strive to use gender-inclusive language. 
                We will default to gender-neutral language if no pronouns are listed."
                        setValue={setValue}
                        defaultValue={formData?.pronouns}
                        watch={watch}
                        options={Object.keys(Pronouns)
                            .map((pronounKey): MultiselectOptionType => {
                                return {
                                    label: pronounKey,
                                    value: pronounKey,
                                };
                            })
                            .concat([
                                //TODO-Mina: Finish ability for fully custom pronouns, and for displaying error state
                                // {
                                //     label: 'I want to self-describe',
                                //     value: 'custom',
                                //     clearsOtherChoices: true,
                                //     customPlaceholder:
                                //         'i.e. she, they, ze, he/they',
                                // },
                                {
                                    label: 'I prefer not to say',
                                    value: 'undeclared',
                                    isExclusive: true,
                                },
                            ])}
                    />

                    <FormTextInput
                        register={register}
                        fieldName="dob"
                        dateMask={{
                            min: new Date(1900, 0, 1),
                            max: new Date(2024, 0, 1),
                        }}
                        label="Date of Birth"
                        placeholder="MM/DD/YYYY"
                        requiredWarningText="Enter a valid date in MM/DD/YYY format."
                        errors={errors}
                    />

                    <FormTextInput
                        fieldName="tin"
                        isSecret
                        mask="XXX XX XXXX"
                        setValue={setValue}
                        defaultValue={formData?.tin}
                        autoComplete="off"
                        requiredWarningText="Enter a valid 9-digit Social Security Number."
                        label="Social Security Number"
                        placeholder="123 45 6789"
                        errors={errors}
                        minLength={11}
                    />

                    <NPISearch
                        initialValue={formData?.npi?.toString() ?? ''}
                        setValue={setValue}
                    />

                    <FormTextInput
                        register={register}
                        fieldName="phone"
                        mask="(000) 000-0000"
                        requiredWarningText="Enter a valid 10-digit phone number (including the area code). "
                        label="Phone Number"
                        placeholder="(123) 456-7890"
                        minLength={14}
                        subLabel="If we have issues with your application, we will contact this number."
                        errors={errors}
                    />
                </FlexCol>

                <AddressForm
                    label="Personal Mailing Address"
                    subLabel="You should be able to receive packages at this address. This is the address we’ll use to mail things like Loula-specific gear. You’ll have the opportunity to provide a business address in the “Business Information” section. "
                    namePrefix="personalAddress"
                    requiredWarningText="Please enter a valid mailing address."
                    watch={watch}
                    errors={errors}
                    register={register}
                    setValue={setValue}
                />
            </FlexCol>
        </Flex>
    );
};
