import { useColorModeValue, VStack } from '@chakra-ui/react';
import { ReactElement } from 'react';
import MainLayout from '../layouts/MainLayout';
import PanelHeading from '../components/PanelHeading';
import { AdonisDecoupleTestingUI } from '../components/devPortal/AdonisDecoupleTestingUI';
import { BasicDevPortal } from '../components/devPortal/BasicDevPortal';
import { DesignSystemDev } from 'ui/DesignSystemDev';

export function DevPortal(): ReactElement {
    return (
        <MainLayout>
            <VStack
                justifyContent={'center'}
                bg={useColorModeValue('white', 'gray.900')}
                border={'1px'}
                p={8}
                borderColor={useColorModeValue('gray.200', 'gray.700')}
                align={'center'}
                mb={6}
                w="100%"
            >
                <DesignSystemDev />
                <PanelHeading>Dev Portal</PanelHeading>
                <BasicDevPortal />
                <AdonisDecoupleTestingUI />
            </VStack>
        </MainLayout>
    );
}
