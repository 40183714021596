import {
    CheckboxProps,
    FormControl,
    FormErrorMessage,
    FormLabel,
} from '@chakra-ui/react';
import {
    FieldErrors,
    FieldValues,
    UseFormSetValue,
    UseFormWatch,
} from 'react-hook-form';
import { CheckBox } from '../Checkbox';
import FONTS from 'ui/fonts';
import { FlexRow } from 'ui/loulaFlex';
import PX from 'ui/px';

export function FormCheckboxInput({
    fieldName,
    label,
    errors,
    setValue,
    isRequired = true,
    defaultValue,
    watch,
    blockingOnCheckedTrue,
    ...props
}: CheckboxProps & {
    label: string;
    fieldName: string;
    errors: FieldErrors<FieldValues> | undefined;
    setValue: UseFormSetValue<FieldValues> | undefined;
    isRequired?: boolean;
    defaultValue?: boolean;
    watch: UseFormWatch<FieldValues> | undefined;
    blockingOnCheckedTrue?: () => void; //For when you need to run some process before allowing user to check
}) {
    const watchField = watch?.(fieldName, defaultValue);

    return (
        <FormControl
            flex={1}
            isInvalid={!!errors?.[fieldName]}
            isRequired={isRequired}
        >
            <FormLabel display="none" htmlFor={fieldName}>
                {label}
            </FormLabel>
            <FlexRow gap={PX.SPACING.PX.S}>
                <CheckBox
                    isChecked={watchField}
                    onChange={(e) => {
                        if (e.target.checked && blockingOnCheckedTrue) {
                            blockingOnCheckedTrue();
                            return;
                        }
                        props?.onChange?.(e);
                        setValue?.(fieldName, e.target.checked, {
                            shouldDirty: true,
                        });
                    }}
                    {...props}
                >
                    <FONTS.P1>{label}</FONTS.P1>
                </CheckBox>
            </FlexRow>

            <FormErrorMessage>
                {errors?.[fieldName] && errors?.[fieldName].message?.toString()}
            </FormErrorMessage>
        </FormControl>
    );
}
