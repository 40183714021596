import { Box, Image, SlideFade } from '@chakra-ui/react';
import { OnboardingStatus } from 'models/constants';
import {
    LANDING_GRAPHIC_ENTRANCE_DURATION,
    LANDING_TEXT_ENTRANCE_DURATION,
} from 'models/constants/animationConstants';
import { OnboardingStatusContext } from 'onboarding/OnboardingContext';
import { ALL_STEPS } from 'onboarding/StepsDefinitions';
import { useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import COLORS from 'ui/colors';
import { OrangeButton } from 'ui/components/Button';
import FONTS from 'ui/fonts';
import { ColRow, FlexCol } from 'ui/loulaFlex';
import PX from 'ui/px';
import { Zyg } from 'util/Utils';

//TODO-Mina: the responsiveness here is nearly right, but the mobile spacing still looks slightlyyy off
export const LandingDirections = () => {
    const { onClickStartApplication, isStarting } = useLandingPage();

    return (
        <Box width="100%" height="100%">
            <SlideFade
                style={{ width: '100%', height: '100%', minHeight: 0 }}
                in={true}
                offsetX={PX.NUM.PX.L}
                offsetY={0}
                transition={{
                    enter: {
                        delay: LANDING_GRAPHIC_ENTRANCE_DURATION,
                        duration: LANDING_TEXT_ENTRANCE_DURATION,
                    },
                }}
            >
                <FlexCol
                    width="fit-content"
                    height={Zyg(null, '100%')}
                    className="landingContentInnerContainer"
                    justify="center"
                    alignItems={Zyg('center', 'start')}
                    margin="auto"
                    paddingBottom={Zyg(PX.SPACING.PX.XXL, 0)}
                    paddingX={Zyg(PX.SPACING.PX.L, PX.SPACING.PX.XL)}
                    gap={Zyg(PX.SPACING.PX.M, PX.SPACING.PX.XL)}
                >
                    <FONTS.H2
                        paddingY={Zyg(PX.SPACING.PX.L, 0)}
                        paddingX={Zyg(PX.SPACING.PX.L, 0)}
                        textAlign={Zyg('center', 'start')}
                        color={COLORS.UTIL.PRIMARY.Warning}
                    >
                        Thanks for creating a Loula account!
                    </FONTS.H2>

                    <FlexCol gap={Zyg(PX.SPACING.PX.XL, PX.SPACING.PX.XL)}>
                        <FONTS.H4 textAlign={Zyg('center', 'start')}>
                            For your Loula application, you’ll need
                        </FONTS.H4>
                        <ColRow
                            marginY={[PX.SPACING.PX.XL, 0]}
                            align={Zyg('center', 'start')}
                            gap={Zyg(PX.SPACING.PX.M, PX.SPACING.PX.XL)}
                        >
                            <Box
                                objectFit="contain"
                                maxW={Zyg('40%', '120px')}
                                width="100%"
                            >
                                <Image
                                    objectFit="contain"
                                    src={'/images/IDGraphic.png'}
                                    alt={'ID Required'}
                                />
                            </Box>
                            <FlexCol maxW="440px" gap={PX.SPACING.PX.S}>
                                <FONTS.H4 color={COLORS.UTIL.PRIMARY.Warning}>
                                    A physical copy of a government photo ID.
                                </FONTS.H4>
                                <FONTS.P1>
                                    You’ll be asked to take a picture of the
                                    front and back of this ID to verify your
                                    identity.
                                </FONTS.P1>
                            </FlexCol>
                        </ColRow>

                        <ColRow
                            align={Zyg('center', 'start')}
                            gap={PX.SPACING.PX.XL}
                        >
                            <Box
                                maxW={Zyg('40%', '120px')}
                                objectFit="contain"
                                width="100%"
                            >
                                <Image
                                    objectFit="contain"
                                    src={'/images/DocumentsGraphic.png'}
                                    alt={'Upload Required'}
                                />
                            </Box>
                            <FlexCol
                                maxW="440px"
                                gap={[
                                    PX.SPACING.PX.M,
                                    null,
                                    null,
                                    null,
                                    PX.SPACING.PX.S,
                                ]}
                            >
                                <FONTS.H4 marginBottom={PX.SPACING.PX.XS}>
                                    Digital copies of
                                </FONTS.H4>

                                <FONTS.H4 color={COLORS.UTIL.PRIMARY.Warning}>
                                    <li>
                                        Medi-Cal Provider Enrollment Approval
                                        Letter
                                    </li>
                                </FONTS.H4>

                                <FONTS.H4 color={COLORS.UTIL.PRIMARY.Warning}>
                                    <li>Proof of Training</li>
                                </FONTS.H4>

                                <FONTS.P1 paddingLeft={PX.SPACING.PX.L}>
                                    Certificate of Doula Training or Letters of
                                    Recommendation from qualified providers.
                                </FONTS.P1>

                                <FONTS.H4 color={COLORS.UTIL.PRIMARY.Warning}>
                                    <li>Certificate of CPR Training</li>
                                </FONTS.H4>

                                <FONTS.H4 color={COLORS.UTIL.PRIMARY.Warning}>
                                    <li>Certificate of HIPAA Training</li>
                                </FONTS.H4>

                                <FONTS.H4 color={COLORS.UTIL.PRIMARY.Warning}>
                                    <li>Liability Insurance Coverage</li>
                                </FONTS.H4>

                                <FONTS.P1 paddingLeft={PX.SPACING.PX.L}>
                                    Up to a million in coverage.
                                </FONTS.P1>

                                <FONTS.P1 marginTop={PX.SPACING.PX.XS}>
                                    PDF, JPG, PNG, and DOCX files up to 20 MB
                                    are accepted.
                                </FONTS.P1>
                            </FlexCol>
                        </ColRow>
                    </FlexCol>
                    <OrangeButton
                        isLoading={isStarting}
                        onClick={onClickStartApplication}
                    >
                        Start Application
                    </OrangeButton>
                </FlexCol>
            </SlideFade>
        </Box>
    );
};

const useLandingPage = () => {
    const navigate = useNavigate();
    const statusContext = useContext(OnboardingStatusContext);
    const [isStarting, setIsStarting] = useState(false);

    const { updateOnboardingStatus } = statusContext ?? {};

    const onClickStartApplication = useCallback(async () => {
        setIsStarting(true);
        await updateOnboardingStatus?.(OnboardingStatus.STARTED);
        navigate(`/onboarding/${ALL_STEPS[0].slug}`, { replace: true });
    }, [updateOnboardingStatus, navigate]);

    return { onClickStartApplication, isStarting };
};
