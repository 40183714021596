import {
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    useRadioGroup,
} from '@chakra-ui/react';
import FileUpload from 'components/FileUploader';
import { StepComponentProps } from 'onboarding/onboarding.model';
import { OnboardingContext } from 'onboarding/OnboardingContext';
import { ReactElement, useContext } from 'react';
import { Radio } from 'ui/components/Radio';
import FONTS from 'ui/fonts';
import { FlexCol, FlexRow } from 'ui/loulaFlex';
import PX from 'ui/px';
import { ICONS } from 'ui/icons';
import COLORS from 'ui/colors';

export const DocumentationSubstepTwo = ({
    commonStyling,
}: StepComponentProps): ReactElement => {
    const onboardingContext = useContext(OnboardingContext);

    const { formInstance, formData, saveField } = onboardingContext ?? {};

    const { formState, setValue, watch } = formInstance ?? {};
    const { errors } = formState ?? {};

    const pathwayChoice: string = watch?.(
        'proofOfTrainingPathway',
        formData?.proofOfTrainingPathway,
    );

    const { getRadioProps, getRootProps } = useRadioGroup({
        defaultValue: formData?.proofOfTrainingPathway,
        onChange: (newVal: string) => {
            //console.log('NEWVAL', newVal);
            setValue?.('proofOfTrainingPathway', newVal);
        },
    });

    return (
        <Flex {...commonStyling}>
            <FlexCol gap={PX.SPACING.PX.XL} className="internalWrapper">
                <FONTS.H2>
                    Which pathway did you select when you enrolled as a Medi-Cal
                    provider?
                </FONTS.H2>
                <FlexCol {...getRootProps()} gap={PX.SPACING.REM.S}>
                    <Radio
                        {...getRadioProps({ value: 'training' })}
                        value="training"
                    >
                        <FONTS.P1 flex={1}>
                            <b>Training Pathway </b> - In the PAVE portal, I
                            uploaded a Certificate of Completion and/or Course
                            Syllabi.
                        </FONTS.P1>
                    </Radio>
                    <Radio {...getRadioProps({ value: 'xp' })} value="xp">
                        <FONTS.P1>
                            <b>Experience Pathway </b>- In the PAVE portal, I
                            uploaded letters of recommendation.
                        </FONTS.P1>
                    </Radio>
                </FlexCol>

                {pathwayChoice === 'training' && (
                    <FormControl
                        isInvalid={!!errors?.syllabus}
                        mt={4}
                        isRequired
                    >
                        <FormLabel display="none">Syllabus</FormLabel>

                        <FileUpload
                            label="Upload your Certificate(s) of Completion and/or Course Syllabi for doula training."
                            sublabelComponent={
                                <FlexCol gap={PX.SPACING.PX.M}>
                                    <FlexRow
                                        background={
                                            COLORS.UTIL.WarningOrange[200]
                                        }
                                        borderRadius={PX.RADII.MD}
                                        padding={PX.SPACING.PX.M}
                                        gap={PX.SPACING.PX.M}
                                    >
                                        <ICONS.WarningIcon
                                            boxSize={5}
                                            color={
                                                COLORS.UTIL.WarningOrange[700]
                                            }
                                        />
                                        <FONTS.P1>
                                            {' '}
                                            Make sure your uploaded documents
                                            meet the following requirements:{' '}
                                        </FONTS.P1>
                                    </FlexRow>

                                    <FONTS.P1>
                                        Your certificate(s) should show a
                                        minimum of <b>16 hours of training</b>{' '}
                                        in the following areas:
                                    </FONTS.P1>
                                    <FONTS.P1>
                                        <li>Lactation support</li>
                                        <li>Childbirth education</li>
                                        <li>
                                            Foundations on anatomy or pregnancy
                                            and childbirth
                                        </li>
                                        <li>
                                            Nonmedical comfort measures,
                                            prenatal support, and labor support
                                            techniques
                                        </li>
                                        <li>
                                            Developing a community resource list
                                        </li>
                                    </FONTS.P1>
                                    <FONTS.P1>
                                        If you do not have a certificate that
                                        includes the course hours completed, you
                                        may <b>upload a syllabus</b> that
                                        demonstrates course hours completed in
                                        the above areas.{' '}
                                    </FONTS.P1>
                                    <FONTS.P1>
                                        You may upload multiple documents.
                                    </FONTS.P1>
                                </FlexCol>
                            }
                            data-cy="syllabus"
                            fieldName="syllabus"
                            maxFiles={10}
                            fieldValue={formData?.['syllabus'] ?? []}
                            formInstance={formInstance}
                            saveField={saveField}
                        />

                        <FormErrorMessage>
                            {errors?.proofOfLiabilityInsurance &&
                                errors.proofOfLiabilityInsurance.message?.toString()}
                        </FormErrorMessage>
                    </FormControl>
                )}

                {pathwayChoice === 'xp' && (
                    <FormControl
                        isRequired
                        isInvalid={!!errors?.hipaaCertificate}
                    >
                        <FormLabel display="none">
                            Upload Letters of Recommendation
                        </FormLabel>

                        <FileUpload
                            label="Upload Letters of Recommendation"
                            subLabel="Upload three client testimonial letters or professional letters of recommendation written in the last seven years from any of the following: 

                        A physician, nurse practitioner, nurse midwife, or licensed midwife

                        An enrolled doula

                        A community-based organization

                        At least one of the letters must be from a licensed provider, an enrolled doula, or a community-based organization. "
                            fieldName="lettersOfRecommendation"
                            fieldValue={
                                formData?.['lettersOfRecommendation'] ?? []
                            }
                            formInstance={formInstance}
                            saveField={saveField}
                            maxFiles={5}
                        />

                        <FormErrorMessage>
                            {errors?.hipaaCertificate &&
                                errors.hipaaCertificate.message?.toString()}
                        </FormErrorMessage>
                    </FormControl>
                )}
            </FlexCol>
        </Flex>
    );
};
