import { useAuth0 } from '@auth0/auth0-react';
import { FormEvent, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { authorize } from '../api/API';
import MainLayout from '../layouts/MainLayout';
import {
    Box,
    Button,
    Flex,
    FormControl,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Skeleton,
    Spacer,
    Text,
    VStack,
    useColorModeValue,
    useDisclosure,
    useToast,
} from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Link as ChakraLink } from '@chakra-ui/react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import PanelHeading from '../components/PanelHeading';
import { FiPlus } from 'react-icons/fi';
import { getTotalChargesString } from '../util/Utils';
import { ClaimReturnType } from '../models/schemas';

const ModalForm = ({
    handlePatientSelected,
}: {
    handlePatientSelected: (patient: any) => void;
}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { getAccessTokenSilently } = useAuth0();

    const [options, setOptions] = useState<any[] | undefined>([]);
    const [selectedPatient, setSelectedPatient] = useState<any | undefined>(
        undefined,
    );

    const onSubmit = (e: SubmitEvent) => {
        e.preventDefault();

        if (selectedPatient === undefined) {
            return;
        }
        handlePatientSelected(selectedPatient);
        onClose();
    };

    // get list of patients
    useEffect(() => {
        (async () => {
            const token = await getAccessTokenSilently();
            authorize(token)
                .get(`/patients`)
                .then((res): void => {
                    const patients = res.data;
                    // @todo: fix any type
                    const options = patients.map((c: any) => {
                        return {
                            label: c.patient.user.preferredName
                                ? c.patient.user.preferredName
                                : c.patient.user.firstName +
                                ' ' +
                                c.patient.user.lastName,
                            value: c.patient.userId,
                        };
                    });
                    setOptions(options);
                });
        })();
    }, []);

    return (
        <Box>
            <Button onClick={onOpen} leftIcon={<FiPlus />}>
                Create Claim
            </Button>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Select Patient</ModalHeader>
                    <ModalCloseButton />
                    <form
                        onSubmit={(e: FormEvent<HTMLFormElement>) =>
                            onSubmit(e as any)
                        }
                    >
                        <ModalBody>
                            <FormControl>
                                <Select
                                    options={options}
                                    name="patient"
                                    onChange={(patient) =>
                                        setSelectedPatient(patient)
                                    }
                                />
                            </FormControl>
                        </ModalBody>

                        <ModalFooter>
                            <Button colorScheme="blue" mr={3} type="submit">
                                Start
                            </Button>
                            <Button variant="ghost" onClick={onClose}>
                                Cancel
                            </Button>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>
        </Box>
    );
};

/**
 * This is an example of a generic "Resource Display" page.
 * It uses the Medplum `<ResourceTable>` component to display a resource.
 * @returns A React component that displays a resource.
 */
export function Claims(): JSX.Element | null {
    const { getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();
    const [claims, setclaims] = useState<ClaimReturnType[] | null>(null);

    const token = getAccessTokenSilently();
    const [searchParams] = useSearchParams();
    const toast = useToast();

    const patientCreatedToastID = 'claim-created-toast';

    useEffect(() => {
        // please fix this!
        if (token == null) {
            return;
        }

        (async () => {
            const token = await getAccessTokenSilently();
            authorize(token)
                .get(`/claims`)
                .then((res): void => {
                    const claims = res.data;
                    //console.log('claims ', claims);
                    setclaims(claims);
                });
        })();
    }, []);

    useEffect(() => {
        if (
            searchParams.get('created') &&
            !toast.isActive(patientCreatedToastID)
        ) {
            toast({
                id: patientCreatedToastID,
                title: 'Claim created.',
                status: 'success',
                duration: 9000,
                isClosable: true,
            });
        }
    }, []);

    const handlePatientSelected = (patient: any) => {
        //console.log('patient selected', patient);
        navigate(`/claims/${patient.value}/create`);
    };

    return (
        <MainLayout>
            <VStack
                bg={useColorModeValue('white', 'gray.900')}
                border={'1px'}
                p={8}
                borderColor={useColorModeValue('gray.200', 'gray.700')}
                align={'start'}
                mb={6}
            >
                <HStack width="100%">
                    <PanelHeading>Claims</PanelHeading>
                    <Spacer />
                    <ModalForm handlePatientSelected={handlePatientSelected} />
                </HStack>

                <Box width={'100%'}>
                    <Flex flexDirection={'column'} gap={4}>
                        <HStack
                            direction="row"
                            spacing={4}
                            justify="right"
                        ></HStack>
                        <Skeleton isLoaded={!!claims}>
                            <TableContainer width={'100%'}>
                                <Table variant="simple">
                                    <Thead>
                                        <Tr>
                                            <Th>Id</Th>
                                            <Th>Name</Th>
                                            <Th>Email</Th>
                                            <Th>Total Charges</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {claims?.map((claim) => (
                                            <Tr key={claim.id}>
                                                <Td>
                                                    <Text as="kbd">
                                                        <ChakraLink
                                                            as={ReactRouterLink}
                                                            to={`/claims/${claim.id}`}
                                                        >
                                                            {claim.id}
                                                        </ChakraLink>
                                                    </Text>
                                                </Td>
                                                <Td>
                                                    {claim.patient?.user
                                                        .preferredName
                                                        ? claim.patient.user
                                                            .preferredName
                                                        : claim.patient?.user
                                                            .firstName}{' '}
                                                    {
                                                        claim.patient?.user
                                                            ?.lastName
                                                    }
                                                </Td>
                                                <Td>
                                                    {claim.patient?.user?.email}
                                                </Td>
                                                <Td>
                                                    {getTotalChargesString(
                                                        claim.serviceLines,
                                                    )}
                                                </Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                            </TableContainer>
                        </Skeleton>
                    </Flex>
                </Box>
            </VStack>
        </MainLayout>
    );
}
