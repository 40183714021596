import { WarningIcon } from '@chakra-ui/icons';
import { FlexProps } from '@chakra-ui/react';
import COLORS from 'ui/colors';
import FONTS from 'ui/fonts';
import { FlexRow } from 'ui/loulaFlex';
import PX from 'ui/px';

export const Alert = ({ content }: FlexProps & { content: string }) => {
    return (
        <FlexRow
            width="100%"
            align="center"
            padding={PX.SPACING.REM.M}
            gap={PX.SPACING.REM.M}
            background="rgba(255, 138, 0, 0.20)"
            borderRadius={PX.RADII.MD}
        >
            <WarningIcon boxSize={5} color={COLORS.UTIL.PRIMARY.Warning} />
            <FONTS.P1>{content}</FONTS.P1>
        </FlexRow>
    );
};
