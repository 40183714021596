import { Outlet, useNavigate, useOutletContext } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useIsDev } from '../hooks/useIsDev';
import Loading from './Loading';
import { useProvider } from '../components/PrivateRoute';

declare global {
    interface Window {
        dataLayer: any[];
    }
}

const DevRoute = () => {
    const isDev = useIsDev();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const provider = useProvider();

    useEffect(
        function EnforceDevOnly() {
            if (!isDev) {
                // Redirect to home page
                navigate('/');
            }
            setLoading(false);
        },
        [isDev],
    );

    if (!loading) return <Outlet context={{ provider } satisfies any} />;
    return <Loading />;
};

export default DevRoute;

export function useDevProvider() {
    return useOutletContext<any>();
}
