// Chakra imports
import { useToast } from 'ui/useToast';
import React from 'react';
// Assets
import {
    FileRejection,
    useDropzone,
    FileError,
    ErrorCode,
} from 'react-dropzone';
import COLORS from 'ui/colors';
import { BaseButton } from 'ui/components/Button';
import FONTS from 'ui/fonts';
import { ICONS } from 'ui/icons';
import { FlexCol } from 'ui/loulaFlex';
import PX from 'ui/px';

function Dropzone({
    onDropAccepted,
    maxFiles = 0, // unlimited
    ...props
}: {
    onDropAccepted: (_files: File[]) => void;
    maxFiles: number;
    [x: string]: unknown;
}) {
    const toast = useToast();

    const _onDropAccepted = (files: File[]) => {
        if (onDropAccepted) onDropAccepted(files);
    };

    const _onDropRejected = (files: FileRejection[]) => {
        files.every((file) => {
            return file.errors.every((err) => {
                return diplayError(err);
            });
        });
    };

    const diplayError = (error: FileError): boolean => {
        let message = error.message;
        let displayMore = true;

        switch (error.code) {
            case ErrorCode.FileInvalidType:
                message =
                    'File type not supported. Upload a JPG, PNG, PDF, or DOCX file.';
                displayMore = false;
                break;
            case ErrorCode.TooManyFiles:
                message = 'Too many files.';
                displayMore = false;
                break;
            case ErrorCode.FileTooLarge:
                message = 'File is larger than 20 MB.';
                break;
            case ErrorCode.FileTooSmall:
                message = 'File is too small.';
            default:
                break;
        }

        toast({
            description: message,
            status: 'error',
            duration: 5000,
            isClosable: true,
        });

        return displayMore;
    };

    const { getInputProps, getRootProps, isDragActive } = useDropzone({
        accept: {
            'image/*': ['.png', '.jpeg', '.jpg'],
            'application/pdf': ['.pdf'],
            'application/msword': ['.doc'],
            ' application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                ['.docx'],
        },
        onDropAccepted: _onDropAccepted,
        onDropRejected: _onDropRejected,
        maxFiles,
        multiple: maxFiles !== 1,
        maxSize: 10485760 * 2, // 20MB
    });

    return (
        <FlexCol
            data-cy={props['data-cy']}
            transition="all 0.3s"
            align="center"
            justify="center"
            bg={isDragActive ? COLORS.UTIL.Gray[50] : COLORS.UTIL.Gray.LIGHT}
            border={`1px dashed ${COLORS.UTIL.Gray.PRIMARY}`}
            borderRadius={PX.RADII.BASE}
            w="100%"
            maxW="640"
            minH="100%"
            paddingX={[PX.SPACING.REM.M, null]}
            textAlign="center"
            _hover={{
                bg: isDragActive ? COLORS.UTIL.Gray[50] : COLORS.UTIL.Gray[400],
            }}
            cursor="pointer"
            {...getRootProps({ className: 'dropzone' })}
            {...props}
        >
            <input {...getInputProps({})} hidden />

            <FlexCol
                minH={90}
                align="center"
                justify="space-between"
                paddingY={PX.SPACING.REM.L}
                gap={PX.SPACING.REM.M}
            >
                <ICONS.UploadIcon
                    transition="all 0.3s"
                    opacity={isDragActive ? 0 : 1}
                    boxSize={10}
                    color={COLORS.STROKES.HEAVY}
                />

                <FONTS.H3 color={COLORS.UTIL.Gray[800]}>
                    Choose a file or drag & drop here
                </FONTS.H3>
                <FONTS.P2 color={COLORS.UTIL.Gray[800]}>
                    JPG, PNG, PDF, DOCX formats accepted, up to 20 MB
                </FONTS.P2>

                <BaseButton
                    transition="all 0.3s"
                    opacity={isDragActive ? 0 : 1}
                    cursor="pointer"
                    _hover={{ bg: COLORS.UTIL.Gray[500] }}
                    _active={{ bg: COLORS.UTIL.Gray[600] }}
                >
                    Browse File
                </BaseButton>
            </FlexCol>
        </FlexCol>
    );
}

export default Dropzone;
