import { Progress, ProgressProps } from '@chakra-ui/react';

//TODO: loulafy this
export const ProgressBar = ({
    total,
    current,
}: ProgressProps & {
    total: number;
    current: number;
}) => {
    return <Progress colorScheme="blue" value={current} max={total} />;
};
