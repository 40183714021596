import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { authorize } from '../api/API';
import MainLayout from '../layouts/MainLayout';
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    HStack,
    Heading,
    Input,
    Select,
    SkeletonText,
    Spacer,
    Text,
    VStack,
    useColorModeValue,
    useToast,
} from '@chakra-ui/react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { VISIT_TYPES } from '../api/APIConstants';
import dayjs from 'dayjs';
import { faker } from '@faker-js/faker';

export function BulkCreateVisits(): JSX.Element {
    const { getAccessTokenSilently } = useAuth0();
    const { patientID } = useParams();
    const navigate = useNavigate();
    const [patient, setPatient] = useState<any>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | undefined>(undefined);
    const toast = useToast();

    const {
        control,
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
        setValue,
    } = useForm();

    const { fields, append } = useFieldArray({
        control,
        name: 'visits',
    });

    useEffect(() => {
        // please fix this!
        (async () => {
            const token = await getAccessTokenSilently({});
            authorize(token)
                .get(`/patients/${patientID}`)
                .then((res) => {
                    const patient = res.data;
                    setPatient(patient);
                    setLoading(false);
                })
                .catch((err) => {
                    setError(err.message);
                    setLoading(false);
                });
        })();
    }, []);

    const onSubmit = async (data: any) => {
        alert('coming soon');
        return;

        const token = await getAccessTokenSilently({});
        authorize(token)
            .post(`/patients/${patientID}/visits`, data.visits)
            .then(() => {
                toast({
                    title: 'Visits created',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                navigate(`/clients/${patientID}`);
            })
            .catch((err) => {
                toast({
                    title: 'Error creating visits',
                    description: err.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            });
    };

    const appendServiceLine = () => {
        append({ procedureCode: '', quantity: 1 });
    };

    const demoData = () => {
        for (let i = 0; i < 5; i++) {
            appendServiceLine();
            setValue(
                `serviceLines.${i}.dateOfService`,
                dayjs(faker.date.past()).format('MM/DD/YYYY'),
            );
        }
    };

    if (loading) {
        return (
            <MainLayout>
                <VStack>
                    <SkeletonText noOfLines={4} spacing="4" />
                    <SkeletonText noOfLines={4} spacing="4" />
                    <SkeletonText noOfLines={4} spacing="4" />
                </VStack>
            </MainLayout>
        );
    }

    if (error) {
        return (
            <MainLayout>
                <Box>
                    <Text>Error: {error}</Text>
                </Box>
            </MainLayout>
        );
    }

    return (
        <MainLayout>
            <VStack
                bg={useColorModeValue('white', 'gray.900')}
                border={'1px'}
                p={8}
                borderColor={useColorModeValue('gray.200', 'gray.700')}
                align={'start'}
            >
                <Box width="100%">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <HStack mt={4} width="100%">
                            <Heading size="md">Bulk Log Visits</Heading>
                            <Spacer />
                            <Button onClick={demoData} colorScheme="orange">
                                Demo Data
                            </Button>
                            <Button onClick={() => appendServiceLine()}>
                                Add Visit
                            </Button>
                        </HStack>

                        {fields.map((field, index) => (
                            <HStack mt={4} key={`serviceLineRow${index}`}>
                                <FormLabel
                                    htmlFor="dobMonth"
                                    mt={index === 0 ? 9 : 2}
                                >
                                    {index + 1}.
                                </FormLabel>

                                <FormControl>
                                    {index === 0 && (
                                        <FormLabel>Visit Type</FormLabel>
                                    )}
                                    <Select
                                        id="visitType"
                                        {...register('visitType', {
                                            required: 'This is required',
                                            minLength: {
                                                value: 2,
                                                message:
                                                    'Minimum length should be 2',
                                            },
                                        })}
                                    >
                                        {VISIT_TYPES.map((type) => (
                                            <optgroup label={type.groupName}>
                                                {type.options.map((option) => (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </optgroup>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl
                                    isRequired
                                    isInvalid={
                                        !!errors[
                                            `serviceLines.${index}.dateOfService`
                                        ]
                                    }
                                >
                                    {index === 0 && (
                                        <FormLabel>Date of Service</FormLabel>
                                    )}
                                    <Controller
                                        name={`serviceLines.${index}.dateOfService`}
                                        control={control}
                                        rules={{
                                            required: 'This is required',
                                            minLength: {
                                                value: 1,
                                                message:
                                                    'Minimum length should be 1',
                                            },
                                            pattern:
                                                /\d{1,2}\/\d{1,2}\/\d{2,4}/,
                                        }}
                                        render={({ field }) => (
                                            <Input
                                                as={InputMask}
                                                mask="**/**/****"
                                                id="dobMonth"
                                                placeholder="MM/DD/YYYY"
                                                {...field}
                                            />
                                        )}
                                    />
                                </FormControl>

                                {/* <FormControl>
                                    {index === 0 && <FormLabel>Qty</FormLabel>}
                                    <Input
                                        id="dobDay"
                                        placeholder="1"
                                        {...register(
                                            `serviceLines.${index}.quantity`,
                                            {
                                                // required: 'This is required',
                                                minLength: {
                                                    value: 1,
                                                    message:
                                                        'Minimum length should be 1',
                                                },
                                            },
                                        )}
                                    />
                                </FormControl> */}
                            </HStack>
                        ))}

                        <input
                            type="hidden"
                            {...register('patientUserId')}
                            value={patientID}
                        />

                        <Button
                            mt={4}
                            colorScheme="teal"
                            isLoading={isSubmitting}
                            type="submit"
                        >
                            Submit
                        </Button>
                    </form>
                </Box>
            </VStack>
        </MainLayout>
    );
}
