import { useAuth0 } from '@auth0/auth0-react';
import COLORS from 'ui/colors';
import FONTS from 'ui/fonts';
import { FlexRow } from 'ui/loulaFlex';
import PX, { SPACING } from 'ui/px';
import { Sheet } from './Sheet';
import { useState } from 'react';
import { SlideFade } from '@chakra-ui/react';
import { shutdown } from "@intercom/messenger-js-sdk";

export const Avatar = ({ initials }: { initials: string }) => {
    const { logout } = useAuth0();

    const [showLogout, setShowLogout] = useState(false);
    const [activeTimeout, setActiveTimeout] = useState<NodeJS.Timeout | null>(
        null,
    );

    const onMouseLeave = () => {
        if (activeTimeout != null) {
            clearTimeout(activeTimeout);
        }
        setActiveTimeout(
            setTimeout(() => {
                setShowLogout(false);
                setActiveTimeout(null);
            }, 2500),
        );
    };
    return (
        <FlexRow
            position="relative"
            justifyContent="center"
            alignItems="center"
            width="44px"
            height="44px"
            padding={PX.SPACING.REM.S}
            background={COLORS.Brand.Orange}
            borderRadius={PX.RADII.FULL}
            color={COLORS.UTIL.Gray.WARM}
            onMouseEnter={() => setShowLogout(true)}
            onMouseLeave={onMouseLeave}
        >
            <SlideFade
                style={{ position: 'absolute', left: 0, top: '-48px' }}
                in={showLogout}
                offsetX={0}
                offsetY={10}
            >
                <Sheet
                    paddingY={SPACING.PX.S}
                    paddingX={SPACING.PX.M}
                    width="200px"
                    cursor="pointer"
                    transition="all 0.3s"
                    _hover={{
                        background: COLORS.UTIL.Gray[200],
                    }}
                    _active={{
                        background: COLORS.UTIL.Gray[500],
                    }}
                    onClick={() => {
                        shutdown();
                        logout({
                            logoutParams: {
                                returnTo: window.location.origin,
                            },
                        })
                    }}
                >
                    <FONTS.P1 color={COLORS.Brand.Orange}>Log out</FONTS.P1>
                </Sheet>
            </SlideFade>

            <FONTS.H3 textTransform="uppercase" color={COLORS.UTIL.Gray.WARM}>
                {initials}
            </FONTS.H3>
        </FlexRow >
    );
};
