import { Text } from '@chakra-ui/react';
import React from 'react';

interface Props {
    children: React.ReactNode; // Add the 'children' property to the Props interface
}

const PanelHeading: React.FC<Props> = ({ children }) => {
    return (
        <Text fontSize="2xl" display={'flex'} alignItems={'center'}>
            {children}
        </Text>
    );
};

export default PanelHeading;
