import React, { useState } from 'react';
import { loadConnectAndInitialize } from '@stripe/connect-js';
import {
    ConnectBalances,
    ConnectPayments,
    ConnectComponentsProvider,
    ConnectPayoutsList,
} from '@stripe/react-connect-js';
import { authorize } from '../api/API';
import { useAuth0 } from '@auth0/auth0-react';
import MainLayout from '../layouts/MainLayout';
import {
    Box,
    Center,
    Container,
    Flex,
    VStack,
    useColorModeValue,
} from '@chakra-ui/react';

interface Props {
    // Define your component's props here
}

const Payments: React.FC<Props> = () => {
    const { getAccessTokenSilently } = useAuth0();
    const [stripeConnectInstance] = useState(() => {
        const fetchClientSecret = async () => {
            // Fetch the AccountSession client secret
            const token = await getAccessTokenSilently();
            const response = await authorize(token).get(
                '/stripe/account-session',
            );
            const { client_secret: clientSecret } = response.data;
            return clientSecret;
        };
        return loadConnectAndInitialize({
            // This is your test publishable API key.
            publishableKey: import.meta.env.VITE_STRIPE_PK,
            fetchClientSecret: fetchClientSecret,
            appearance: {
                overlays: 'dialog',
                variables: {
                    colorPrimary: '#625afa',
                },
            },
        });
    });

    return (
        <MainLayout>
            <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
                <VStack
                    justifyContent={'center'}
                    bg={useColorModeValue('white', 'gray.900')}
                    border={'1px'}
                    p={8}
                    borderColor={useColorModeValue('gray.200', 'gray.700')}
                    align={'center'}
                    mb={6}
                    w="100%"
                >
                    <Box w={{ base: 'xs', md: '2xl' }}>
                        <ConnectBalances />
                    </Box>
                </VStack>
                <VStack
                    bg={useColorModeValue('white', 'gray.900')}
                    border={'1px'}
                    p={8}
                    borderColor={useColorModeValue('gray.200', 'gray.700')}
                    align={'start'}
                    mb={6}
                >
                    <ConnectPayoutsList />
                </VStack>
            </ConnectComponentsProvider>
        </MainLayout>
    );
};

export default Payments;
