import { Box, Select, SelectProps } from '@chakra-ui/react';
import { forwardRef } from 'react';
import FONTS from 'ui/fonts';
import { FlexCol } from 'ui/loulaFlex';
import PX from 'ui/px';
import { ICONS } from 'ui/icons';
import { TextInputStyling } from './TextInput';

export type DropdownProps = SelectProps & {
    label?: string;
    subLabel?: string;
    options: { value: string | undefined; label: string | number }[];
};

export const Dropdown = forwardRef<SelectProps, DropdownProps>(
    ({ label, subLabel, options, ...selectProps }, ref) => {
        return (
            <FlexCol
                width="100%"
                justifyContent="center"
                gap={PX.SPACING.REM.XS}
            >
                {!!label && <FONTS.P1 fontWeight={500}>{label}</FONTS.P1>}
                <Box className="baseTextBox" position="relative" width="100%">
                    <Select
                        className="selectRoot"
                        width="100%"
                        {...TextInputStyling}
                        padding={0}
                        icon={<ICONS.ArrowDownIcon boxSize={10} />}
                        ref={ref}
                        {...selectProps}
                    >
                        {options
                            .concat({ label: '-', value: undefined })
                            .map((option, idx) => (
                                <option
                                    key={`dropdownOpt_${idx}`}
                                    value={option.value}
                                >
                                    {option.label}
                                </option>
                            ))}
                    </Select>
                </Box>

                {!!subLabel && <FONTS.P2>{subLabel}</FONTS.P2>}
            </FlexCol>
        );
    },
);
