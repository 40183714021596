import { useAuth0 } from '@auth0/auth0-react';
import { Suspense, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Patients } from './patients/Patients';
import { CreatePatient } from './patients/CreatePatient';
import { Claims } from './claims/Claims';
import { ClaimDetail } from './claims/ClaimDetail';
import { CreateClaim } from './claims/CreateClaim';
import { ComingSoon } from './pages/ComingSoon';
import { ErrorPage } from './pages/ErrorPage';
import { PatientDetail } from './patients/PatientDetail';
import { Settings } from './profile/Settings';
import { CreateVisit } from './visits/CreateVisit';
import { Visits } from './visits/Visits';
import { BulkCreateVisits } from './visits/BulkCreateVisits';
import PrivateRoute from './components/PrivateRoute';
import Loading from './components/Loading';
import { PaymentTab } from './profile/settings/PaymentTab';
import SecurityTab from './profile/settings/SecurityTab';
import VisitsPanel from './patients/detail/VisitsPanel';
import OverviewPanel from './patients/detail/OverviewPanel';
import Payments from './payments/Payments';
import ProfileTab from './profile/settings/ProfileTab';
import { EditPatient } from './patients/EditPatient';
import { VisitDetail } from './visits/VisitDetail';
import { VerifyEmail } from './pages/VerifyEmail';
import { DevPortal } from './pages/DevPortal';
import { OnboardingFlow } from 'onboarding/OnboardingFlow';
import { LoginPage } from 'components/LoginPage';
import { SignupPage } from 'components/SignupPage';
import 'react-datepicker/dist/react-datepicker.css';
import DevRoute from 'components/DevRoute';
import Intercom from '@intercom/messenger-js-sdk';
import useScreenSize from 'hooks/useScreenSize';

import './App.css';

const useIntercom = () => {
    const { isNarrowScreen } = useScreenSize();

    useEffect(() => {
        Intercom({
            app_id: 'ocd9l3z9',
            vertical_padding: 100,
            hide_default_launcher: isNarrowScreen,
        });
    }, [isNarrowScreen]);
};

export function App(): JSX.Element | null {
    useIntercom();

    const { isLoading } = useAuth0();

    if (isLoading) {
        return (
            <div className="page-layout">
                <Loading />
            </div>
        );
    }

    return (
        <Suspense fallback={<Loading />}>
            <Routes>
                <Route element={<PrivateRoute />}>
                    {/* TODO: remove redirect */}
                    <Route path="/" element={<Navigate to="/onboarding" />} />
                    <Route path="/onboarding" element={<OnboardingFlow />} />
                    <Route
                        path="/onboarding/:stepSlug"
                        element={<OnboardingFlow />}
                    />
                    <Route
                        path="/onboarding/:stepSlug/:subStepSlug"
                        element={<OnboardingFlow />}
                    />
                    <Route path="/settings" element={<Settings />}>
                        <Route index element={<SecurityTab />} />
                        {/* TODO: this route should be behind a dev flag, right now the user only 
                        sees the link to it if theyre a dev but could still navigate there manually */}
                        <Route path="payment" element={<PaymentTab />} />
                        <Route path="profile" element={<ProfileTab />} />
                    </Route>

                    {/* Routes only accessible to developers, see DevRoute and useIsDev for more info */}
                    <Route element={<DevRoute />}>
                        <Route path="/devPortal" element={<DevPortal />} />

                        <Route path="/login" element={<LoginPage />} />
                        <Route path="/signup" element={<SignupPage />} />

                        <Route path="/payments" element={<Payments />} />

                        {/* Patients */}
                        <Route path="/clients" element={<Patients />} />
                        <Route
                            path="/clients/:patientID"
                            element={<PatientDetail />}
                        >
                            <Route
                                path="overview"
                                index
                                element={<OverviewPanel />}
                            />
                            <Route path="visits" element={<VisitsPanel />} />
                        </Route>
                        <Route
                            path="/clients/:patientID/edit"
                            element={<EditPatient />}
                        />
                        <Route
                            path="/clients/:patientID/visits/create"
                            element={<CreateVisit />}
                        />
                        <Route
                            path="/clients/:patientID/visits/bulk"
                            element={<BulkCreateVisits />}
                        />
                        <Route
                            path="/clients/create"
                            element={<CreatePatient />}
                        />

                        {/* Claims */}
                        <Route path="/claims" element={<Claims />} />
                        <Route
                            path="/claims/:claimId"
                            element={<ClaimDetail />}
                        />
                        <Route
                            path="/claims/:patientID/create"
                            element={<CreateClaim />}
                        />

                        <Route path="/visits" element={<Visits />} />

                        <Route
                            path="/visits/:visitID"
                            element={<VisitDetail />}
                        />
                    </Route>
                </Route>
                <Route path="/tbd" element={<ComingSoon />} />
                <Route path="/verify-email" element={<VerifyEmail />} />
                <Route path="/error" element={<ErrorPage />} />

                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </Suspense>
    );
}
