import { BoxProps } from '@chakra-ui/react';
import COLORS from 'ui/colors';
import { ICONS } from 'ui/icons';
import { FlexRow } from 'ui/loulaFlex';
import PX from 'ui/px';

export const IconButton = ({
    icon,
    color = COLORS.UTIL.Gray[900],
    hoverColor,
    activeColor,
    boxSize = 5,
    ...props
}: BoxProps & {
    icon: keyof typeof ICONS;
    color?: string;
    hoverColor?: string;
    activeColor?: string;
}) => {
    const IconComponent = ICONS[icon];
    return (
        <FlexRow
            _hover={{
                background: COLORS.BRAND.Periwinkle[200],
            }}
            width={Number(boxSize) + 1}
            height={Number(boxSize) + 1}
            borderRadius={PX.RADII.ROUNDED}
            transition="all 0.3s"
            cursor="pointer"
            justify="center"
            align="center"
            {...props}
        >
            <IconComponent
                boxSize={boxSize}
                color={color}
                _hover={{ color: hoverColor ?? color }}
                _active={{
                    color: activeColor ?? color,
                    transform: 'scale(0.9)',
                }}
                transition="all 0.3s"
                background="transparent"
            />
        </FlexRow>
    );
};
