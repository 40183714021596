import { FormControl, FormLabel } from '@chakra-ui/react';
import { STATES } from 'models/constants';
import { AddressInfo } from 'models/schemas';
import { useMemo } from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import {
    FieldErrors,
    FieldValues,
    UseFormRegister,
    UseFormSetValue,
    UseFormWatch,
} from 'react-hook-form';
import COLORS from 'ui/colors';
import { AutoComplete } from 'ui/components/AutoComplete';
import { Dropdown } from 'ui/components/Dropdown';
import { FormTextInput } from 'ui/components/Form/FormTextInput';
import { TextInput } from 'ui/components/TextInput';
import FONTS from 'ui/fonts';
import { FlexCol, FlexRow } from 'ui/loulaFlex';
import PX from 'ui/px';

interface AddressFormProps {
    label?: string;
    subLabel?: string;
    requiredWarningText?: string;
    isDisabled?: boolean;
    value?: Partial<AddressInfo>;
    errors?: FieldErrors<FieldValues>;
    register?: UseFormRegister<FieldValues>;
    setValue?: UseFormSetValue<FieldValues>;
    watch: UseFormWatch<FieldValues> | undefined;
    namePrefix?: string; // Used to prefix the name of the fields
}

const AddressForm = ({
    isDisabled,
    label,
    subLabel,
    errors,
    register,
    watch,
    value,
    setValue,
    namePrefix = '',
}: AddressFormProps) => {
    const {
        placesService,
        placePredictions,
        getPlacePredictions,
        isPlacePredictionsLoading,
    } = usePlacesService({
        apiKey: 'AIzaSyBFbRPPRFQWKWZVsjZvdOtB3oTt00u6fig',
        options: {
            componentRestrictions: { country: 'us' },
            types: ['address'],
        },
    });

    const placePredictionPossibilities = useMemo(() => {
        return placePredictions.map((prediction) => {
            return {
                label: prediction.description,
                value: prediction.place_id,
            };
        });
    }, [placePredictions]);

    const formNamePrefix = namePrefix.length > 0 ? namePrefix + '.' : '';

    const searchAddressStr: string | undefined = watch?.(
        formNamePrefix + 'address1',
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const parsePlaceResponse = (place: any) => {
        if (!setValue) {
            return;
        }

        const { address_components } = place;
        let streetNumber = '';
        let streetName = '';
        let subpremise;
        let city = '';
        let state = '';
        let postalCode = '';

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        address_components.forEach((component: any) => {
            const { types, short_name, long_name } = component;
            if (types.includes('street_number')) {
                streetNumber = long_name;
            } else if (types.includes('route')) {
                streetName = long_name;
            } else if (
                types.includes('locality') ||
                types.includes('sublocality')
            ) {
                city = long_name;
            } else if (types.includes('administrative_area_level_1')) {
                state = short_name;
            } else if (types.includes('postal_code')) {
                postalCode = long_name;
            } else if (types.includes('subpremise')) {
                subpremise = long_name;
            }
        });

        const setValueOpts = { shouldDirty: true, shouldValidate: true };

        setValue(
            formNamePrefix + 'address1',
            `${streetNumber} ${streetName}`,
            setValueOpts,
        );
        setValue(formNamePrefix + 'address2', subpremise, setValueOpts);
        setValue(formNamePrefix + 'city', city, setValueOpts);
        setValue(formNamePrefix + 'state', state, setValueOpts);
        setValue(formNamePrefix + 'zip', postalCode, setValueOpts);
    };

    const onSelectPlaceById = (placeId: string) => {
        placesService.getDetails({ placeId }, parsePlaceResponse);
    };

    const onAutocompleteInputChange = (val: string) => {
        setValue?.(formNamePrefix + 'address1', val);
        getPlacePredictions({
            input: val,
        });
    };

    return (
        <FlexCol gap={PX.SPACING.PX.S}>
            <FlexRow align="center" wrap="wrap" gap={PX.SPACING.REM.M}>
                <FormControl flex={2} flexBasis="60%" mt={4} isRequired>
                    <FormLabel display="none" htmlFor="address1">
                        Address
                    </FormLabel>

                    {isDisabled ? (
                        <TextInput
                            isDisabled={isDisabled}
                            id={formNamePrefix + 'address1'}
                            label={label ?? 'Address'}
                            value={value?.address1}
                        />
                    ) : (
                        errors && (
                            <AutoComplete
                                label={label ?? 'Address'}
                                possibilities={placePredictionPossibilities}
                                isLoading={isPlacePredictionsLoading}
                                value={searchAddressStr}
                                onInputChange={onAutocompleteInputChange}
                                onClickItem={onSelectPlaceById}
                            />
                        )
                    )}
                </FormControl>

                <FormControl flex={1} flexBasis="30%" mt={4}>
                    <FormLabel display="none" htmlFor="address2">
                        Apt/Unit # (optional)
                    </FormLabel>

                    <TextInput
                        isDisabled={isDisabled}
                        id={formNamePrefix + 'address2'}
                        label="Apt/Unit # (optional)"
                        {...register?.(formNamePrefix + 'address2')}
                        value={value?.address2}
                        blockSpecialChars={true}
                    />
                </FormControl>

                <FlexRow flex={2} flexBasis="40%">
                    {isDisabled ? (
                        <TextInput
                            id="disabledCity"
                            label="City"
                            isDisabled={isDisabled}
                            value={value?.city}
                        />
                    ) : (
                        errors && (
                            <FormTextInput
                                flex={2}
                                flexBasis="40%"
                                register={register}
                                errors={errors}
                                maxLength={50}
                                id="city"
                                label="City"
                                fieldName={formNamePrefix + 'city'}
                            />
                        )
                    )}
                </FlexRow>

                <FlexRow flex={2} flexBasis="75px" maxWidth="75px">
                    {isDisabled ? (
                        <Dropdown
                            id="disabledState"
                            isDisabled={isDisabled}
                            label="State"
                            options={Object.values(STATES).map(
                                (stateAcronym) => {
                                    return {
                                        label: stateAcronym,
                                        value: stateAcronym,
                                    };
                                },
                            )}
                            value={value?.state}
                        />
                    ) : (
                        <FormControl key={`addy_field_state`}>
                            <FormLabel display="none" htmlFor="state">
                                State
                            </FormLabel>
                            <Dropdown
                                id="state"
                                label="State"
                                options={Object.values(STATES).map(
                                    (stateAcronym) => {
                                        return {
                                            label: stateAcronym,
                                            value: stateAcronym,
                                        };
                                    },
                                )}
                                {...register?.(formNamePrefix + 'state', {
                                    required: `State is required`,
                                })}
                            />
                        </FormControl>
                    )}
                </FlexRow>

                <FlexRow flex={2} flexBasis="20%">
                    {isDisabled ? (
                        <TextInput
                            id="disabledZip"
                            label="Zip Code"
                            isDisabled={isDisabled}
                            value={value?.zip}
                        />
                    ) : (
                        errors && (
                            <FormTextInput
                                flex={2}
                                flexBasis="20%"
                                register={register}
                                errors={errors}
                                maxLength={50}
                                required={false}
                                id="zip"
                                label="Zip Code"
                                fieldName={formNamePrefix + 'zip'}
                            />
                        )
                    )}
                </FlexRow>
            </FlexRow>
            <FONTS.P2
                color={
                    errors?.[namePrefix]
                        ? COLORS.UTIL.Red[700]
                        : COLORS.UTIL.Black
                }
            >
                {errors?.[namePrefix]
                    ? 'Please enter a valid address'
                    : subLabel}
            </FONTS.P2>
        </FlexCol>
    );
};

export default AddressForm;
