import { useAuth0 } from '@auth0/auth0-react';
import { Center, Flex, Heading, Link, VStack } from '@chakra-ui/react';
import React from 'react';

export function ErrorPage(): JSX.Element {
    const { logout } = useAuth0();

    return (
        <Flex
            width={'100vw'}
            height={'100vh'}
            alignContent={'center'}
            justifyContent={'center'}
        >
            <Center>
                <VStack gap={8}>
                    <Heading size="lg">
                        Something went wrong. Please contact
                        support@joinloula.com for help.
                    </Heading>
                    <Link
                        onClick={() =>
                            logout({
                                logoutParams: {
                                    returnTo: window.location.origin,
                                },
                            })
                        }
                    >
                        Logout
                    </Link>
                </VStack>
            </Center>
        </Flex>
    );
}
