import { Flex } from '@chakra-ui/react';
import COLORS from 'ui/colors';
import FONTS from 'ui/fonts';
import PX from 'ui/px';
import React from 'react';
import { show } from '@intercom/messenger-js-sdk';
import { LINK } from 'ui/components/Link';

export const HelpTag = (): React.ReactElement => {
    return (
        <Flex width="100%" gap={PX.SPACING.PX.XS} paddingY={PX.SPACING.REM.M}>
            <FONTS.P2
                marginLeft={PX.SPACING.REM.M}
                color={COLORS.UTIL.Gray[900]}
            >
                Need help?
            </FONTS.P2>
            <LINK.Small onClick={show}>Chat with us</LINK.Small>
        </Flex>
    );
};
