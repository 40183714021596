import {
    AlertDialogContent,
    AlertDialogOverlay,
    FlexProps,
    AlertDialog as StandardAlertDialog,
} from '@chakra-ui/react';
import { useRef } from 'react';
import FONTS from './fonts';
import { FlexCol, FlexRow } from './loulaFlex';
import {
    DangerousButton,
    PrimaryButton,
    SecondaryButton,
} from './components/Button';
import PX from './px';
import COLORS from './colors';
import { ICONS } from './icons';

export type AlertDialogProps = FlexProps & {
    title: string;
    description?: string;
    destructiveBtnText: string;
    destructiveIcon?: keyof typeof ICONS;
    leastDestructiveBtnText: string;
    isDestructiveEnabled?: boolean;
    isDestructiveDangerous?: boolean;
    onClickDestructive: () => void;
    onClickLeastDestructive: () => void;
    onClose?: () => void;
};

export const AlertDialog = ({
    title,
    description,
    onClose,

    destructiveBtnText,
    destructiveIcon,
    onClickDestructive,
    isDestructiveEnabled = true,
    isDestructiveDangerous,

    leastDestructiveBtnText,
    onClickLeastDestructive,

    children,
    ...props
}: AlertDialogProps): React.ReactElement | null => {
    const leastDestructiveRef = useRef(null);

    const onCloseDialog = () => {
        onClose?.();
    };

    const DestructiveBtn = isDestructiveDangerous
        ? DangerousButton
        : PrimaryButton;

    return (
        <StandardAlertDialog
            isOpen={true}
            onClose={onCloseDialog}
            leastDestructiveRef={leastDestructiveRef}
            isCentered
        >
            <AlertDialogOverlay
                className="alertDialogueOverlay"
                backdropFilter="blur(1px)"
            />

            <AlertDialogContent
                className="alertDialogueContent"
                background={COLORS.UTIL.Gray[200]}
                boxShadow="-5px 5px 10px 3px rgba(141, 141, 141, 0.50)"
                {...props}
            >
                <FlexCol
                    width="100%"
                    padding={PX.SPACING.REM.XL}
                    gap={PX.SPACING.REM.M}
                >
                    <FONTS.H3>{title}</FONTS.H3>
                    {!!children ? children : <FONTS.P1>{description}</FONTS.P1>}
                </FlexCol>

                <FlexRow
                    width="100%"
                    padding={PX.SPACING.REM.M}
                    borderTop={`1px solid ${COLORS.UTIL.Gray.LIGHT}`}
                    justify="end"
                    align="center"
                    gap={PX.SPACING.REM.S}
                >
                    <SecondaryButton
                        ref={leastDestructiveRef}
                        onClick={onClickLeastDestructive}
                    >
                        {leastDestructiveBtnText ?? 'Cancel'}
                    </SecondaryButton>
                    <DestructiveBtn
                        disabled={!isDestructiveEnabled}
                        onClick={() => {
                            if (isDestructiveEnabled) onClickDestructive();
                        }}
                        iconRight={destructiveIcon}
                    >
                        {destructiveBtnText ?? 'Proceed'}
                    </DestructiveBtn>
                </FlexRow>
            </AlertDialogContent>
        </StandardAlertDialog>
    );
};
