import {
    PayerModel,
    PractitionerReturnType,
    ProcedureCodeModel,
} from '../models/schemas';
import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { authorize } from '../api/API';
import { OnboardingStatus } from 'models/constants';

export function useAllPayers() {
    const { getAccessTokenSilently } = useAuth0();
    const [payers, setPayers] = useState<Record<string, PayerModel>>({});

    useEffect(() => {
        (async () => {
            const token = await getAccessTokenSilently({});
            authorize(token)
                .get(`/payers/list`)
                .then((res): void => {
                    const payers = res.data;
                    const payersMap: Record<string, PayerModel> = {};
                    payers.forEach((payer: PayerModel) => {
                        payersMap[payer.externalPayerId] = payer;
                    });
                    setPayers(payersMap);
                });
        })();
    }, [getAccessTokenSilently]);

    return {
        allPayers: payers,
    };
}
export function useProcedureCodes() {
    const { getAccessTokenSilently } = useAuth0();

    const [procedureCodes, setProcedureCodes] = useState<ProcedureCodeModel[]>(
        [],
    );
    useEffect(() => {
        (async () => {
            const token = await getAccessTokenSilently({});
            authorize(token)
                .get(`/visits/procedureOptions`)
                .then((res): void => {
                    const procedureOptions = res.data;
                    setProcedureCodes(procedureOptions);
                });
        })();
    }, []);

    return {
        procedureCodes,
    };
}

export const usePractitioner = () => {
    const { getAccessTokenSilently } = useAuth0();

    const [practitionerData, setPractitionerData] =
        useState<PractitionerReturnType | null>(null);

    const getPractitionerData = useCallback(async () => {
        const token = await getAccessTokenSilently();
        authorize(token)
            .get(`/providers/me`)
            .then((res): void => {
                setPractitionerData(res.data);
            });
    }, [getAccessTokenSilently]);

    useEffect(() => {
        (async () => {
            await getPractitionerData();
        })();
    }, [getPractitionerData]);

    return {
        practitionerData,
        refreshPractitionerData: getPractitionerData,
    };
};

export const useOnboardingStatus = (): OnboardingStatusContext => {
    const { getAccessTokenSilently } = useAuth0();

    const { practitionerData, refreshPractitionerData } = usePractitioner();
    const { onboardingStatus } = practitionerData ?? {};

    const updateOnboardingStatus = useCallback(
        async (newStatus: OnboardingStatus) => {
            const token = await getAccessTokenSilently();

            await authorize(token)
                .post('/providers/onboard/status', { status: newStatus })
                .then(refreshPractitionerData);
        },
        [getAccessTokenSilently, refreshPractitionerData],
    );

    const isStarted = useMemo(
        () => onboardingStatus === OnboardingStatus.STARTED,
        [onboardingStatus],
    );

    const isCompleted = useMemo(
        () =>
            onboardingStatus === OnboardingStatus.APPROVED ||
            onboardingStatus === OnboardingStatus.SUBMITTED ||
            onboardingStatus === OnboardingStatus.UNDER_REVIEW,
        [onboardingStatus],
    );

    return {
        isLoaded: practitionerData !== null,
        onboardingStatus: onboardingStatus as OnboardingStatus | undefined,
        refreshStatus: refreshPractitionerData,
        updateOnboardingStatus,
        isStarted,
        isCompleted,
    };
};

export type OnboardingStatusContext = {
    isLoaded: boolean;
    onboardingStatus: OnboardingStatus | undefined;
    updateOnboardingStatus: (_newStatus: OnboardingStatus) => Promise<void>;
    isStarted: boolean;
    isCompleted: boolean;
    refreshStatus: () => Promise<void>;
};
