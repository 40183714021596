import { Icon, IconProps } from '@chakra-ui/react';

export const ArrowBackIcon = ({
    width = 200,
    height = 200,
    ...props
}: IconProps) => (
    <Icon viewBox="0 0 200 200" width={width} height={height} {...props}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width.toString()}
            height={height.toString()}
            viewBox="0 0 24 24"
            fill="none"
        >
            <mask
                id="mask0_203_320"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width={width.toString()}
                height={height.toString()}
            >
                <rect
                    width={width.toString()}
                    height={height.toString()}
                    fill="#D9D9D9"
                />
            </mask>
            <g mask="url(#mask0_203_320)">
                <path
                    d="M7.825 13L13.425 18.6L12 20L4 12L12 4L13.425 5.4L7.825 11H20V13H7.825Z"
                    fill="currentColor"
                />
            </g>
        </svg>
    </Icon>
);

export const ArrowForwardIcon = ({
    width = 200,
    height = 200,
    ...props
}: IconProps) => (
    <Icon viewBox="0 0 200 200" width={width} height={height} {...props}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width.toString()}
            height={height.toString()}
            viewBox="0 0 24 24"
            fill="none"
        >
            <mask
                id="mask0_203_288"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width={width.toString()}
                height={height.toString()}
            >
                <rect
                    width={width.toString()}
                    height={height.toString()}
                    fill="#D9D9D9"
                />
            </mask>
            <g mask="url(#mask0_203_288)">
                <path
                    d="M16.175 13H4V11H16.175L10.575 5.4L12 4L20 12L12 20L10.575 18.6L16.175 13Z"
                    fill="currentColor"
                />
            </g>
        </svg>
    </Icon>
);

export const ArrowLeftIcon = ({
    width = 200,
    height = 200,
    ...props
}: IconProps) => (
    <Icon viewBox="0 0 200 200" width={width} height={height} {...props}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width.toString()}
            height={height.toString()}
            viewBox="0 0 24 24"
            fill="none"
        >
            <mask
                id="mask0_203_289"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="240"
                height="240"
            >
                <rect
                    width={width.toString()}
                    height={height.toString()}
                    fill="#D9D9D9"
                />
            </mask>
            <g mask="url(#mask0_203_289)">
                <path d="M14 17L9 12L14 7V17Z" fill="currentColor" />
            </g>
        </svg>
    </Icon>
);

export const ArrowDownIcon = ({
    width = 200,
    height = 200,
    ...props
}: IconProps) => (
    <Icon viewBox="0 0 200 200" width={width} height={height} {...props}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width.toString()}
            height={height.toString()}
            viewBox="0 0 24 24"
            fill="none"
        >
            <mask
                id="mask0_203_290"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width={width.toString()}
                height={height.toString()}
            >
                <rect
                    width={width.toString()}
                    height={height.toString()}
                    fill="#D9D9D9"
                />
            </mask>
            <g mask="url(#mask0_203_290)">
                <path d="M12 15L7 10H17L12 15Z" fill="currentColor" />
            </g>
        </svg>
    </Icon>
);

export const ArrowBackIOSIcon = ({
    width = 200,
    height = 200,
    ...props
}: IconProps) => (
    <Icon viewBox="0 0 200 200" width={width} height={height} {...props}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width.toString()}
            height={height.toString()}
            viewBox="0 0 24 24"
            fill="none"
        >
            <mask
                id="mask0_203_291"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width={width.toString()}
                height={height.toString()}
            >
                <rect
                    width={width.toString()}
                    height={height.toString()}
                    fill="#D9D9D9"
                />
            </mask>
            <g mask="url(#mask0_203_291)">
                <path
                    d="M16 22L6 12L16 2L17.775 3.775L9.55 12L17.775 20.225L16 22Z"
                    fill="currentColor"
                />
            </g>
        </svg>
    </Icon>
);

export const ArrowForwardIOSIcon = ({
    width = 200,
    height = 200,
    ...props
}: IconProps) => (
    <Icon viewBox="0 0 200 200" width={width} height={height} {...props}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width.toString()}
            height={height.toString()}
            viewBox="0 0 24 24"
            fill="none"
        >
            <mask
                id="mask0_203_293"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width={width.toString()}
                height={height.toString()}
            >
                <rect
                    width={width.toString()}
                    height={height.toString()}
                    fill="#D9D9D9"
                />
            </mask>
            <g mask="url(#mask0_203_293)">
                <path
                    d="M8.025 22L6.25 20.225L14.475 12L6.25 3.775L8.025 2L18.025 12L8.025 22Z"
                    fill="currentColor"
                />
            </g>
        </svg>
    </Icon>
);

export const UploadIcon = ({
    width = 200,
    height = 200,
    ...props
}: IconProps) => (
    <Icon viewBox="0 0 240 240" width={width} height={height} {...props}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width.toString()}
            height={height.toString()}
            viewBox="0 0 30 34"
            fill="none"
        >
            <path
                fill="currentColor"
                d="M18.5 25h-5c-.875 0-1.5-.625-1.5-1.5V13H6.5a1.232 1.232 0 0 1-.875-2.125l9.5-9.5c.438-.438 1.25-.438 1.688 0l9.5 9.5c.812.813.25 2.125-.875 2.125H20v10.5c0 .875-.688 1.5-1.5 1.5Zm13.5-.5v7c0 .875-.688 1.5-1.5 1.5h-29C.625 33 0 32.375 0 31.5v-7c0-.813.625-1.5 1.5-1.5H10v.5c0 1.938 1.563 3.5 3.5 3.5h5c1.875 0 3.5-1.563 3.5-3.5V23h8.5c.813 0 1.5.688 1.5 1.5ZM24.25 30c0-.688-.563-1.25-1.25-1.25-.688 0-1.25.563-1.25 1.25 0 .688.563 1.25 1.25 1.25.688 0 1.25-.563 1.25-1.25Zm4 0c0-.688-.563-1.25-1.25-1.25-.688 0-1.25.563-1.25 1.25 0 .688.563 1.25 1.25 1.25.688 0 1.25-.563 1.25-1.25Z"
            />
        </svg>
    </Icon>
);

export const BackupIcon = ({
    width = 200,
    height = 200,
    ...props
}: IconProps) => (
    <Icon viewBox="0 0 200 200" {...props}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width.toString()}
            height={height.toString()}
            viewBox="0 0 24 24"
            fill="none"
        >
            <mask
                id="mask0_203_294"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width={width.toString()}
                height={height.toString()}
            >
                <rect
                    width={width.toString()}
                    height={height.toString()}
                    fill="#D9D9D9"
                />
            </mask>
            <g mask="url(#mask0_203_294)">
                <path
                    d="M11 20H6.5C4.98333 20 3.6875 19.475 2.6125 18.425C1.5375 17.375 1 16.0917 1 14.575C1 13.275 1.39167 12.1167 2.175 11.1C2.95833 10.0833 3.98333 9.43333 5.25 9.15C5.66667 7.61667 6.5 6.375 7.75 5.425C9 4.475 10.4167 4 12 4C13.95 4 15.6042 4.67917 16.9625 6.0375C18.3208 7.39583 19 9.05 19 11C20.15 11.1333 21.1042 11.6292 21.8625 12.4875C22.6208 13.3458 23 14.35 23 15.5C23 16.75 22.5625 17.8125 21.6875 18.6875C20.8125 19.5625 19.75 20 18.5 20H13V12.85L14.6 14.4L16 13L12 9L8 13L9.4 14.4L11 12.85V20Z"
                    fill="currentColor"
                />
            </g>
        </svg>
    </Icon>
);

export const DeleteIcon = ({
    width = 200,
    height = 200,
    ...props
}: IconProps) => (
    <Icon viewBox="0 0 200 200" width={width} height={height} {...props}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width.toString()}
            height={height.toString()}
            viewBox="0 0 24 24"
            fill="none"
        >
            <mask
                id="a"
                width={width.toString()}
                height={height.toString()}
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
                style={{ maskType: 'alpha' }}
            >
                <path fill="#D9D9D9" d="M0 0h24v24H0z" />
            </mask>
            <g mask="url(#a)">
                <path
                    fill="currentColor"
                    d="M9 17h2V8H9v9Zm4 0h2V8h-2v9Zm-8 4V6H4V4h5V3h6v1h5v2h-1v15H5Z"
                />
            </g>
        </svg>
    </Icon>
);

export const EditIcon = ({
    width = 200,
    height = 200,
    ...props
}: IconProps) => (
    <Icon viewBox="0 0 200 200" width={width} height={height} {...props}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width.toString()}
            height={height.toString()}
            viewBox="0 0 24 24"
            fill="none"
        >
            <mask
                id="a"
                width={width.toString()}
                height={height.toString()}
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
                style={{ maskType: 'alpha' }}
            >
                <path fill="#D9D9D9" d="M0 0h24v24H0z" />
            </mask>
            <g mask="url(#a)">
                <path
                    fill="currentColor"
                    d="M3 21v-4.25L17.625 2.175 21.8 6.45 7.25 21H3ZM17.6 7.8 19 6.4 17.6 5l-1.4 1.4 1.4 1.4Z"
                />
            </g>
        </svg>
    </Icon>
);

export const LaunchIcon = ({
    width = 200,
    height = 200,
    ...props
}: IconProps) => (
    <Icon viewBox="0 0 200 200" width={width} height={height} {...props}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width.toString()}
            height={height.toString()}
            viewBox="0 0 24 24"
            fill="none"
        >
            <mask
                id="mask0_203_319"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width={width.toString()}
                height={height.toString()}
            >
                <rect
                    width={width.toString()}
                    height={height.toString()}
                    fill="#D9D9D9"
                />
            </mask>
            <g mask="url(#mask0_203_319)">
                <path
                    d="M19 19H5V5H12V3H3V21H21V12H19V19ZM14 3V5H17.59L7.76 14.83L9.17 16.24L19 6.41V10H21V3H14Z"
                    fill="currentColor"
                />
            </g>
        </svg>
    </Icon>
);

export const CheckMarkIcon = ({
    width = 200,
    height = 200,
    ...props
}: IconProps) => (
    <Icon viewBox="-6 -8 24 24" width={width} height={height} {...props}>
        <path
            fill="currentColor"
            d="M4.379 7.844 1.137 4.602a.519.519 0 0 1 0-.723l.703-.703a.495.495 0 0 1 .703 0L4.75 5.363 9.438.676a.495.495 0 0 1 .703 0l.703.703a.519.519 0 0 1 0 .723L5.102 7.844a.519.519 0 0 1-.723 0Z"
        />
    </Icon>
);

export const ThinCheckMarkIcon = ({
    width = 200,
    height = 200,
    ...props
}: IconProps) => (
    <Icon viewBox="-6 -8 24 24" width={width} height={height} {...props}>
        <path
            fill="#312E2B"
            d="M10.195.469c.117-.117.305-.117.399 0l.68.656c.093.117.093.305 0 .398L4.241 8.555a.27.27 0 0 1-.398 0L.704 5.438c-.095-.118-.095-.305 0-.399l.679-.68c.094-.093.281-.093.398 0l2.25 2.274L10.195.469Z"
        />
    </Icon>
);

export const FileIcon = ({
    width = 200,
    height = 200,
    ...props
}: IconProps) => (
    <Icon viewBox="0 0 200 200" width={width} height={height} {...props}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width.toString()}
            height={height.toString()}
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                fill="currentColor"
                d="M13.25 7.813c0 .546.39.937.938.937H19.5v12.813c0 .546-.43.937-.938.937H5.438c-.546 0-.937-.39-.937-.938V3.438c0-.507.39-.937.938-.937h7.812v5.313Zm6.25-.547V7.5h-5v-5h.234c.235 0 .47.117.664.273l3.829 3.829c.156.195.273.43.273.664Z"
            />
        </svg>
    </Icon>
);

export const SearchIcon = ({
    width = 200,
    height = 200,
    ...props
}: IconProps) => (
    <Icon viewBox="0 0 160 160" width={width} height={height} {...props}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width.toString()}
            height={height.toString()}
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                fill="currentColor"
                d="M14.809 12.863c.246.274.246.684-.028.93l-.765.766c-.246.273-.657.273-.93 0l-2.707-2.707a.64.64 0 0 1-.191-.465v-.465a5.651 5.651 0 0 1-3.5 1.203A5.683 5.683 0 0 1 1 6.437C1 3.32 3.543.75 6.688.75c3.117 0 5.687 2.57 5.687 5.688 0 1.34-.465 2.542-1.203 3.5h.437c.164 0 .329.082.465.19l2.735 2.735ZM6.688 9.938c1.914 0 3.5-1.56 3.5-3.5 0-1.915-1.586-3.5-3.5-3.5a3.507 3.507 0 0 0-3.5 3.5 3.49 3.49 0 0 0 3.5 3.5Z"
            />
        </svg>
    </Icon>
);

export const CloseIcon = ({
    width = 200,
    height = 200,
    ...props
}: IconProps) => (
    <Icon viewBox="0 0 160 160" width={width} height={height} {...props}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width.toString()}
            height={height.toString()}
            viewBox="0 0 20 20"
            fill="none"
        >
            <path
                fill="currentColor"
                d="m10.578 7.5 3.906 3.945a1.16 1.16 0 0 1 0 1.72l-.859.858a1.16 1.16 0 0 1-1.719 0L8 10.117l-3.945 3.906a1.16 1.16 0 0 1-1.72 0l-.858-.859a1.16 1.16 0 0 1 0-1.719L5.383 7.5 1.477 3.594a1.16 1.16 0 0 1 0-1.719l.859-.86a1.16 1.16 0 0 1 1.719 0L8 4.923l3.906-3.906a1.16 1.16 0 0 1 1.719 0l.86.859a1.16 1.16 0 0 1 0 1.719L10.577 7.5Z"
            />
        </svg>
    </Icon>
);

export const WarningIcon = ({
    width = 200,
    height = 200,
    ...props
}: IconProps) => (
    <Icon viewBox="0 0 200 200" width={width} height={height} {...props}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width.toString()}
            height={height.toString()}
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                fill="currentColor"
                d="M21.688 12.5c0 5.352-4.375 9.688-9.688 9.688A9.686 9.686 0 0 1 2.312 12.5c0-5.313 4.336-9.688 9.688-9.688 5.313 0 9.688 4.376 9.688 9.688ZM12 14.453c-1.016 0-1.797.82-1.797 1.797 0 1.016.781 1.797 1.797 1.797.977 0 1.797-.781 1.797-1.797 0-.977-.82-1.797-1.797-1.797Zm-1.719-6.445.274 5.312c.039.274.234.43.468.43h1.915c.234 0 .43-.156.468-.43l.274-5.312c.039-.274-.196-.508-.47-.508h-2.46c-.273 0-.508.234-.469.508Z"
            />
        </svg>
    </Icon>
);

export const WarningXIcon = ({
    width = 200,
    height = 200,
    ...props
}: IconProps) => (
    <Icon viewBox="0 0 200 200" width={width} height={height} {...props}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width.toString()}
            height={height.toString()}
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M7 0.96875C10.7461 0.96875 13.7812 4.00391 13.7812 7.75C13.7812 11.4961 10.7461 14.5312 7 14.5312C3.25391 14.5312 0.21875 11.4961 0.21875 7.75C0.21875 4.00391 3.25391 0.96875 7 0.96875ZM10.3086 9.55469L8.53125 7.75L10.3086 5.97266C10.4453 5.86328 10.4453 5.64453 10.3086 5.50781L9.24219 4.44141C9.10547 4.30469 8.88672 4.30469 8.77734 4.44141L7 6.21875L5.22266 4.44141C5.08594 4.30469 4.86719 4.30469 4.75781 4.44141L3.66406 5.50781C3.52734 5.64453 3.52734 5.86328 3.66406 5.97266L5.46875 7.75L3.66406 9.52734C3.52734 9.66406 3.52734 9.88281 3.66406 9.99219L4.73047 11.0859C4.86719 11.2227 5.08594 11.2227 5.19531 11.0859L7 9.28125L8.77734 11.0859C8.88672 11.2227 9.10547 11.2227 9.24219 11.0859L10.3086 10.0195C10.4453 9.88281 10.4453 9.66406 10.3086 9.55469Z"
                fill="currentColor"
            />
        </svg>
    </Icon>
);

export const MenuIcon = ({
    width = 200,
    height = 200,
    ...props
}: IconProps) => (
    <Icon viewBox="0 0 200 200" width={width} height={height} {...props}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width.toString()}
            height={height.toString()}
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                fill="currentColor"
                d="M1.25 4.188a.74.74 0 0 1-.75-.75V1.562c0-.375.328-.75.75-.75h19.5c.375 0 .75.375.75.75v1.875c0 .422-.375.75-.75.75H1.25Zm0 7.5a.74.74 0 0 1-.75-.75V9.062c0-.374.328-.75.75-.75h19.5c.375 0 .75.376.75.75v1.876c0 .421-.375.75-.75.75H1.25Zm0 7.5a.74.74 0 0 1-.75-.75v-1.875c0-.375.328-.75.75-.75h19.5c.375 0 .75.374.75.75v1.875c0 .421-.375.75-.75.75H1.25Z"
            />
        </svg>
    </Icon>
);

export const ICONS = {
    ArrowBackIcon,
    ArrowForwardIcon,
    ArrowLeftIcon,
    ArrowDownIcon,
    ArrowBackIOSIcon,
    ArrowForwardIOSIcon,
    BackupIcon,
    DeleteIcon,
    EditIcon,
    LaunchIcon,
    CheckMarkIcon,
    SearchIcon,
    CloseIcon,
    WarningIcon,
    WarningXIcon,
    UploadIcon,
    ThinCheckMarkIcon,
    FileIcon,
    MenuIcon,
};
