import FONTS from 'ui/fonts';
import { FlexRow } from '../loulaFlex';
import PX from 'ui/px';
import COLORS from 'ui/colors';
import { FlexProps } from '@chakra-ui/react';
import { IconButton } from 'ui/components/IconButton';

export const TextTag = ({
    text,
    onClickClose,
    ...props
}: FlexProps & {
    text: string;
    onClickClose?: () => void;
}) => {
    return (
        <FlexRow
            align="center"
            justify="center"
            gap={PX.SPACING.PX.S}
            borderRadius={PX.RADII.SM}
            padding={PX.SPACING.PX.XS}
            border={`1px solid ${COLORS.STROKES.HEAVY}`}
            background={COLORS.UTIL.PRIMARY.White}
            {...props}
        >
            <FONTS.TAG>{text}</FONTS.TAG>

            {onClickClose && (
                <IconButton
                    icon="CloseIcon"
                    color={COLORS.STROKES.HEAVY}
                    onClick={onClickClose}
                    boxSize={3}
                />
            )}
        </FlexRow>
    );
};
