import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import {
    FieldErrors,
    FieldValues,
    UseFormSetValue,
    UseFormWatch,
} from 'react-hook-form';
import { CheckBox } from '../Checkbox';
import FONTS from 'ui/fonts';
import { FlexCol, FlexRow } from 'ui/loulaFlex';
import PX from 'ui/px';

export type MultiselectOptionType = {
    label: string;
    value: string;
    isExclusive?: boolean;
    customPlaceholder?: string;
};

export function FormMultiselect({
    fieldName,
    label,
    subLabel,
    errors,
    setValue,
    isRequired = true,
    defaultValue,
    watch,
    options,
}: {
    label: string;
    subLabel?: string;
    fieldName: string;
    errors: FieldErrors<FieldValues> | undefined;
    setValue: UseFormSetValue<FieldValues> | undefined;
    isRequired?: boolean;
    options: MultiselectOptionType[];
    defaultValue?: Record<string, boolean>;
    watch: UseFormWatch<FieldValues> | undefined;
}) {
    const watchField: Record<string, boolean> | undefined = watch?.(
        fieldName,
        defaultValue,
    );

    const onChangeCheckbox = (
        option: MultiselectOptionType,
        isChecked: boolean,
    ) => {
        const valueToSet: Record<string, boolean> = {
            [option.value]: isChecked,
        };

        //if its exclusive, we drop any other selections, otherwise we check for other exclusives to deselect
        if (!option.isExclusive && isChecked) {
            const checkedValues = Object.entries(watchField ?? {})
                .filter(([, checked]) => checked)
                .map(([val]) => val);

            checkedValues.forEach((val) => {
                const fullOption = options.find(
                    (option) => option.value === val,
                );

                if (!!fullOption) {
                    valueToSet[fullOption.value] = !fullOption.isExclusive;
                }
            });
        }

        setValue?.(fieldName, valueToSet, { shouldDirty: true });
    };

    return (
        <FormControl
            flex={1}
            isInvalid={!!errors?.[fieldName]}
            mt={4}
            isRequired={isRequired}
        >
            <FormLabel display="none" htmlFor={fieldName}>
                {label}
            </FormLabel>
            <FlexCol gap={PX.SPACING.PX.S}>
                <FONTS.P1 fontWeight={500}>{label}</FONTS.P1>
                {options.map((option, idx) => (
                    <FlexCol
                        key={`multiselect_option_${idx}`}
                        gap={PX.SPACING.PX.S}
                    >
                        <FlexRow align="center" gap={PX.SPACING.PX.S}>
                            <CheckBox
                                isChecked={watchField?.[option.value] ?? false}
                                onChange={(e) =>
                                    onChangeCheckbox(option, e.target.checked)
                                }
                            />
                            <FONTS.P1>{option.label}</FONTS.P1>
                        </FlexRow>
                        {/*TODO-Mina finish setting up for custom write-in option ability here 
                        {option.customPlaceholder &&
                            watchField[option.value] && (
                                <TextInput marginLeft={PX.SPACING.PX.L} onChange={} />
                            )} */}
                    </FlexCol>
                ))}
                <FONTS.P2>{subLabel}</FONTS.P2>
            </FlexCol>
            <FormErrorMessage>
                {errors?.[fieldName] && errors?.[fieldName].message?.toString()}
            </FormErrorMessage>
        </FormControl>
    );
}
