import { useState, useEffect } from 'react';
import { BREAKPOINTS } from 'ui/theme';

const useScreenSize = () => {
    const [screenSize, setScreenSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    const isShortScreen = screenSize.height < BREAKPOINTS.LG;
    const isNarrowScreen = screenSize.width < BREAKPOINTS.LG;

    useEffect(() => {
        const handleResize = () => {
            setScreenSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return { screenSize, isShortScreen, isNarrowScreen };
};

export default useScreenSize;
