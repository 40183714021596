import { Text, VStack, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { Link as ChakraLink } from '@chakra-ui/react';

interface Props {
    // Define your component's props here
}

const SecurityTab: React.FC<Props> = () => {
    // Implement your component's logic here

    return (
        // JSX markup for your component goes here
        <VStack
            bg={useColorModeValue('white', 'gray.900')}
            border={'1px'}
            p={8}
            borderColor={useColorModeValue('gray.200', 'gray.700')}
            align={'start'}
        >
            <Text>
                To reset your password,{' '}
                <ChakraLink href="/reset-password">sign out</ChakraLink> and
                click on "Forgot Password?" and check your email for next steps.
            </Text>
        </VStack>
    );
};

export default SecurityTab;
