import { Flex } from '@chakra-ui/react';
import COLORS from 'ui/colors';
import { useOnboardingState } from './hooks/useOnboardingState';

import Loading from 'components/Loading';
import React from 'react';
import useScreenSize from 'hooks/useScreenSize';

import {
    DesktopStepWrapper,
    MobileStepWrapper,
} from './components/StepsWrapper';
import {
    ApplicatonSummary,
    SideBar,
} from './components/OnboardingCompleteScreen';
import { Helmet } from 'react-helmet';
import {
    OnboardingContext,
    OnboardingStatusContext,
} from './OnboardingContext';
import { DesktopSideBar, MobileNavBar } from './components/NavBar';

import { OnboardingLandingPage } from './landing/OnboardingLandingPage';
import { useOnboardingStatus } from 'hooks/dbHooks';

export function OnboardingFlow(): React.ReactElement {
    const practitionerOnboardingStatusContext = useOnboardingStatus();
    const { isStarted, isCompleted, refreshStatus } =
        practitionerOnboardingStatusContext ?? {};
    const onboardingState = useOnboardingState({ onComplete: refreshStatus });

    const [showHelpTag, setShowHelpTag] = React.useState(false);

    const { isNarrowScreen } = useScreenSize();

    if (
        !practitionerOnboardingStatusContext.isLoaded ||
        !onboardingState.isLoaded ||
        onboardingState.activeStepIdx === -1
    ) {
        return (
            <div className="page-layout">
                <Loading />
            </div>
        );
    }

    return (
        <Flex
            width="100vw"
            height="100vh"
            position="fixed"
            className="onboardingWrapperPage"
            background={COLORS.BRAND.Periwinkle[100]}
            direction={['column', null, null, 'row']}
            justifyContent="space-between"
        >
            <OnboardingContext.Provider value={onboardingState}>
                {isCompleted ? (
                    <>
                        <SideBar />
                        <ApplicatonSummary />
                    </>
                ) : isStarted ? (
                    <>
                        <Helmet>
                            <title>
                                Onboarding |{' '}
                                {onboardingState.activeStepOrSubstep.title ??
                                    'Loula'}
                            </title>
                        </Helmet>

                        {isNarrowScreen ? (
                            <MobileNavBar showHelpTag={showHelpTag} />
                        ) : (
                            <DesktopSideBar />
                        )}

                        {isNarrowScreen ? (
                            <MobileStepWrapper
                                setShowHelpTag={setShowHelpTag}
                            />
                        ) : (
                            <DesktopStepWrapper />
                        )}
                    </>
                ) : null}
            </OnboardingContext.Provider>

            {!isStarted && !isCompleted && (
                <OnboardingStatusContext.Provider
                    value={practitionerOnboardingStatusContext}
                >
                    <OnboardingLandingPage />
                </OnboardingStatusContext.Provider>
            )}
        </Flex>
    );
}
