import { ResumeItem } from 'models/schemas';
import { useForm, UseFormReturn } from 'react-hook-form';
import COLORS from 'ui/colors';
import { PrimaryButton, SecondaryButton } from 'ui/components/Button';
import { CheckBox } from 'ui/components/Checkbox';
import { DateInput } from 'ui/components/DateInput';
import { Tabs } from 'ui/components/Tabs';
import { TextAreaInput } from 'ui/components/TextAreaInput';
import { TextInput } from 'ui/components/TextInput';
import FONTS from 'ui/fonts';
import { FlexCol, FlexRow } from 'ui/loulaFlex';
import PX from 'ui/px';
import SHADOWS from 'ui/shadows';
import { ICONS } from 'ui/icons';

export const ResumeForm = ({
    resumeItem,
    onSave,
    onDiscard,
    isEditting,
}: {
    resumeItem?: ResumeItem;
    onSave: (_val: ResumeItem) => void;
    onDiscard: () => void;
    isEditting: boolean;
}) => {
    const resumeFormInstance: UseFormReturn<ResumeItem> = useForm({
        defaultValues: resumeItem,
    });

    const {
        watch,
        register,
        setValue,
        formState: { errors },
    } = resumeFormInstance;

    const [startDate, endDate, current, description] = watch([
        'startDate',
        'endDate',
        'current',
        'description',
    ]);

    const saveForm = () => {
        onSave(resumeFormInstance.getValues());
    };

    const validateStartDate = (val: string) => {
        //console.log('validateStartDate')
        const date = new Date(val);
        //console.log(`startdate = ${val}, ${date.toISOString()}`);
        const endDateAsDate = new Date(endDate);
        //console.log(`enddate = ${endDate}, ${endDateAsDate.toISOString()}`);
        if (date > endDateAsDate) {
            return 'Start date cannot be before end date';
        }
        if (date > new Date()) {
            return 'Start date cannot be in the future';
        }
    };

    const validateEndDate = (val: string) => {
        //console.log('validateEndDate')
        const date = new Date(val);
        //console.log(`enddate = ${val}, ${date.toISOString()}`);
        const startDateAsDate = new Date(startDate);
        //console.log(`startdate = ${startDate}, ${startDateAsDate.toISOString()}`);

        if (date < startDateAsDate) {
            return 'End date cannot be before start date';
        }
        if (date > new Date()) {
            return 'End date cannot be in the future';
        }
    };

    return (
        <FlexCol
            background={COLORS.UTIL.Gray[100]}
            gap={PX.SPACING.REM.L}
            boxShadow={SHADOWS.md}
            borderRadius={PX.RADII.MD}
            border={`1px solid ${COLORS.UTIL.PRIMARY.Grey}`}
        >
            <Tabs
                tabHeaders={['Work Experience', 'Career Break']}
                onChange={(idx) => {
                    if (idx == 1) {
                        setValue('title', 'Career Break');
                    }
                }}
                tabPanels={[
                    <FlexCol paddingX={PX.SPACING.PX.M} gap={PX.SPACING.REM.L}>
                        <TextInput {...register('title')} label="Job Title" />
                        <TextInput
                            {...register('company')}
                            label="Company/Organization Name"
                            subLabel="If this is your own doula practice, you may write your business name or write “Self-employed”"
                        />
                        <TextInput
                            {...register('location')}
                            label="Location"
                            placeholder="i.e. San Francisco, CA"
                        />
                        <FlexRow wrap="wrap">
                            <FlexCol flex={1} flexBasis="50%">
                                <DateInput
                                    label="From"
                                    valueAsDate={
                                        !!startDate?.length
                                            ? new Date(startDate)
                                            : new Date()
                                    }
                                    dateFormat="MM/yyyy"
                                    setValueAsString={(val: string) => {
                                        //console.log('GIVEN VAL', val);
                                        setValue('startDate', val, {
                                            shouldValidate: true,
                                        });
                                    }}
                                    {...register('startDate', {
                                        validate: {
                                            validStartDate: validateStartDate,
                                        },
                                    })}
                                />
                                <ValidationError
                                    message={errors.startDate?.message}
                                />
                            </FlexCol>
                            <FlexCol
                                flex={1}
                                gap={PX.SPACING.PX.XS}
                                flexBasis="50%"
                            >
                                <DateInput
                                    disabled={current}
                                    label="To"
                                    valueAsDate={
                                        !!endDate
                                            ? new Date(endDate)
                                            : new Date()
                                    }
                                    dateFormat="MM/yyyy"
                                    setValueAsString={(val: string) => {
                                        setValue('endDate', val, {
                                            shouldValidate: true,
                                        });
                                    }}
                                    {...register('endDate', {
                                        validate: {
                                            validEndDate: validateEndDate,
                                        },
                                    })}
                                />
                                <ValidationError
                                    message={errors.endDate?.message}
                                />
                                <CheckBox
                                    isChecked={current}
                                    onChange={(e) =>
                                        setValue('current', e.target.checked)
                                    }
                                >
                                    <FONTS.P1>
                                        I am currently in this role
                                    </FONTS.P1>
                                </CheckBox>
                            </FlexCol>
                        </FlexRow>
                        <TextAreaInput
                            {...register('description')}
                            maxLength={1000}
                            label="Description"
                            subLabel="Write a 2-3 sentence summary of the type of activities you performed in this role. "
                        />
                    </FlexCol>,
                    <FlexCol paddingX={PX.SPACING.PX.M} gap={PX.SPACING.REM.L}>
                        <FlexRow wrap="wrap">
                            <FlexCol flex={1} flexBasis="50%">
                                <DateInput
                                    label="From"
                                    valueAsDate={
                                        !!startDate?.length
                                            ? new Date(startDate)
                                            : new Date()
                                    }
                                    dateFormat="MM/yyyy"
                                    setValueAsString={(val: string) => {
                                        //console.log('GIVEN VAL', val);
                                        setValue('startDate', val, {
                                            shouldValidate: true,
                                        });
                                    }}
                                    {...register('startDate', {
                                        validate: {
                                            validStartDate: validateStartDate,
                                        },
                                    })}
                                />
                                <ValidationError
                                    message={errors.startDate?.message}
                                />
                            </FlexCol>
                            <FlexCol flex={1} flexBasis="50%">
                                <DateInput
                                    disabled={current}
                                    label="To"
                                    valueAsDate={
                                        !!endDate
                                            ? new Date(endDate)
                                            : new Date()
                                    }
                                    dateFormat="MM/yyyy"
                                    setValueAsString={(val: string) => {
                                        setValue('endDate', val, {
                                            shouldValidate: true,
                                        });
                                    }}
                                    {...register('endDate', {
                                        validate: {
                                            validEndDate: validateEndDate,
                                        },
                                    })}
                                />
                                <FlexRow gap={PX.SPACING.PX.S}>
                                    <CheckBox
                                        isChecked={current}
                                        onChange={(e) =>
                                            setValue(
                                                'current',
                                                e.target.checked,
                                            )
                                        }
                                    />
                                    <FONTS.P1>
                                        I am currently on a career break
                                    </FONTS.P1>
                                </FlexRow>
                                <ValidationError
                                    message={errors.endDate?.message}
                                />
                            </FlexCol>
                        </FlexRow>
                        <TextAreaInput
                            value={description}
                            onChange={(e) =>
                                setValue('description', e.target.value)
                            }
                            label="Describe the nature of your career break"
                            subLabel="Write a 1-2 sentence summary of why you took a break from doula work and what you did during that time."
                        />
                    </FlexCol>,
                ]}
            />

            <FlexRow
                justify="end"
                paddingY={PX.SPACING.REM.M}
                paddingX={PX.SPACING.REM.L}
                gap={PX.SPACING.REM.S}
            >
                <SecondaryButton onClick={onDiscard}>
                    Discard{isEditting ? ' Changes' : ''}
                </SecondaryButton>
                <PrimaryButton onClick={saveForm}>Save</PrimaryButton>
            </FlexRow>
        </FlexCol>
    );
};

const ValidationError = ({ message = '' }: { message?: string }) => {
    if (message)
        return (
            <FlexRow gap={PX.SPACING.PX.XS} align="center">
                <ICONS.WarningIcon color={COLORS.UTIL.Red[700]} boxSize={5} />
                <FONTS.P1 color={COLORS.UTIL.Red[700]}>{message}</FONTS.P1>
            </FlexRow>
        );
};
