import {
    CreateToastFnReturn,
    Spacer,
    useToast as useStandardToast,
    UseToastOptions,
} from '@chakra-ui/react';
import { FlexRow } from './loulaFlex';
import FONTS from './fonts';
import COLORS from './colors';
import PX from './px';
import { IconButton } from './components/IconButton';

export const useToast = (options?: UseToastOptions): CreateToastFnReturn => {
    const standardToast = useStandardToast({
        ...options,
        render: (renderProps) => (
            <FlexRow
                borderRadius={PX.RADII.MD}
                padding={PX.SPACING.REM.SM}
                background={COLORS.UTIL.Black}
                align="center"
                justify="space-between"
            >
                <FONTS.P1 fontWeight={600} color={COLORS.UTIL.Gray.WARM}>
                    {renderProps.description}
                </FONTS.P1>
                <Spacer minW={PX.SPACING.PX.M} flex={1} />
                <IconButton
                    color={COLORS.UTIL.Gray.WHITE}
                    hoverColor={COLORS.UTIL.Gray[300]}
                    activeColor={COLORS.UTIL.Gray[400]}
                    aria-label="Close"
                    icon="CloseIcon"
                    onClick={renderProps.onClose}
                />
            </FlexRow>
        ),
    });

    return standardToast;
};
