import { TextProps } from '@chakra-ui/react';
import COLORS from 'ui/colors';
import FONTS from 'ui/fonts';

const SmallLink = ({ children, ...props }: TextProps) => {
    return (
        <FONTS.P2
            cursor="pointer"
            textDecorationLine="underline"
            color={COLORS.UTIL.PRIMARY.Blue}
            _hover={{
                color: COLORS.BRAND.Periwinkle[700],
            }}
            {...props}
        >
            {children}
        </FONTS.P2>
    );
};

const LargeLink = ({ children, ...props }: TextProps) => {
    return (
        <FONTS.P1
            cursor="pointer"
            textDecorationLine="underline"
            color={COLORS.UTIL.PRIMARY.Blue}
            _hover={{
                color: COLORS.BRAND.Periwinkle[700],
            }}
            {...props}
        >
            {children}
        </FONTS.P1>
    );
};

export const LINK = {
    Small: SmallLink,
    Large: LargeLink,
};
