import {
    Avatar,
    Box,
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    HStack,
    Input,
    Spacer,
    Text,
    Textarea,
    VStack,
    useColorModeValue,
    useToast,
} from '@chakra-ui/react';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { authorize } from '../../api/API';
import { faker } from '@faker-js/faker';
import PanelHeading from '../../components/PanelHeading';

interface Props {
    // Define your component's props here
}

const ProfileTab: React.FC<Props> = () => {
    const { getAccessTokenSilently, user } = useAuth0();
    const toast = useToast();
    const token = getAccessTokenSilently();
    const navigate = useNavigate();
    const {
        handleSubmit,
        register,
        formState: { errors, isSubmitting },
        setValue,
        watch,
        getValues,
        control,
    } = useForm();

    const [file, setFile] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const fileUploadRef = useRef<HTMLInputElement>(null);
    const { ref, ...rest } = register('fileUpload');
    const fileUploadWatch = watch('fileUpload');

    async function onSubmit(values: any) {
        //console.log('Submitting form', values);

        await authorize(await token)
            .post('/providers/profile', values)
            .then(() => {
                //console.log('Profile updated successfully');
                toast({
                    title: 'Profile saved.',
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                });
            })
            .catch((err) => {
                console.error('Error updating profile', err);
                toast({
                    title: 'Error updating profile. Please try again later.',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            });
    }

    const uploadFileFn = async (token: string, file: any) => {
        const formData = new FormData();
        formData.append('file', file);

        const result = await authorize(token).post(
            '/providers/upload-temp-file',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            },
        );

        return result.data;
    };

    const uploadFile = async (file: File) => {
        setLoading(true);
        uploadFileFn(await token, file)
            .then((res): void => {
                setValue('avatar', res.fileName);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (
            fileUploadRef.current &&
            fileUploadRef.current.files &&
            fileUploadRef.current.files.length > 0
        ) {
            // Set preview
            //console.log('File uploaded', fileUploadRef.current.files[0]);
            setFile(URL.createObjectURL(fileUploadRef.current.files[0]));

            // Upload image
            uploadFile(fileUploadRef.current.files[0]);
        }
    }, [fileUploadWatch]);

    const demoData = () => {
        // Implement your component's logic here
        setValue('bio', faker.lorem.paragraphs(5));
    };

    useImperativeHandle(ref, () => fileUploadRef.current);

    return (
        // JSX markup for your component goes here
        <VStack
            bg={useColorModeValue('white', 'gray.900')}
            border={'1px'}
            p={8}
            borderColor={useColorModeValue('gray.200', 'gray.700')}
            align={'start'}
            gap={4}
        >
            <HStack width="100%">
                <PanelHeading>Profile</PanelHeading>
                <Spacer />
                <Button onClick={demoData} colorScheme="orange">
                    Demo Data
                </Button>
            </HStack>
            <Box width="100%">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormControl isInvalid={!!errors.bio}>
                        <FormLabel htmlFor="avatar">Avatar</FormLabel>
                        <HStack alignContent={'center'} gap={8}>
                            <Avatar
                                size={'xl'}
                                name={user?.name}
                                bg="purple.500"
                                src={file}
                            />
                            <Input
                                type="file"
                                display={'none'}
                                {...rest}
                                ref={fileUploadRef}
                            />
                            <input type="hidden" {...register('avatar')} />
                            <Button
                                onClick={() => fileUploadRef.current?.click()}
                            >
                                Replace
                            </Button>
                        </HStack>
                        <FormErrorMessage>
                            {errors.bio && errors.bio.message?.toString()}
                        </FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={!!errors.bio} mt={4}>
                        <FormLabel htmlFor="bio">Bio</FormLabel>
                        <Textarea
                            size={'lg'}
                            minH="200"
                            w="100%"
                            resize="none"
                            {...register('bio')}
                        />
                        <FormErrorMessage>
                            {errors.bio && errors.bio.message?.toString()}
                        </FormErrorMessage>
                    </FormControl>

                    <Button
                        type="submit"
                        mt={4}
                        isLoading={isSubmitting || loading}
                    >
                        Save
                    </Button>
                </form>
            </Box>
        </VStack>
    );
};

export default ProfileTab;
