import {
    Box,
    Input,
    InputProps,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    useMergeRefs,
} from '@chakra-ui/react';
import { forwardRef, useState } from 'react';
import COLORS from 'ui/colors';
import FONTS from 'ui/fonts';
import { FlexCol } from 'ui/loulaFlex';
import PX from 'ui/px';
import { ICONS } from 'ui/icons';
import { SpecialChars } from 'models/constants';
import { useIMask } from 'react-imask';
import { DefaultDateFromString, DefaultStringifiedDate } from 'util/Utils';

export const TextInputStyling = {
    background: COLORS.UTIL.Gray.WARM,
    borderRadius: PX.RADII.MD,
    border: `1px solid ${COLORS.STROKES.HEAVY}`,
    padding: PX.SPACING.PX.S,
    display: 'flex',
    fontFamily: 'DM Sans',
    fontSize: PX.NUM.PX.M,
    size: 'md',
    invalid: {
        borderColor: COLORS.UTIL.PRIMARY.Error,
    },

    focusBorderColor: COLORS.UTIL.PRIMARY.Blue,
    _hover: {},

    _disabled: {
        background: COLORS.UTIL.Gray[300],
        opacity: 0.7,
    },
};

export type DateMaskProps = {
    min: Date;
    max: Date;
};

export type LoulaTextProps = {
    label?: string;

    mask?: string;
    dateMask?: DateMaskProps;

    subLabel?: string;
    isSecret?: boolean;
    iconLeft?: keyof typeof ICONS;
    iconRight?: keyof typeof ICONS;
    iconColorLeft?: string;
    iconColorRight?: string;

    blockSpecialChars?: boolean;
    blockChars?: string;

    onAccept?: (_str: string) => void; //If you make a masked & secret input, use this to save the value (instead of saving '*** ** ****')
};

export type TextInputProps = InputProps & LoulaTextProps;

export const TextInput = forwardRef<InputProps, TextInputProps>(
    (
        {
            label,
            subLabel,
            mask,
            dateMask,
            iconLeft,
            iconRight,
            iconColorLeft,
            iconColorRight,
            isSecret,
            blockSpecialChars,
            blockChars,
            defaultValue,
            onAccept,
            ...inputProps
        },
        forwardedRef,
    ) => {
        const [isSecretVisible, setIsSecretVisible] = useState(false);
        const ICONRIGHT = !!iconRight ? ICONS[iconRight] : null;
        const ICONLEFT = !!iconLeft ? ICONS[iconLeft] : null;

        const hasMasking = !!dateMask || !!mask;

        const useIMaskProps = !!dateMask
            ? {
                  mask: Date,
                  pattern: 'm/d/Y',
                  format: DefaultStringifiedDate,
                  parse: DefaultDateFromString,
                  min: dateMask.min ?? new Date(1900, 0, 1),
                  max: dateMask.max ?? new Date(2025, 11, 31),
                  lazy: true,
                  placeholderChar: ' ',
              }
            : !!mask
              ? {
                    mask: mask,
                    definitions: {
                        X: {
                            mask: '0',
                            displayChar: isSecretVisible ? undefined : '•',
                            placeholderChar: '#',
                        },
                    },
                }
              : {};

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const { ref: iMaskRef } = useIMask(useIMaskProps as any, {
            defaultValue: defaultValue?.toString(),
            onAccept: async (value) => {
                onAccept?.(value);
            },
        });

        const mergeRef = useMergeRefs(
            forwardedRef,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            !hasMasking ? undefined : (iMaskRef as any),
        );

        return (
            <FlexCol width="100%" gap={PX.SPACING.REM.XS}>
                {!!label && <FONTS.P1 fontWeight={500}>{label}</FONTS.P1>}
                <Box className="baseTextBox" position="relative" width="100%">
                    <InputGroup>
                        {iconLeft && ICONLEFT && (
                            <InputLeftElement>
                                <ICONLEFT
                                    boxSize={5}
                                    color={
                                        iconColorLeft ?? COLORS.STROKES.HEAVY
                                    }
                                    zIndex={1}
                                />
                            </InputLeftElement>
                        )}

                        <Input
                            onKeyDown={(e) => {
                                if (
                                    (blockSpecialChars &&
                                        SpecialChars.includes(e.key)) ||
                                    (!!blockChars && blockChars.includes(e.key))
                                ) {
                                    e.preventDefault?.();
                                }
                            }}
                            {...TextInputStyling}
                            {...inputProps}
                            {...(iconLeft
                                ? { paddingLeft: PX.SPACING.PX.XL }
                                : {})}
                            ref={mergeRef}
                        />
                        {isSecret !== undefined && (
                            <InputRightElement
                                right={
                                    !!iconRight
                                        ? PX.SPACING.REM.XL
                                        : PX.SPACING.REM.S
                                }
                            >
                                <FONTS.P1
                                    cursor="pointer"
                                    textDecorationLine="underline"
                                    color={COLORS.UTIL.PRIMARY.Blue}
                                    onClick={() =>
                                        setIsSecretVisible((prev) => !prev)
                                    }
                                >
                                    {isSecretVisible ? 'Hide' : 'View'}
                                </FONTS.P1>
                            </InputRightElement>
                        )}
                        {iconRight && ICONRIGHT && (
                            <InputRightElement>
                                <ICONRIGHT
                                    color={
                                        iconColorRight ?? COLORS.STROKES.HEAVY
                                    }
                                    boxSize={5}
                                />
                            </InputRightElement>
                        )}
                    </InputGroup>
                </Box>

                {!!subLabel && <FONTS.P2>{subLabel}</FONTS.P2>}
            </FlexCol>
        );
    },
);
