import { Flex, Image } from '@chakra-ui/react';
import { StepComponentProps } from 'onboarding/onboarding.model';
import { ReactElement, useContext } from 'react';
import { authorize } from 'api/API';
import FONTS from 'ui/fonts';
import { useAuth0 } from '@auth0/auth0-react';
import { FlexCol } from 'ui/loulaFlex';
import { PrimaryButton } from 'ui/components/Button';
import PX from 'ui/px';
import { OnboardingContext } from 'onboarding/OnboardingContext';

export const IdentityStep = ({
    commonStyling,
}: StepComponentProps): ReactElement => {
    const onboardingContext = useContext(OnboardingContext);
    const { formData, saveForm } = onboardingContext ?? {};

    const { getAccessTokenSilently } = useAuth0();

    const token = getAccessTokenSilently();

    const createVerificationSession = async () => {
        authorize(await token)
            .post('/stripe/verification-session')
            .then((res) => {
                saveForm?.({ identityVerificationStatus: 'initiated' });
                window.location.href = res.data.url;
            });
    };

    const retrieveVerificationSession = async () => {
        if (!formData?.identityVerificationSessionId) return createVerificationSession();
        authorize(await token)
            .get(
                `/stripe/verification-session/${formData?.identityVerificationSessionId}`,
            )
            .then((res) => {
                window.location.href = res.data.url;
            });
    };

    return (
        <Flex {...commonStyling}>
            <FlexCol gap={PX.SPACING.PX.XL} className="internalWrapper">
                {(!formData?.identityVerificationStatus ||
                    formData?.identityVerificationStatus === 'abandoned') && (
                        <FlexCol maxW={500} gap={PX.SPACING.PX.XL}>
                            <FONTS.H2>
                                Almost there! We need to verify your identity.
                            </FONTS.H2>

                            <Image
                                width="100%"
                                height="100%"
                                src="/images/verifyID.png"
                                alt="verify ID"
                            />

                            <FONTS.P1>
                                Loula uses a third party service, Stripe, to verify
                                your identity. You’ll be taken off the Loula website
                                to complete the verification, then brought back to
                                this page.
                            </FONTS.P1>
                            <FONTS.P1>
                                You’ll need a physical copy of your
                                government-issued photo ID.
                            </FONTS.P1>
                            <PrimaryButton onClick={createVerificationSession}>
                                Verify My Identity
                            </PrimaryButton>
                        </FlexCol>
                    )}

                {formData?.identityVerificationStatus &&
                    formData?.identityVerificationStatus === 'initiated' && (
                        <FlexCol maxW={400} gap={PX.SPACING.PX.XL}>
                            <Image
                                width="100%"
                                height="100%"
                                src="/images/successVerifyingID.png"
                                alt="ID verified"
                            />
                            <FONTS.H2>
                                Identity verification submitted.
                            </FONTS.H2>

                            <FONTS.P1>
                                Thank you for initiating the identity
                                verification process via Stripe. Proceed to the
                                next section to review and submit your
                                application.
                            </FONTS.P1>
                        </FlexCol>
                    )}

                {formData?.identityVerificationStatus &&
                    formData?.identityVerificationStatus === 'needs_input' && (
                        <FlexCol maxW={500} gap={PX.SPACING.PX.XL}>
                            <FONTS.H2>
                                Stripe ran into an issue verifying your
                                identity. Please try again.
                            </FONTS.H2>

                            <Image
                                width="100%"
                                height="100%"
                                src="/images/verifyID.png"
                                alt="verify ID"
                            />

                            <FONTS.P1>
                                You’ll need a physical copy of your
                                government-issued photo ID.
                            </FONTS.P1>
                            <PrimaryButton
                                onClick={retrieveVerificationSession}
                            >
                                Resolve Verification Issues
                            </PrimaryButton>
                        </FlexCol>
                    )}

                {formData?.identityVerificationStatus &&
                    formData?.identityVerificationStatus === 'verified' && (
                        <FlexCol maxW={400} gap={PX.SPACING.PX.XL}>
                            <Image
                                width="100%"
                                height="100%"
                                src="/images/successVerifyingID.png"
                                alt="ID verified"
                            />
                            <FONTS.H2>Identity verified.</FONTS.H2>

                            <FONTS.P1>
                                Thank you for verifying your identity via
                                Stripe. Proceed to the next section to review
                                and submit your application.
                            </FONTS.P1>
                        </FlexCol>
                    )}
            </FlexCol>
        </Flex>
    );
};
