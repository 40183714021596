import { Flex } from '@chakra-ui/react';
import { StepComponentProps } from 'onboarding/onboarding.model';
import { ReactElement, useContext, useState } from 'react';
import AddressForm from 'components/AddressForm';
import { FlexCol } from 'ui/loulaFlex';
import PX from 'ui/px';
import FONTS from 'ui/fonts';
import { FormTextInput } from 'ui/components/Form/FormTextInput';
import { FormCheckboxInput } from 'ui/components/Form/FormCheckBoxInput';
import { TextInput } from 'ui/components/TextInput';
import { OnboardingContext } from 'onboarding/OnboardingContext';
import { AlertDialog } from 'ui/AlertDialogue';

export const BusinessInfoStep = ({
    commonStyling,
}: StepComponentProps): ReactElement => {
    const onboardingContext = useContext(OnboardingContext);

    const { formInstance, formData, goToStep } = onboardingContext ?? {};
    const { register, formState, setValue, watch } = formInstance ?? {};
    const { errors } = formState ?? {};

    const isSameName: boolean = watch?.(
        'isNameSameAsPersonal',
        formData?.isNameSameAsPersonal,
    );
    const isSameAddy: boolean = watch?.(
        'isAddressSameAsPersonal',
        formData?.isAddressSameAsPersonal,
    );

    const hasNoBusinessTin: boolean = watch?.(
        'hasNoBusinessTin',
        formData?.hasNoBusinessTin,
    );

    const businessNameVisualFields = {
        fieldName: 'businessName',
        label: 'Business Name',
        subLabel: 'Use your legal business name',
    };

    const businessAddyVisualFields = {
        subLabel:
            'This address will be used for tax-purposes and official mail. Use the address that you would use on a tax return. ',
    };

    const businessTINVisualFields = {
        fieldName: 'businessTIN',
        label: 'Tax Identification Number (TIN) or Employer Identification Number (EIN)',
    };

    const [showAlertDialog, setShowAlertDialog] = useState<string | null>(null);

    const onTryToCheckSameName =
        !formData?.firstName || !formData?.lastName
            ? () => {
                  setShowAlertDialog(
                      'No legal name has been entered in the Personal Information step',
                  );
              }
            : undefined;

    const onTryToCheckSameAddy = !formData?.personalAddress?.address1
        ? () => {
              setShowAlertDialog(
                  'No personal mailing address has been entered in the Personal Information step',
              );
          }
        : undefined;

    return (
        <Flex {...commonStyling}>
            <FlexCol gap={PX.SPACING.PX.XL} className="internalWrapper">
                <FONTS.H2>Let’s learn more about your doula practice</FONTS.H2>

                <FlexCol gap={PX.SPACING.PX.S}>
                    {isSameName ? (
                        <TextInput
                            {...businessNameVisualFields}
                            value={`${formData?.firstName} ${formData?.lastName}`}
                            isDisabled={true}
                        />
                    ) : (
                        <FormTextInput
                            {...businessNameVisualFields}
                            register={register}
                            errors={errors}
                            maxLength={50}
                        />
                    )}
                    <FormCheckboxInput
                        defaultChecked={formData?.isNameSameAsPersonal}
                        fieldName="isNameSameAsPersonal"
                        label="Same as Legal Name"
                        watch={watch}
                        setValue={setValue}
                        errors={errors}
                        blockingOnCheckedTrue={onTryToCheckSameName}
                    />
                </FlexCol>

                <FlexCol gap={PX.SPACING.PX.S}>
                    {hasNoBusinessTin ? (
                        <FormTextInput
                            isDisabled={true}
                            errors={{}}
                            value=""
                            {...businessTINVisualFields}
                        />
                    ) : (
                        <FormTextInput
                            setValue={setValue}
                            errors={errors}
                            mask="XXX XX XXXX"
                            defaultValue={formData?.businessTIN}
                            autoComplete="off"
                            placeholder="123 45 6789"
                            isRequired={false}
                            isSecret
                            minLength={9}
                            {...businessTINVisualFields}
                        />
                    )}

                    <FormCheckboxInput
                        defaultChecked={formData?.hasNoBusinessTin}
                        fieldName="hasNoBusinessTin"
                        label="I do not have a TIN or EIN"
                        watch={watch}
                        setValue={setValue}
                        errors={errors}
                    />
                </FlexCol>

                <FlexCol>
                    <FlexCol gap={PX.SPACING.PX.SM}>
                        <FONTS.P1 fontWeight={500}>
                            Business Mailing Address
                        </FONTS.P1>

                        <FormCheckboxInput
                            defaultChecked={formData?.isAddressSameAsPersonal}
                            fieldName="isAddressSameAsPersonal"
                            label="Same as Personal Mailing Address"
                            watch={watch}
                            setValue={setValue}
                            errors={errors}
                            blockingOnCheckedTrue={onTryToCheckSameAddy}
                        />
                    </FlexCol>
                    {isSameAddy ? (
                        <AddressForm
                            {...businessAddyVisualFields}
                            watch={undefined}
                            value={formData?.personalAddress}
                            isDisabled={isSameAddy}
                        />
                    ) : (
                        <AddressForm
                            {...businessAddyVisualFields}
                            namePrefix="businessAddress"
                            errors={errors}
                            watch={watch}
                            register={register}
                            setValue={setValue}
                        />
                    )}
                </FlexCol>
            </FlexCol>

            {showAlertDialog && (
                <AlertDialog
                    title={showAlertDialog ?? 'Missing Field'}
                    leastDestructiveBtnText="Cancel"
                    destructiveBtnText="Return to Personal Information"
                    onClickDestructive={goToStep?.(0) ?? (() => {})}
                    isDestructiveEnabled={true}
                    onClickLeastDestructive={() => {
                        setShowAlertDialog(null);
                    }}
                ></AlertDialog>
            )}
        </Flex>
    );
};
