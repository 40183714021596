import { Collapse, Flex } from '@chakra-ui/react';
import { ProgressableStep } from 'onboarding/onboarding.model';
import { OnboardingContext } from 'onboarding/OnboardingContext';
import { ReactElement, useContext, useState } from 'react';
import COLORS from 'ui/colors';
import FONTS from 'ui/fonts';
import { CheckMarkIcon } from 'ui/icons';
import { FlexCol } from 'ui/loulaFlex';
import PX from 'ui/px';

export const ProgressIndicator = ({}: {
    isMobile: boolean;
}): ReactElement | null => {
    const onboardingContext = useContext(OnboardingContext);

    const { steps, activeStepIdx, isStepComplete, isStepStarted } =
        onboardingContext ?? {};

    return (
        <Flex
            marginTop={[null, null, 'auto']}
            marginBottom={PX.SPACING.REM.S}
            justifyContent="center"
            alignItems="center"
        >
            <Flex className="progressIndicator" direction="column" width="100%">
                {steps?.map((step, idx) => (
                    <ProgressIndicatorStep
                        key={`progressableStep-${idx}`}
                        step={step}
                        stepIdx={idx}
                        isActive={idx === activeStepIdx}
                        showVerticalBar={idx !== steps.length - 1}
                        isVerticalBarFilled={
                            (steps &&
                                idx < steps.length - 1 &&
                                (isStepStarted?.(steps[idx]) ||
                                    idx === activeStepIdx ||
                                    isStepComplete?.(steps[idx])) &&
                                (isStepStarted?.(steps[idx + 1]) ||
                                    idx + 1 === activeStepIdx ||
                                    isStepComplete?.(steps[idx + 1]))) ??
                            false
                        }
                    />
                ))}
            </Flex>
        </Flex>
    );
};

const ProgressIndicatorStep = ({
    showVerticalBar = true,
    isActive,
    isVerticalBarFilled,
    stepIdx,
    step,
}: {
    showVerticalBar: boolean;
    isActive: boolean;
    isVerticalBarFilled: boolean;
    stepIdx: number;
    step: ProgressableStep;
}): React.ReactElement | null => {
    const onboardingContext = useContext(OnboardingContext);

    const { goToStep, isStepComplete, isStepStarted, steps } =
        onboardingContext ?? {};
    const hasSubSteps = (step.subSteps?.length ?? 0) > 0;
    const showSubsteps = isActive && hasSubSteps;
    const minHeight = PX.NUM.PX.SM;
    const maxHeight =
        9 * (hasSubSteps && isActive ? (step.subSteps?.length ?? 0) + 1 : 1);

    const [verticalBarHeight, setVerticalBarHeight] = useState(
        showSubsteps ? maxHeight : minHeight,
    );

    const isStarted = isStepStarted?.(step) || isActive;

    return (
        <Flex direction="column" width="100%">
            <Flex
                className="progressableStep"
                onClick={isStarted ? goToStep?.(stepIdx) : undefined}
                paddingX={PX.SPACING.REM.M}
                background={
                    isActive && !hasSubSteps
                        ? COLORS.BRAND.Periwinkle[200]
                        : 'transparent'
                }
                gap={PX.SPACING.REM.S}
                borderRadius={PX.RADII.ROUNDED}
                width="100%"
                height="40px"
                alignItems="center"
                position="relative"
                transition="all 0.3s ease"
                cursor="pointer"
                _hover={
                    !isStarted
                        ? undefined
                        : {
                              background: COLORS.BRAND.Periwinkle[200],
                          }
                }
                _active={
                    !isStarted
                        ? undefined
                        : { background: COLORS.BRAND.Periwinkle[300] }
                }
            >
                {showVerticalBar && (
                    <Flex
                        marginLeft={`calc(${PX.SPACING.PX.M} * 0.5 - ${isVerticalBarFilled ? 2 : 1}px)`}
                        className="statusBar"
                        width={isVerticalBarFilled ? '4px' : '2px'}
                        height={verticalBarHeight}
                        top={0.5 * PX.NUM.PX.S}
                        background={
                            isVerticalBarFilled
                                ? COLORS.UTIL.PRIMARY.Blue
                                : COLORS.UTIL.Gray[500]
                        }
                        position="absolute"
                        zIndex={1}
                        transition="all 0s"
                    />
                )}

                <StatusOrb
                    isActive={isActive}
                    isComplete={
                        stepIdx < (steps?.length ?? 0) - 1 &&
                        (isStepComplete?.(step) ?? false)
                    }
                    isStarted={isStarted ?? false}
                />
                <FONTS.P1
                    color={
                        isStarted
                            ? COLORS.UTIL.PRIMARY.Blue
                            : COLORS.STROKES.HEAVY
                    }
                    fontWeight={isActive ? 'semibold' : 'regular'}
                >
                    {step.title}
                </FONTS.P1>
            </Flex>
            <Collapse
                startingHeight={1}
                in={isActive && hasSubSteps}
                onUpdate={(latest) => {
                    if (latest.height === 'auto') {
                        setVerticalBarHeight(maxHeight);
                    } else if (typeof latest.height === 'number') {
                        if (latest.height === 1) {
                            setVerticalBarHeight(minHeight);
                        } else {
                            setVerticalBarHeight(
                                Math.max(
                                    minHeight +
                                        latest.height +
                                        (showSubsteps ? 32 : 24),
                                    minHeight,
                                ),
                            );
                        }
                    }
                }}
            >
                <Substeps stepIdx={stepIdx} step={step} isActive={isActive} />
            </Collapse>
        </Flex>
    );
};

export const Substeps = ({
    isActive,
    step,
    stepIdx,
}: {
    stepIdx: number;
    step: ProgressableStep;
    isActive: boolean;
}): React.ReactElement | null => {
    const onboardingContext = useContext(OnboardingContext);

    const { activeSubStepIdx, goToSubStep, isStepComplete, isStepStarted } =
        onboardingContext ?? {};

    return (
        <FlexCol width="100%" gap={PX.SPACING.PX.S}>
            {step.subSteps?.map((subStep, idx) => {
                return (
                    <Flex
                        key={`subStep-${step.slug}-${idx}`}
                        width="100%"
                        justifyContent="start"
                    >
                        <Flex
                            marginLeft={PX.SPACING.REM.XXL}
                            gap={PX.SPACING.REM.S}
                            flex={1}
                            key={`subStep-${step.slug}-${idx}`}
                            onClick={goToSubStep?.(stepIdx, idx)}
                            className="progressableStep"
                            paddingY={PX.SPACING.PX.XS}
                            paddingX={PX.SPACING.PX.S}
                            alignItems="center"
                            position="relative"
                            transition="all 0.3s ease"
                            borderRadius={PX.RADII.ROUNDED}
                            background={
                                isActive && activeSubStepIdx === idx
                                    ? COLORS.BRAND.Periwinkle[200]
                                    : 'transparent'
                            }
                            _hover={{
                                background: isActive
                                    ? COLORS.BRAND.Periwinkle[200]
                                    : 'transparent',
                            }}
                            _active={{
                                background: COLORS.Brand.Periwinkle[300],
                            }}
                            cursor="pointer"
                        >
                            <StatusOrb
                                isSubStepOrb={true}
                                isComplete={isStepComplete?.(subStep)}
                                isStarted={isStepStarted?.(subStep)}
                            />

                            <FONTS.P2
                                color={
                                    isStepStarted?.(step)
                                        ? COLORS.UTIL.PRIMARY.Blue
                                        : COLORS.STROKES.HEAVY
                                }
                                fontWeight={
                                    isActive && activeSubStepIdx === idx
                                        ? 'semibold'
                                        : 'regular'
                                }
                            >
                                {subStep.title}
                            </FONTS.P2>
                        </Flex>
                    </Flex>
                );
            })}
        </FlexCol>
    );
};

const StatusOrb = ({
    isComplete,
    isActive,
    isStarted,
    isSubStepOrb = false,
}: {
    isActive?: boolean;
    isComplete?: boolean;
    isStarted?: boolean;
    isSubStepOrb?: boolean;
}) => {
    return (
        <Flex
            className="statusOrb"
            width={PX.SPACING.REM.SM}
            height={PX.SPACING.REM.SM}
            borderRadius={PX.RADII.FULL}
            background={
                isComplete
                    ? COLORS.UTIL.PRIMARY.Blue
                    : isSubStepOrb
                      ? 'transparent'
                      : isActive
                        ? COLORS.BRAND.Periwinkle[200]
                        : isStarted
                          ? COLORS.BRAND.Periwinkle[100]
                          : COLORS.UTIL.Gray[500]
            }
            zIndex={1}
            transition="all 0.15s"
            border={
                isStarted ? `4px solid ${COLORS.UTIL.PRIMARY.Blue}` : undefined
            }
            justify="center"
            align="center"
        >
            {isComplete && (
                <CheckMarkIcon boxSize={6} color={COLORS.UTIL.Gray.WARM} />
            )}
        </Flex>
    );
};
