import { authorize } from '../api/API';
import { useAuth0 } from '@auth0/auth0-react';
import { useState, useEffect } from 'react';
import {
    AdonisContract,
    AdonisPatient,
    AdonisProvider,
} from '../models/schemas';

export function useAllContracts() {
    const { getAccessTokenSilently } = useAuth0();

    const token = getAccessTokenSilently();
    const [allContracts, setAllContracts] = useState<AdonisContract[]>([]);

    useEffect(() => {
        if (token == null) {
            //console.log('TOKEN NULL');
            return;
        }

        (async () => {
            const token = await getAccessTokenSilently({});
            authorize(token)
                .get('adonis-test/contracts/list')
                .then((res): void => {
                    //console.log('contracts in our DB', res.data);
                    setAllContracts(res.data.data);
                });
        })();
    }, []);

    return {
        allContracts,
    };
}

export function useAllPatients() {
    const { getAccessTokenSilently } = useAuth0();

    const token = getAccessTokenSilently();
    const [allPatients, setAllPatients] = useState<AdonisPatient[]>([]);

    useEffect(() => {
        if (token == null) {
            //console.log('TOKEN NULL');
            return;
        }

        (async () => {
            const token = await getAccessTokenSilently({});
            authorize(token)
                .get('adonis-test/patients/list')
                .then((res): void => {
                    //console.log('providers', res.data);
                    setAllPatients(res.data.data);
                });
        })();
    }, []);

    return {
        allPatients,
    };
}

export function useAllProviders() {
    const { getAccessTokenSilently } = useAuth0();

    const token = getAccessTokenSilently();
    const [allProviders, setAllProviders] = useState<AdonisProvider[]>([]);

    useEffect(() => {
        if (token == null) {
            //console.log('TOKEN NULL');
            return;
        }

        (async () => {
            const token = await getAccessTokenSilently({});
            authorize(token)
                .get('adonis-test/providers/list')
                .then((res): void => {
                    //console.log('providers', res.data);
                    setAllProviders(res.data.data);
                });
        })();
    }, []);

    return {
        allProviders,
    };
}
