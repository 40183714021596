import {
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
} from '@chakra-ui/react';
import FileUpload from 'components/FileUploader';
import { StepComponentProps } from 'onboarding/onboarding.model';
import { OnboardingContext } from 'onboarding/OnboardingContext';
import { ReactElement, useContext, useState } from 'react';
import { FlexCol } from 'ui/loulaFlex';
import PX from 'ui/px';

export const DocumentationSubstepOne = ({
    commonStyling,
}: StepComponentProps): ReactElement => {
    const onboardingContext = useContext(OnboardingContext);

    const { formInstance, formData, saveField } = onboardingContext ?? {};
    const [, setLoading] = useState(false);
    //console.log('uploading: ', loading);

    const { formState } = formInstance ?? {};

    const { errors } = formState ?? {};

    return (
        <Flex {...commonStyling}>
            <FlexCol gap={PX.SPACING.PX.XL} className="internalWrapper">
                <FormControl
                    isInvalid={!!errors?.mediCalEnrollmentFile}
                    mt={4}
                    isRequired
                >
                    <FormLabel display="none">
                        Medicaid/Medi-Cal Approval Letter
                    </FormLabel>

                    <FileUpload
                        label="Upload the approval letter you received when you became
                        enrolled as a Medi-Cal Provider"
                        subLabel="This letters hould include your “Application
                        Identification Number” and the “Date Application
                        Received” View example"
                        data-cy="medi-cal-enrollment-file"
                        fieldName="mediCalEnrollmentFile"
                        onLoadingChange={setLoading}
                        maxFiles={1}
                        fieldValue={formData?.['mediCalEnrollmentFile'] ?? []}
                        formInstance={formInstance}
                        saveField={saveField}
                    />

                    <FormErrorMessage>
                        {errors?.mediCalEnrollmentFile &&
                            errors?.mediCalEnrollmentFile.message?.toString()}
                    </FormErrorMessage>
                </FormControl>

                <FormControl
                    isRequired
                    mt={4}
                    isInvalid={!!errors?.cprCertificate}
                >
                    <FormLabel display="none">
                        Upload a copy of your CPR training certificate
                    </FormLabel>

                    <FileUpload
                        label="Upload your CPR cerfication"
                        subLabel=" The CPR certification must be an adult and infant
                        certification from the American Heart Association or the
                        American Red Cross. The training has to be in person,
                        either in its entirety or hybrid (online and in person).
                        You may upload multiple certificates."
                        fieldName="cprCertificate"
                        fieldValue={formData?.['cprCertificate'] ?? []}
                        formInstance={formInstance}
                        onLoadingChange={setLoading}
                        saveField={saveField}
                        maxFiles={1}
                    />

                    <FormErrorMessage>
                        {errors?.cprCertificate &&
                            errors?.cprCertificate.message?.toString()}
                    </FormErrorMessage>
                </FormControl>
            </FlexCol>
        </Flex>
    );
};
