import { Box, chakra, RadioProps, useRadio } from '@chakra-ui/react';
import COLORS from 'ui/colors';
import { FlexRow } from 'ui/loulaFlex';
import PX from 'ui/px';

export const Radio = (props: RadioProps) => {
    const { state, htmlProps, getInputProps, getRadioProps, getLabelProps } =
        useRadio(props);

    const radioProps = getRadioProps();

    return (
        <chakra.label
            display="flex"
            flexDirection="row"
            gap={PX.SPACING.PX.S}
            alignItems="center"
            {...htmlProps}
            cursor="pointer"
        >
            <input {...getInputProps({})} hidden />
            <FlexRow
                width={PX.SPACING.PX.L}
                height={PX.SPACING.PX.L}
                background="transparent"
                borderRadius={PX.RADII.ROUNDED}
                justify="center"
                align="center"
                transition="all 0.3s"
                _hover={{
                    background: COLORS.BRAND.Periwinkle[200],
                }}
            >
                <FlexRow
                    {...radioProps}
                    width={PX.SPACING.PX.M}
                    height={PX.SPACING.PX.M}
                    alignItems="center"
                    justify="center"
                    border={`1px solid ${COLORS.STROKES.HEAVY}`}
                    rounded="full"
                    transition="all 0.3s"
                    _active={{
                        border: `1px solid ${COLORS.BRAND.Periwinkle[700]}`,
                    }}
                >
                    <Box
                        {...getLabelProps()}
                        transition="all 0.2s"
                        background={
                            state.isChecked
                                ? COLORS.UTIL.Gray[900]
                                : COLORS.UTIL.Gray.WARM
                        }
                        zIndex={1}
                        width={PX.SPACING.PX.S}
                        height={PX.SPACING.PX.S}
                        borderRadius="100px"
                    />
                </FlexRow>
            </FlexRow>
            {props.children}
        </chakra.label>
    );
};
