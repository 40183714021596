import { createContext } from 'react';
import { OnboardingState } from './onboarding.model';
import { OnboardingStatusContext as ContextType } from 'hooks/dbHooks';

export const OnboardingContext = createContext(
    {} as OnboardingState | undefined,
);

export const OnboardingStatusContext = createContext(
    {} as ContextType | undefined,
);
