import {
    Tabs as StandardTabs,
    Tab,
    TabIndicator,
    TabList,
    TabPanel,
    TabPanels,
    TabsProps,
} from '@chakra-ui/react';
import COLORS from 'ui/colors';
import FONTS from 'ui/fonts';
import { WarningIcon } from 'ui/icons';
import { FlexRow } from 'ui/loulaFlex';
import PX from 'ui/px';

export const Tabs = ({
    tabHeaders,
    tabPanels,
    tabAlerts,
    boldHeaders = false,
    ...props
}: Partial<TabsProps> & {
    boldHeaders?: boolean;
    tabHeaders: string[];
    tabAlerts?: boolean[];
    tabPanels: React.ReactNode[];
}) => {
    return (
        <StandardTabs {...props} variant="unstyled">
            <TabList paddingX={PX.SPACING.PX.M} paddingTop={PX.SPACING.PX.M}>
                {tabHeaders.map((headerText, idx) => (
                    <Tab key={`tab_${idx}_header`} textAlign="center">
                        <FlexRow
                            justify="center"
                            align="center"
                            gap={PX.SPACING.PX.XS}
                        >
                            {tabAlerts?.[idx] && (
                                <WarningIcon
                                    boxSize={5}
                                    color={COLORS.UTIL.WarningOrange[700]}
                                />
                            )}
                            <FONTS.P1 fontWeight={boldHeaders ? 700 : 400}>
                                {headerText}
                            </FONTS.P1>
                        </FlexRow>
                    </Tab>
                ))}
            </TabList>
            <TabIndicator height="5px" bg={COLORS.UTIL.Gray[900]} />
            <TabPanels borderTop={`1px solid ${COLORS.UTIL.Gray[500]}`}>
                {tabPanels.map((panelElement, idx) => (
                    <TabPanel key={`tabPanel_${idx}`}>{panelElement}</TabPanel>
                ))}
            </TabPanels>
        </StandardTabs>
    );
};
