import { extendTheme } from '@chakra-ui/react';
import { Global } from '@emotion/react';

export const BREAKPOINTS = {
    SM: 240,
    MD: 480,
    LG: 768,
    XL: 992,
    XXL: 1280,
};

const breakpoints = {
    base: '0px',
    sm: `${BREAKPOINTS.SM}px`,
    md: `${BREAKPOINTS.MD}px`,
    lg: `${BREAKPOINTS.LG}px`,
    xl: `${BREAKPOINTS.XL}px`,
    '2xl': `${BREAKPOINTS.XXL}px`,
};

// 3. Extend the theme
const theme = extendTheme({
    breakpoints,
    fonts: {
        heading: 'DM Sans',
        body: 'DM Sans',
    },
});

export default theme;

export const Fonts = () => (
    <Global
        styles={`
    
            @font-face {
            font-family: 'DM Sans';
            src: url('/fonts/DMSans-Thin.woff2') format('woff2'),
                url('/fonts/DMSans-Thin.woff') format('woff');
            font-weight: 100;
            font-style: normal;
            font-display: swap;
            }

            @font-face {
                font-family: 'DM Sans';
                src: url('/fonts/DMSans-Regular.woff2') format('woff2'),
                    url('/fonts/DMSans-Regular.woff') format('woff');
                font-weight: normal;
                font-style: normal;
                font-display: swap;
            }

            @font-face {
                font-family: 'DM Sans';
                src: url('/fonts/DMSans-SemiBold.woff2') format('woff2'),
                    url('/fonts/DMSans-SemiBold.woff') format('woff');
                font-weight: 600;
                font-style: normal;
                font-display: swap;
            }

            @font-face {
                font-family: 'DM Sans';
                src: url('/fonts/DMSans-Medium.woff2') format('woff2'),
                    url('/fonts/DMSans-Medium.woff') format('woff');
                font-weight: 500;
                font-style: normal;
                font-display: swap;
            }

            @font-face {
                font-family: 'DM Sans';
                src: url('/fonts/DMSans-ExtraBold.woff2') format('woff2'),
                    url('/fonts/DMSans-ExtraBold.woff') format('woff');
                font-weight: bold;
                font-style: normal;
                font-display: swap;
            }

            @font-face {
                font-family: 'DM Sans';
                src: url('/fonts/DMSans-Bold.woff2') format('woff2'),
                    url('/fonts/DMSans-Bold.woff') format('woff');
                font-weight: bold;
                font-style: normal;
                font-display: swap;
            }

            @font-face {
                font-family: 'DM Sans';
                src: url('//fonts/DMSans-Black.woff2') format('woff2'),
                    url('/fonts/DMSans-Black.woff') format('woff');
                font-weight: 900;
                font-style: normal;
                font-display: swap;
            }

            @font-face {
                font-family: 'DM Sans';
                src: url('/fonts/DMSans-ExtraLight.woff2') format('woff2'),
                    url('/fonts/DMSans-ExtraLight.woff') format('woff');
                font-weight: 200;
                font-style: normal;
                font-display: swap;
            }

            @font-face {
                font-family: 'DM Sans';
                src: url('/fonts/DMSans-Light.woff2') format('woff2'),
                    url('/fonts/DMSans-Light.woff') format('woff');
                font-weight: 300;
                font-style: normal;
                font-display: swap;
            }
    `}
    />
);
