import { Button, HStack, Text, VStack } from '@chakra-ui/react';
import { ReactElement, useCallback, useState } from 'react';
import ReactJson from 'react-json-view';
import {
    AdonisClaim,
    ClaimModel,
    CreateClaimResponse,
    VisitModel,
} from '../../models/schemas';
import { useAuth0 } from '@auth0/auth0-react';
import { authorize } from '../../api/API';

const testDefaultVisit = {
    id: 'test_visit_1',
    patientUserId: 'e6846466-ec2f-4d70-a56f-789ebd95088b',
    practitionerUserId: 'd3b44385-c59b-4fcf-8744-2bbc6c12b6ef',
    code: 'code',
    dateOfService: new Date().toISOString(),
    placeOfService: 1,
    visitType: 'visitType',
    durationMinutes: 1,
    notes: 'notes',
    createdAt: 'createdAt',
};

//const testDefaultClaim = {
// id: 'mina_test_1',
// practitionerUserId: 'practicioner',
// patientUserId: 'patient',
// status: 'status',
// result: 'result',
// totalCharges: 'totalCharges',
// serviceLines: [
//     {
//         procedureCode: 'procedureCode',
//         procedureModifier: 'procedureModifier',
//         quantity: 1,
//         charges: 1,
//         diagnosisPointers: ['1'],
//         dateOfService: 'dateOfService',
//     },
// ],
// diagnoses: [
//     {
//         codeType: 'codeType',
//         code: 'code',
//     },
// ],
// placeOfService: 1,
// createdAt: 'createdAt',
//}

export function AdonisDecoupleTestingUI(): ReactElement | null {
    /*
     * In an ideal world, this is a one-stop place for testing
     * as I dev out the Adonis decoupling. This would include
     * things like:
     * - Create a basic visit
     * - Turn the basic visit into a basic claim
     * - Check that the updates with Adonis DB work as expected (eg patient info updates)
     * - Send the claim to Adonis
     */

    const { getAccessTokenSilently } = useAuth0();

    const [visit, setVisit] = useState<VisitModel>(testDefaultVisit);

    const [baseClaim, setBaseClaim] = useState<ClaimModel | null>(null);

    const [adonisClaim, setAdonisClaim] = useState<AdonisClaim | null>(null);

    const [adonisResponse, setAdonisResponse] =
        useState<CreateClaimResponse | null>(null);

    const generateClaim = useCallback(async () => {
        //take the visit object, create a claim, and setClaim
        const token = await getAccessTokenSilently({});

        if (token == null) {
            //console.log('TOKEN NULL');
            return;
        }

        authorize(token)
            .post('/claims/createTestClaimFromTestVisit', visit)
            .then((res): void => {
                //console.log('RES OF SENDING CLAIM PART', res);
                setBaseClaim(res.data);
            });
    }, [visit]);

    const convertClaim = useCallback(async () => {
        //take the visit object, create a claim, and setClaim
        const token = await getAccessTokenSilently({});

        if (token == null) {
            //console.log('TOKEN NULL');
            return;
        }

        authorize(token)
            .post('/claims/convertBaseClaimToAdonisClaim', baseClaim)
            .then((res): void => {
                //console.log('RES OF SENDING CLAIM PART', res);
                setAdonisClaim(res.data);
            });
    }, [baseClaim]);

    const submitClaim = useCallback(async () => {
        //take the visit object, create a claim, and setClaim
        const token = await getAccessTokenSilently({});

        if (token == null) {
            //console.log('TOKEN NULL');
            return;
        }

        authorize(token)
            .post('/claims/submitTestClaim', adonisClaim)
            .then((res): void => {
                //console.log('RES OF FAKE SUBMITTING CLAIM', res);
                setAdonisResponse(res.data);
            });
    }, [adonisClaim]);

    const restart = useCallback(() => {
        setVisit(testDefaultVisit);
        setBaseClaim(null);
        setAdonisClaim(null);
    }, []);

    return (
        <VStack w="100%">
            <HStack w="100%" overflowX="scroll">
                <VStack
                    alignItems="start"
                    justifyContent="start"
                    overflowY="scroll"
                >
                    <Text fontWeight="bold">Visit</Text>
                    <ReactJson
                        src={visit}
                        onEdit={(edit: any) => setVisit(edit.updated_src)}
                    />
                    <Button onClick={generateClaim}>Generate DB Claim</Button>
                </VStack>
                {baseClaim && (
                    <VStack
                        alignItems="start"
                        justifyContent="start"
                        overflowY="scroll"
                    >
                        <Text fontWeight="bold">Base Claim</Text>
                        <ReactJson
                            src={baseClaim}
                            onEdit={(edit: any) =>
                                setBaseClaim(edit.updated_src)
                            }
                        />
                        <Button onClick={convertClaim}>Convert To Claim</Button>
                    </VStack>
                )}
                {adonisClaim && (
                    <VStack
                        alignItems="start"
                        justifyContent="start"
                        overflowY="scroll"
                    >
                        <Text fontWeight="bold">Adonis Claim</Text>
                        <ReactJson
                            src={adonisClaim}
                            onEdit={(edit: any) =>
                                setAdonisClaim(edit.updated_src)
                            }
                        />
                        <Button onClick={submitClaim}>Test Submit Claim</Button>
                    </VStack>
                )}
            </HStack>
            <Button onClick={restart}>Restart</Button>
            {adonisResponse && (
                <VStack
                    alignItems="start"
                    justifyContent="start"
                    overflowY="scroll"
                >
                    <Text fontWeight="bold">Adonis Response</Text>
                    <ReactJson
                        src={adonisResponse}
                        onEdit={(edit: any) =>
                            setAdonisResponse(edit.updated_src)
                        }
                    />
                </VStack>
            )}
        </VStack>
    );
}
