import { useAuth0 } from '@auth0/auth0-react';
import { FormEvent, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { authorize } from '../api/API';
import MainLayout from '../layouts/MainLayout';
import {
    Box,
    Button,
    Flex,
    FormControl,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spacer,
    VStack,
    useColorModeValue,
    useDisclosure,
    useToast,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import VisitsTable from '../claims/components/VisitsTable';
import { FiPlus, FiPlusCircle } from 'react-icons/fi';
import PanelHeading from '../components/PanelHeading';

const ModalForm = ({
    handlePatientSelected,
}: {
    handlePatientSelected: (patient: any, mode: string) => void;
}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { getAccessTokenSilently } = useAuth0();

    const [options, setOptions] = useState<any[] | undefined>([]);
    const [selectedPatient, setSelectedPatient] = useState<any | undefined>(
        undefined,
    );
    const [mode, setMode] = useState<'bulk' | 'single'>('single');

    const onSubmit = (e: SubmitEvent) => {
        e.preventDefault();

        if (selectedPatient === undefined) {
            return;
        }
        handlePatientSelected(selectedPatient, mode);
        onClose();
    };

    // get list of patients
    useEffect(() => {
        (async () => {
            const token = await getAccessTokenSilently();
            authorize(token)
                .get(`/patients`)
                .then((res): void => {
                    const patients = res.data;
                    // @todo: fix any type
                    const options = patients.map((c: any) => {
                        return {
                            label: c.patient.user.preferredName
                                ? c.patient.user.preferredName
                                : c.patient.user.firstName +
                                ' ' +
                                c.patient.user.lastName,
                            value: c.patient.userId,
                        };
                    });
                    setOptions(options);
                });
        })();
    }, []);

    return (
        <Box>
            <HStack>
                <Button
                    leftIcon={<FiPlus />}
                    onClick={() => {
                        setMode('bulk');
                        onOpen();
                    }}
                    colorScheme="gray"
                >
                    Bulk Log
                </Button>
                <Button
                    onClick={() => {
                        setMode('single');
                        onOpen();
                    }}
                    colorScheme="teal"
                    leftIcon={<FiPlusCircle />}
                >
                    Log Visit
                </Button>
            </HStack>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Select Patient</ModalHeader>
                    <ModalCloseButton />
                    <form
                        onSubmit={(e: FormEvent<HTMLFormElement>) =>
                            onSubmit(e as any)
                        }
                    >
                        <ModalBody>
                            <FormControl>
                                <Select
                                    options={options}
                                    name="patient"
                                    onChange={(patient) =>
                                        setSelectedPatient(patient)
                                    }
                                />
                            </FormControl>
                        </ModalBody>

                        <ModalFooter>
                            <Button colorScheme="blue" mr={3} type="submit">
                                Start
                            </Button>
                            <Button variant="ghost" onClick={onClose}>
                                Cancel
                            </Button>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>
        </Box>
    );
};

/**
 * This is an example of a generic "Resource Display" page.
 * It uses the Medplum `<ResourceTable>` component to display a resource.
 * @returns A React component that displays a resource.
 */
export function Visits(): JSX.Element | null {
    const { getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();
    const [claims, setclaims] = useState<any[] | undefined>(undefined);

    const token = getAccessTokenSilently();
    const [searchParams] = useSearchParams();
    const toast = useToast();

    const patientCreatedToastID = 'claim-created-toast';

    useEffect(() => {
        // please fix this!
        if (token == null) {
            return;
        }

        (async () => {
            const token = await getAccessTokenSilently();
            authorize(token)
                .get(`/visits`)
                .then((res): void => {
                    const visits = res.data;
                    //console.log('visits ', visits);
                    setclaims(visits);
                });
        })();
    }, []);

    useEffect(() => {
        if (
            searchParams.get('created') &&
            !toast.isActive(patientCreatedToastID)
        ) {
            toast({
                id: patientCreatedToastID,
                title: 'Visit logged.',
                status: 'success',
                duration: 9000,
                isClosable: true,
            });
        }
    }, []);

    const handlePatientSelected = (patient: any, mode: string) => {
        //console.log('patient selected', patient);
        const url =
            mode === 'bulk'
                ? `/clients/${patient.value}/visits/bulk`
                : `/clients/${patient.value}/visits/create`;
        navigate(url);
    };

    return (
        <MainLayout>
            <HStack width="100%">
                <PanelHeading>Visits</PanelHeading>
                <Spacer />

                <ModalForm handlePatientSelected={handlePatientSelected} />
            </HStack>
            <VStack
                bg={useColorModeValue('white', 'gray.900')}
                border={'1px'}
                p={8}
                borderColor={useColorModeValue('gray.200', 'gray.700')}
                align={'start'}
                mb={6}
                mt={4}
            >
                <Box width={'100%'}>
                    <Flex flexDirection={'column'} gap={4}>
                        <HStack
                            direction="row"
                            spacing={4}
                            justify="right"
                        ></HStack>
                        <VisitsTable visits={claims} />
                    </Flex>
                </Box>
            </VStack>
        </MainLayout>
    );
}
