const xs = '0px 0px 0px 1px rgba(0, 0, 0, 0.05);';
const sm = '0px 1px 2px 0px rgba(0, 0, 0, 0.05);';
const base =
    '0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.06);';
const md =
    '0px 4px 6px -1px rgba(0, 0, 0, 0.10), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);';
const lg =
    '0px 10px 15px -3px rgba(0, 0, 0, 0.10), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);';
const xl =
    '0px 20px 25px -5px rgba(0, 0, 0, 0.10), 0px 10px 10px -5px rgba(0, 0, 0, 0.04);';
const xxl =
    '0px 0px 0px 1px rgba(0, 0, 0, 0.10), 0px 5px 10px 0px rgba(0, 0, 0, 0.20), 0px 15px 40px 0px rgba(0, 0, 0, 0.40); 0px 25px 50px -12px rgba(0, 0, 0, 0.25)';
const outline = '0px 0px 0px 3px rgba(63, 153, 225, 0.60);';
const inner = '0px 2px 4px 0px rgba(0, 0, 0, 0.06) inset;';
const darkLg =
    '0px 0px 0px 1px rgba(0, 0, 0, 0.10), 0px 5px 10px 0px rgba(0, 0, 0, 0.20), 0px 15px 40px 0px rgba(0, 0, 0, 0.40);';

const elevationOne = '-2px 3px 3px 3px rgba(186, 186, 186, 0.10);';

const sheetElevation = '-2px 2px 2px 0px rgba(174, 174, 174, 0.20);';

const SHADOWS = {
    xs,
    sm,
    base,
    md,
    lg,
    xl,
    xxl,
    outline,
    inner,
    darkLg,
    elevationOne,
    sheetElevation,
};

export default SHADOWS;
