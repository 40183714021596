export const SLIDE_MAGNITUDE = 50;
export const SLIDE_DURATION = 0.37;
export const COLLAPSE_DURATION = 0.5;

export const STATES = {
    Alabama: 'AL',
    Alaska: 'AK',
    Arizona: 'AZ',
    Arkansas: 'AR',
    California: 'CA',
    Colorado: 'CO',
    Connecticut: 'CT',
    Delaware: 'DE',
    Florida: 'FL',
    Georgia: 'GA',
    Hawaii: 'HI',
    Idaho: 'ID',
    Illinois: 'IL',
    Indiana: 'IN',
    Iowa: 'IA',
    Kansas: 'KS',
    Kentucky: 'KY',
    Louisiana: 'LA',
    Maine: 'ME',
    Maryland: 'MD',
    Massachusetts: 'MA',
    Michigan: 'MI',
    Minnesota: 'MN',
    Mississippi: 'MS',
    Missouri: 'MO',
    Montana: 'MT',
    Nebraska: 'NE',
    Nevada: 'NV',
    'New Hampshire': 'NH',
    'New Jersey': 'NJ',
    'New Mexico': 'NM',
    'New York': 'NY',
    'North Carolina': 'NC',
    'North Dakota': 'ND',
    Ohio: 'OH',
    Oklahoma: 'OK',
    Oregon: 'OR',
    Pennsylvania: 'PA',
    'Rhode Island': 'RI',
    'South Carolina': 'SC',
    'South Dakota': 'SD',
    Tennessee: 'TN',
    Texas: 'TX',
    Utah: 'UT',
    Vermont: 'VT',
    Virginia: 'VA',
    Washington: 'WA',
    'West Virginia': 'WV',
    Wisconsin: 'WI',
    Wyoming: 'WY',
};

/**
 * The counties of California
 */
export const COUNTIES = [
    'Alameda',
    'Alpine',
    'Amador',
    'Butte',
    'Calaveras',
    'Colusa',
    'Contra Costa',
    'Del Norte',
    'El Dorado',
    'Fresno',
    'Glenn',
    'Humboldt',
    'Imperial',
    'Inyo',
    'Kern',
    'Kings',
    'Lake',
    'Lassen',
    'Los Angeles',
    'Madera',
    'Marin',
    'Mariposa',
    'Mendocino',
    'Merced',
    'Modoc',
    'Mono',
    'Monterey',
    'Napa',
    'Nevada',
    'Orange',
    'Placer',
    'Plumas',
    'Riverside',
    'Sacramento',
    'San Benito',
    'San Bernardino',
    'San Diego',
    'San Francisco',
    'San Joaquin',
    'San Luis Obispo',
    'San Mateo',
    'Santa Barbara',
    'Santa Clara',
    'Santa Cruz',
    'Shasta',
    'Sierra',
    'Siskiyou',
    'Solano',
    'Sonoma',
    'Stanislaus',
    'Sutter',
    'Tehama',
    'Trinity',
    'Tulare',
    'Tuolumne',
    'Ventura',
    'Yolo',
    'Yuba',
];

export const DoulaBusinessTypeCopy = {
    SoleProvider: 'Individual Sole Provider',
    IndividualProvider: 'Incorporated Individual Provider',
    DoulaGroup: 'Doula Group Member',
};

export enum ProofOfTrainingPathway {
    TRAINING = 'training',
    XP = 'xp',
}

export enum OnboardingStatus {
    STARTED = 'started',
    SUBMITTED = 'submitted',
    UNDER_REVIEW = 'under_review',
    APPROVED = 'approved',
}

export const OnboardingProofOfTrainingPathway: Record<string, string> = {
    [ProofOfTrainingPathway.TRAINING]: 'Training Pathway',
    [ProofOfTrainingPathway.XP]: 'Experience Pathway',
};

export const SpecialChars = '@*&$%!=;"<>~`\\|';

export type FullPronouns = {
    subject: string;
    object: string;
    possessive_adj: string;
    possessive_pro: string;
    reflexive: string;
};

export const Pronouns: Record<string, FullPronouns> = {
    'he/him': {
        subject: 'he',
        object: 'him',
        possessive_adj: 'his',
        possessive_pro: 'his',
        reflexive: 'himself',
    },
    'she/her': {
        subject: 'she',
        object: 'her',
        possessive_adj: 'her',
        possessive_pro: 'hers',
        reflexive: 'herself',
    },
    'they/them': {
        subject: 'they',
        object: 'them',
        possessive_adj: 'their',
        possessive_pro: 'theirs',
        reflexive: 'themselves',
    },
};
