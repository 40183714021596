import {
    Flex,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tooltip,
    Tr,
    useRadioGroup,
} from '@chakra-ui/react';
import { COUNTIES } from 'models/constants';
import { StepComponentProps } from 'onboarding/onboarding.model';
import {
    ReactElement,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import FONTS from 'ui/fonts';
import {
    AutoComplete,
    AutoCompletePossibility,
} from 'ui/components/AutoComplete';
import { FlexCol, FlexRow } from 'ui/loulaFlex';
import { TextTag } from 'ui/components/TextTag';
import PX from 'ui/px';
import { useAllPayers } from 'hooks/dbHooks';
import { PayerModel } from 'models/schemas';
import { PrimaryButton } from 'ui/components/Button';
import { CheckBox } from 'ui/components/Checkbox';
import { Alert } from 'ui/components/Alert';
import { Radio } from 'ui/components/Radio';
import COLORS from 'ui/colors';
import { OnboardingContext } from 'onboarding/OnboardingContext';

export const ServiceAreaStep = ({
    commonStyling,
}: StepComponentProps): ReactElement => {
    const onboardingContext = useContext(OnboardingContext);

    const { formData, formInstance } = onboardingContext ?? {};
    const { setValue, watch } = formInstance ?? {};

    const { allPayers } = useAllPayers();

    const [showPlans, setShowPlans] = useState(
        (formData?.serviceCounties?.length ?? 0) > 0 &&
            (Object.keys(formData?.contractedPayerIds ?? {}).length ??
                formData?.preExistingPayerIds?.length ??
                0) > 0, //if they already picked some, have it shown already
    );

    const [showPreExistingSection, setShowPreExistingSection] = useState(
        formData?.preExistingPayerIds?.length ?? 0 > 0,
    );

    const selectedPayerIds: Record<string, boolean> = watch?.(
        'contractedPayerIds',
        formData?.contractedPayerIds ?? {},
    );

    const setSelectedPayerIds = useCallback(
        (newVal: Record<string, boolean>) => {
            setValue?.('contractedPayerIds', newVal, { shouldDirty: true });
        },
        [setValue],
    );

    const counties: string[] = watch?.(
        'serviceCounties',
        formData?.serviceCounties ?? [],
    );

    const setCounties = useCallback(
        (newVal: string[]) => {
            if (Object.keys(allPayers).length === 0) return;

            setValue?.('serviceCounties', newVal, { shouldDirty: true });

            const updatedSelectedPayerIds: Record<string, boolean> = {};

            newVal.forEach((county) => {
                Object.values(allPayers).forEach((payer) => {
                    if (payer.countiesServed.includes(county)) {
                        updatedSelectedPayerIds[payer.externalPayerId] =
                            selectedPayerIds[payer.externalPayerId] ?? true;
                    }
                });
            });

            setSelectedPayerIds(updatedSelectedPayerIds);
        },
        [allPayers, selectedPayerIds, setSelectedPayerIds, setValue],
    );

    const existingPayerIds: string[] = watch?.(
        'preExistingPayerIds',
        formData?.preExistingPayerIds ?? [],
    );

    const setExistingPayerIds = useCallback(
        (newVal: string[]) => {
            setValue?.('preExistingPayerIds', newVal, { shouldDirty: true });
        },
        [setValue],
    );

    const [hasExistingPlans, setHasExistingPlans] = useState<string>(
        existingPayerIds.length > 0 ? 'true' : '',
    );

    const { getRadioProps, getRootProps } = useRadioGroup({
        defaultValue: hasExistingPlans,
        onChange: setHasExistingPlans,
    });

    const onClickCountyInSearch = (county: string) => {
        setCounties([...counties, county]);
    };

    const deselectCounty = (county: string) => () => {
        setCounties(counties.filter((c) => c !== county));
    };

    const deselectPreExistingPayer = (payerId: string) => () => {
        setExistingPayerIds(existingPayerIds.filter((id) => id !== payerId));
    };

    const onClickPayerInSearch = (payerId: string) => {
        const payer = allPayers[payerId];

        if (!payer) return;

        const newExistingPayerIds = existingPayerIds.includes(
            payer.externalPayerId,
        )
            ? existingPayerIds
            : [...existingPayerIds, payer.externalPayerId];

        setExistingPayerIds(newExistingPayerIds);
    };

    const planPossibilities: AutoCompletePossibility[] = useMemo(() => {
        return Object.values(allPayers).map((payer) => {
            return {
                label: payer.name,
                value: payer.externalPayerId,
                disabled: existingPayerIds.includes(payer.externalPayerId),
            };
        });
    }, [existingPayerIds, allPayers]);

    const countiesPossibilities: AutoCompletePossibility[] = useMemo(() => {
        return COUNTIES.map((county) => {
            return {
                label: county,
                value: county,
                disabled: counties.includes(county),
            };
        });
    }, [counties]);

    return (
        <Flex {...commonStyling}>
            <FlexCol gap={PX.SPACING.PX.XXL} className="internalWrapper">
                <FlexCol
                    className="countySearchComponent"
                    position="relative"
                    gap={PX.SPACING.REM.S}
                >
                    <FONTS.H2>Select the counties you serve</FONTS.H2>
                    <AutoComplete
                        searchIconOnRight={true}
                        subLabel="Find the counties you serve from the look-up above. "
                        possibilities={countiesPossibilities}
                        onClickItem={onClickCountyInSearch}
                        noResultsHeader="All Counties"
                    />
                    <FlexRow gap={PX.SPACING.REM.M} wrap="wrap">
                        {counties.map((county, idx) => (
                            <TextTag
                                key={`county_tag_${idx}`}
                                text={county}
                                onClickClose={deselectCounty(county)}
                            />
                        ))}
                    </FlexRow>
                    {!showPlans && (
                        <PrimaryButton
                            disabled={counties.length === 0}
                            onClick={() => {
                                if (counties.length !== 0) {
                                    setShowPlans(true);
                                }
                            }}
                        >
                            Next
                        </PrimaryButton>
                    )}
                </FlexCol>

                {showPlans && counties.length > 0 && (
                    <FlexCol gap={PX.SPACING.REM.XL}>
                        <MCPTable
                            counties={counties}
                            selectedPayerIds={selectedPayerIds}
                            setSelectedPayerIds={setSelectedPayerIds}
                            allPayers={allPayers}
                        />

                        {showPreExistingSection ||
                        (existingPayerIds?.length ?? 0) > 0 ? (
                            <FlexCol gap={PX.SPACING.PX.L}>
                                <FlexCol gap={PX.SPACING.PX.M}>
                                    <FONTS.H2>
                                        Do you have any existing Managed Care
                                        Plan contracts?
                                    </FONTS.H2>
                                    <FONTS.P1>
                                        We are collecting which Managed Care
                                        Plans you already have contracts with
                                        for two reasons:
                                    </FONTS.P1>
                                    <FONTS.P1>
                                        1) If you're already contracted with a
                                        plan, we may be able to speed up your
                                        credentialing process.
                                    </FONTS.P1>
                                    <FONTS.P1>
                                        2) We will need to double check that
                                        your individual contract will not be
                                        canceled if you get credentialed through
                                        Loula.
                                    </FONTS.P1>
                                </FlexCol>
                                <FlexCol
                                    {...getRootProps()}
                                    gap={PX.SPACING.PX.M}
                                >
                                    <Radio
                                        {...getRadioProps({ value: 'true' })}
                                        value="true"
                                    >
                                        <FONTS.P1>
                                            Yes, I have at least one existing
                                            contract with a Managed Care Plan .
                                        </FONTS.P1>
                                    </Radio>
                                    <Radio
                                        {...getRadioProps({ value: 'false' })}
                                        value="false"
                                    >
                                        <FONTS.P1>
                                            No, I do not have any existing
                                            contracts
                                        </FONTS.P1>
                                    </Radio>
                                </FlexCol>

                                {hasExistingPlans === 'true' && (
                                    <>
                                        <AutoComplete
                                            label="Select all existing Managed Care Plan contracts"
                                            subLabel="Search for the Managed Care Plans you have contracted with from the look-up above. "
                                            possibilities={planPossibilities}
                                            onClickItem={onClickPayerInSearch}
                                            noResultsHeader="All Plans"
                                        />
                                        <FlexRow
                                            gap={PX.SPACING.REM.M}
                                            wrap="wrap"
                                        >
                                            {existingPayerIds.map(
                                                (payerId, idx) => (
                                                    <TextTag
                                                        key={`text_tag_${payerId}_${idx}`}
                                                        text={
                                                            allPayers[payerId]
                                                                ?.name ?? ''
                                                        }
                                                        onClickClose={deselectPreExistingPayer(
                                                            payerId,
                                                        )}
                                                    />
                                                ),
                                            )}
                                        </FlexRow>
                                    </>
                                )}
                            </FlexCol>
                        ) : (
                            <PrimaryButton
                                onClick={() => setShowPreExistingSection(true)}
                            >
                                Next
                            </PrimaryButton>
                        )}
                    </FlexCol>
                )}
            </FlexCol>
        </Flex>
    );
};

const MCPTable = ({
    selectedPayerIds,
    setSelectedPayerIds,
    allPayers,
    counties,
}: {
    counties: string[];
    selectedPayerIds: Record<string, boolean>;
    allPayers: Record<string, PayerModel>;
    setSelectedPayerIds: (_newVal: Record<string, boolean>) => void;
}) => {
    const [allSelected, setAllSelected] = useState(
        Object.values(selectedPayerIds).every((v) => v),
    );

    useEffect(function checkAllIfNeeded() {
        if (!allSelected) return;

        if (Object.values(selectedPayerIds).every((v) => v)) return;

        setSelectedPayerIds(
            Object.fromEntries(
                Object.keys(selectedPayerIds).map((key) => [key, true]),
            ),
        );
    });

    const onClickPlanCheckbox = (payerId: string, isChecked: boolean) => {
        setSelectedPayerIds({ ...selectedPayerIds, [payerId]: isChecked });
    };

    const getCountiesServedText = (
        countiesServed: string[],
    ): {
        countiesListed: string;
        numExtra: number;
        fullString: string | null;
    } => {
        //sort matching ones to the front
        countiesServed.sort((a, b) =>
            counties.includes(a) ? 1 : counties.includes(b) ? -1 : 0,
        );

        const numExtra = Math.max(countiesServed.length - 2, 0);
        const countiesListed = countiesServed.slice(0, 2).join(', ');
        const fullString = numExtra > 0 ? countiesServed.join(', ') : null;

        return {
            countiesListed,
            numExtra,
            fullString,
        };
    };

    return (
        <FlexCol gap={PX.SPACING.PX.L}>
            <FONTS.H2>
                Select the Managed Care Plans you want to be credentialed with
            </FONTS.H2>

            <FlexRow align="center" gap={PX.SPACING.REM.S}>
                <CheckBox
                    isChecked={allSelected}
                    onChange={(e) => setAllSelected(e.target.checked)}
                />
                <FONTS.P1>
                    Get credentialed with all{' '}
                    <b>
                        {Object.keys(selectedPayerIds).length} Managed Care
                        Plans
                    </b>{' '}
                    in the selected counties (recommended)
                </FONTS.P1>
            </FlexRow>

            <Table>
                <Thead>
                    <Tr>
                        <Th></Th>
                        <Th>
                            <FONTS.H6>Health Plan</FONTS.H6>
                        </Th>
                        <Th>
                            <FONTS.H6>Counties Served</FONTS.H6>
                        </Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {Object.values(allPayers).map((payer, idx) => {
                        if (!(payer.externalPayerId in selectedPayerIds))
                            return null;
                        const { numExtra, countiesListed, fullString } =
                            getCountiesServedText(payer.countiesServed);

                        return (
                            <Tr
                                key={`row_${idx}`}
                                opacity={allSelected ? 0.5 : 1}
                            >
                                <Td>
                                    <CheckBox
                                        isDisabled={allSelected}
                                        isChecked={
                                            selectedPayerIds[
                                                payer.externalPayerId
                                            ]
                                        }
                                        onChange={(e) =>
                                            onClickPlanCheckbox(
                                                payer.externalPayerId,
                                                e.target.checked,
                                            )
                                        }
                                    />
                                </Td>
                                <Td>
                                    <FONTS.H4>{payer.name}</FONTS.H4>
                                </Td>
                                <Td>
                                    <Tooltip
                                        placement="bottom-start"
                                        label={fullString}
                                    >
                                        <FlexRow gap={PX.SPACING.PX.S}>
                                            <FONTS.P1>
                                                {countiesListed}
                                            </FONTS.P1>
                                            {numExtra > 0 && (
                                                <FONTS.P1
                                                    color={
                                                        COLORS.UTIL.PRIMARY.Grey
                                                    }
                                                >
                                                    +{numExtra} others
                                                </FONTS.P1>
                                            )}
                                        </FlexRow>
                                    </Tooltip>
                                </Td>
                            </Tr>
                        );
                    })}
                </Tbody>
            </Table>
            <Alert content="Include Managed Care Plans you have an existing contract if you want to bill that plan through Loula. Loula will only be able to assist you with plans that you are credentialed with through Loula. We are unable assist with plans where you only have an individual contract." />
        </FlexCol>
    );
};
