import { Box, FlexProps, SlideFade } from '@chakra-ui/react';
import { SLIDE_DURATION, SLIDE_MAGNITUDE } from '../../models/constants';
import { ProgressableStep } from 'onboarding/onboarding.model';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import PX from 'ui/px';
import { OnboardingContext } from 'onboarding/OnboardingContext';

export const commonStepStyling: FlexProps = {
    className: 'onboardingStep',
    paddingX: [PX.SPACING.PX.M, null, PX.SPACING.PX.L],
    paddingTop: [PX.SPACING.PX.M, null, 0],
    paddingBottom: PX.SPACING.PX.XXL,
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    flex: 1,
    overflowX: 'hidden',
    overflowY: 'scroll',
    gap: [PX.SPACING.PX.L, null, PX.SPACING.PX.XL],
    css: { '.internalWrapper': { maxWidth: '820px' } },
};

/**
 * Component for adding an animation to the swapping of steps
 * TODO-MINA: Generalize this into a fade/swap component,
 * right now its tight wound to onboarding steps
 */
export const AnimatedStepSwapper = (): React.ReactElement => {
    const onboardingContext = useContext(OnboardingContext);

    const { steps, activeStepIdx, activeSubStepIdx, onStepFullyLoaded } =
        onboardingContext ?? {};

    const { flatSteps, flatIdx } = useMemo(
        function flattenSteps() {
            if (activeStepIdx === undefined || !steps)
                return { flatSteps: [], flatIdx: 0 };

            let flatIdx = 0;
            const flatSteps: ProgressableStep[] = [];

            if (activeStepIdx === -1) return { flatSteps, flatIdx };

            for (let i = 0; i < steps.length; i++) {
                if (activeStepIdx > i) {
                    flatIdx += steps[i].subSteps?.length ?? 1;
                } else if (activeStepIdx === i) {
                    const numSubSteps =
                        steps[i].subSteps?.length ?? 0 > 0
                            ? Math.max(activeSubStepIdx ?? 0, 0)
                            : 0;
                    flatIdx += numSubSteps;
                }

                if ((steps[i].subSteps?.length ?? 0) > 0) {
                    flatSteps.push(...(steps[i].subSteps ?? []));
                } else {
                    flatSteps.push(steps[i]);
                }
            }

            // //console.log(
            //     'FLATTENED',
            //     activeStepIdx,
            //     activeSubStepIdx,
            //     flatIdx,
            //     flatSteps,
            // );
            return { flatSteps, flatIdx };
        },
        [steps, activeStepIdx, activeSubStepIdx],
    );

    const [idxA, setIdxA] = useState(flatIdx);
    const [idxB, setIdxB] = useState(-1);
    const [queuedIdxA, setQueuedIdxA] = useState(-1);
    const [queuedIdxB, setQueuedIdxB] = useState(-1);

    const FirstStepComponent = idxA === -1 ? null : flatSteps[idxA].component;
    const SecondStepComponent = idxB === -1 ? null : flatSteps[idxB].component;

    useEffect(
        function switchStepComponentEffect() {
            if (idxB === -1 && idxA !== flatIdx) {
                setQueuedIdxB(flatIdx);
            } else if (idxA === -1 && idxB !== flatIdx) {
                setQueuedIdxA(flatIdx);
            } else {
                //console.log(
                // 'ISSUE: switching step components. idxA:',
                //     idxA,
                //     'idxB:',
                //     idxB,
                //     'flattenedStepsIdx:',
                //     flatIdx,
                // );
                onStepFullyLoaded?.();
            }
        },
        [flatIdx, idxA, idxB],
    );
    return (
        <Box width="100%" height="100%" overflowY="scroll" overflowX="hidden">
            <SlideFade
                className="QueuedComponentFade"
                offsetX={
                    idxA === -1 && idxB !== -1
                        ? queuedIdxA > idxB
                            ? SLIDE_MAGNITUDE
                            : -SLIDE_MAGNITUDE
                        : queuedIdxB > idxA
                          ? -SLIDE_MAGNITUDE
                          : SLIDE_MAGNITUDE
                }
                offsetY={0}
                in={idxA !== -1 && queuedIdxB === -1}
                onAnimationComplete={() => {
                    //setRenderedStepIdx(queuedStepIdx);
                    if (queuedIdxB === -1) return;
                    setIdxB(queuedIdxB);
                    setQueuedIdxA(-1);
                    setQueuedIdxB(-1);
                    setIdxA(-1);
                    //if (idxA !== -1) setIdxB(-1);
                }}
                transition={{
                    enter: { duration: SLIDE_DURATION / 4 },
                    exit: { duration: SLIDE_DURATION / 4 },
                }}
                style={{
                    height:
                        idxA !== -1 && queuedIdxB === -1 ? '100%' : undefined,
                }}
            >
                {FirstStepComponent && (
                    <FirstStepComponent commonStyling={commonStepStyling} />
                )}
            </SlideFade>
            <SlideFade
                offsetX={
                    idxB === -1 && idxA !== -1
                        ? queuedIdxB > idxA
                            ? SLIDE_MAGNITUDE
                            : -SLIDE_MAGNITUDE
                        : queuedIdxA > idxB
                          ? -SLIDE_MAGNITUDE
                          : SLIDE_MAGNITUDE
                }
                offsetY={0}
                className="QueuedComponentFade"
                in={idxB !== -1 && queuedIdxA === -1}
                onAnimationComplete={() => {
                    //setQueuedStepIdx(-1);
                    if (queuedIdxA === -1) return;
                    setIdxA(queuedIdxA);
                    setQueuedIdxA(-1);
                    setQueuedIdxB(-1);
                    setIdxB(-1);
                    //if (idxB !== -1) setIdxA(-1);
                }}
                transition={{
                    enter: { duration: SLIDE_DURATION / 4 },
                    exit: { duration: SLIDE_DURATION / 4 },
                }}
                style={{
                    height:
                        idxB !== -1 && queuedIdxA === -1 ? '100%' : undefined,
                }}
            >
                {SecondStepComponent && (
                    <SecondStepComponent commonStyling={commonStepStyling} />
                )}
            </SlideFade>
        </Box>
    );
};
