import {
    Box,
    Button,
    Card,
    CardBody,
    HStack,
    Stat,
    StatHelpText,
    StatLabel,
    StatNumber,
    Table,
    Tbody,
    Td,
    Tr,
    VStack,
} from '@chakra-ui/react';
import MainLayout from '../layouts/MainLayout';
import { useOutletContext, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { authorize } from '../api/API';
import {
    convertMinutesToReadable,
    getNameToShow,
    getPlaceOfServiceLabel,
    getVisitTypeLabel,
} from '../util/Utils';
import Loading from '../components/Loading';
import dayjs from 'dayjs';

export function VisitDetail(): JSX.Element | null {
    const { getAccessTokenSilently } = useAuth0();
    const { visitID } = useParams();
    const [visit, setVisit] = useState<any>(null);

    /**
     * Retrieves a visit from the server and updates the state with the retrieved visit.
     */
    const getVisit = async () => {
        const token = await getAccessTokenSilently({});
        authorize(token)
            .get(`/visits/${visitID}`)
            .then((res): void => {
                const visit = res.data;
                //console.log('visit ', visit);
                setVisit(res.data);
            });
    };

    useEffect(() => {
        getVisit();
    }, []);

    if (!visit) {
        return <Loading />;
    }

    const finalizeClaim = async () => {
        const token = await getAccessTokenSilently({});
        authorize(token)
            .get(`/visits/${visitID}/finalize`)
            .then((res): void => {
                const visit = res.data;
                //console.log('visit ', visit);
            });
    };

    return (
        <MainLayout
            backButtonRoute={'/clients'}
            headerText={visit && getNameToShow(visit.patient.user)}
        >
            <Button colorScheme={'blue'} mb={4} onClick={() => finalizeClaim()}>
                Create Claim
            </Button>
            <HStack spacing={'24px'} alignItems={'stretch'}>
                <Box flex="1">
                    <Card>
                        <CardBody>
                            <Table variant={'unstyled'}>
                                <Tbody>
                                    <Tr>
                                        <Td width={8} fontWeight={'medium'}>
                                            Ref #
                                        </Td>
                                        <Td data-hj-suppress>{visit.code}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td
                                            w="180px"
                                            fontWeight={'medium'}
                                            border={'none'}
                                        >
                                            Date of Service
                                        </Td>
                                        <Td data-hj-suppress>
                                            {dayjs(visit.dateOfService).format(
                                                'MM/DD/YYYY',
                                            )}
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td
                                            w="180px"
                                            fontWeight={'medium'}
                                            border={'none'}
                                        >
                                            Place of Service
                                        </Td>
                                        <Td data-hj-suppress>
                                            {getPlaceOfServiceLabel(
                                                visit.placeOfService,
                                            )}
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td
                                            w="180px"
                                            fontWeight={'medium'}
                                            border={'none'}
                                        >
                                            Visit Type
                                        </Td>
                                        <Td data-hj-suppress>
                                            {getVisitTypeLabel(visit.visitType)}
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td
                                            w="180px"
                                            fontWeight={'medium'}
                                            border={'none'}
                                        >
                                            Duration
                                        </Td>
                                        <Td data-hj-suppress>
                                            {convertMinutesToReadable(
                                                visit.durationMinutes,
                                            )}
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td
                                            w="180px"
                                            fontWeight={'medium'}
                                            border={'none'}
                                            verticalAlign={'top'}
                                        >
                                            Notes
                                        </Td>
                                        <Td data-hj-suppress>{visit.notes}</Td>
                                    </Tr>
                                </Tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Box>
                <VStack alignItems={'stretch'}>
                    <Card width={'150px'} flex="1">
                        <CardBody>
                            <Stat>
                                <StatLabel>Visits Logged</StatLabel>
                                <StatNumber>12</StatNumber>
                                <StatHelpText>Feb 12 - Feb 28</StatHelpText>
                            </Stat>
                        </CardBody>
                    </Card>
                </VStack>
            </HStack>
        </MainLayout>
    );
}

export function useVisit() {
    return useOutletContext<any>();
}
