import { Collapse, Flex } from '@chakra-ui/react';
import COLORS from 'ui/colors';
import PX from 'ui/px';
import { ProgressIndicator } from '../components/ProgressIndicator';

import React, { useContext } from 'react';

import { UserTag } from '../components/UserTag';
import { NumCompleteTracker } from '../components/NumCompleteTracker';
import { COLLAPSE_DURATION } from '../components/StepsWrapper';
import { nameToShow } from 'util/Utils';
import { IconButton } from 'ui/components/IconButton';
import { OnboardingContext } from '../OnboardingContext';
import { Fade } from '@chakra-ui/react';
import { HelpTag } from './HelpTag';

type SideBarComponentProps = {
    showHelpTag?: boolean;
};

export const MobileNavBar = ({ showHelpTag }: SideBarComponentProps): React.ReactElement => {
    const onboardingState = useContext(OnboardingContext);

    const { steps, numComplete, isCollapsed, setIsCollapsed, formData } =
        onboardingState ?? {};

    return (
        <Flex
            direction="column"
            width="100%"
            //flex={isCollapsed ? undefined : 1}
            className="mobileSidebar"
        >
            <Flex
                width="100%"
                justifyContent="space-between"
                alignItems="center"
                padding={`${PX.SPACING.PX.S} ${PX.SPACING.PX.S} ${PX.SPACING.PX.M} ${PX.SPACING.PX.S}`}
            >
                <IconButton
                    paddingX={PX.SPACING.PX.S}
                    aria-label="open menu"
                    icon={isCollapsed ? 'MenuIcon' : 'CloseIcon'}
                    color={COLORS.UTIL.PRIMARY.Blue}
                    onClick={() => setIsCollapsed?.(!isCollapsed)}
                />
                <NumCompleteTracker
                    numComplete={numComplete ?? 0}
                    numTotal={(steps?.length ?? 0) - 1}
                />
            </Flex>
            <Collapse
                in={!isCollapsed}
                startingHeight={1}
                transition={{
                    enter: { duration: COLLAPSE_DURATION / 2 },
                    exit: { duration: COLLAPSE_DURATION / 2 },
                }}
                style={{ overflow: 'visible' }} //So we get the benefit of size changing, but not doing awk slide
            >
                <Flex
                    //flex={1}
                    paddingX={[PX.SPACING.REM.S, PX.SPACING.REM.L]}
                    paddingBottom={0}
                    className="progressInformation"
                    direction="column"
                    gap={PX.SPACING.PX.M}
                >
                    <UserTag
                        overrideName={nameToShow(
                            formData?.firstName,
                            formData?.lastName,
                            formData?.preferredName,
                        )}
                    />
                    <ProgressIndicator isMobile={true} />
                    <Fade in={showHelpTag}>
                        <HelpTag />
                    </Fade>
                </Flex>
            </Collapse>
        </Flex>
    );
};

export const DesktopSideBar =
    ({}: SideBarComponentProps): React.ReactElement => {
        const onboardingContext = useContext(OnboardingContext);

        const { numComplete, steps, formData } = onboardingContext ?? {};

        return (
            <Flex
                direction="column"
                height="100%"
                className="sidebar"
                padding={`${PX.SPACING.REM.XL} ${PX.SPACING.REM.XL} ${PX.SPACING.REM.M} ${PX.SPACING.REM.L}`}
            >
                <Flex direction="column" gap={PX.SPACING.REM.M}>
                    <Flex
                        className="progressInformation"
                        //height="50%"
                        direction="column"
                        gap={PX.SPACING.REM.XL}
                    //overflowY="hidden"
                    >
                        <NumCompleteTracker
                            numComplete={numComplete ?? 0}
                            numTotal={(steps?.length ?? 0) - 1}
                        />
                        <ProgressIndicator isMobile={false} />
                    </Flex>
                    <HelpTag />
                </Flex>
                <UserTag
                    overrideName={nameToShow(
                        formData?.firstName,
                        formData?.lastName,
                        formData?.preferredName,
                    )}
                />
            </Flex>
        );
    };
