import { BoxProps, Flex, FlexProps } from '@chakra-ui/react';
import { UploadFileCard } from 'components/FileUploader';
import {
    ProgressableStep,
    StepComponentProps,
} from 'onboarding/onboarding.model';
import { ReactElement, useContext, useState } from 'react';
import COLORS from 'ui/colors';
import { SecondaryButton } from 'ui/components/Button';
import { Sheet } from 'ui/components/Sheet';
import { TextTag } from 'ui/components/TextTag';
import FONTS from 'ui/fonts';
import { ICONS } from 'ui/icons';
import { FlexCol, FlexRow } from 'ui/loulaFlex';
import PX from 'ui/px';
import { ResumeItemCard } from '../ResumeItemCard';
import { useAllPayers, usePractitioner } from 'hooks/dbHooks';
import { generateAddressInReadableString } from 'util/Utils';
import { OnboardingProofOfTrainingPathway } from 'models/constants';
import { OnboardingContext } from 'onboarding/OnboardingContext';

export const ReviewStep = ({
    commonStyling,
}: StepComponentProps): ReactElement => {
    const onboardingContext = useContext(OnboardingContext);

    const { practitionerData } = usePractitioner();
    const { onboardingStatus } = practitionerData ?? {};
    const isFlowComplete =
        onboardingStatus === 'submitted' ||
        onboardingStatus === 'under_review' ||
        onboardingStatus === 'approved';

    const { steps, formData, isStepComplete, goToStep, goToSubStep } =
        onboardingContext ?? {};

    const { allPayers } = useAllPayers();

    return (
        <Flex {...commonStyling}>
            <FlexCol gap={PX.SPACING.PX.XL} className="internalWrapper">
                {!isFlowComplete && (
                    <FONTS.H2>
                        Review the following sections to make sure the
                        information inputted is accurate.
                    </FONTS.H2>
                )}

                {isFlowComplete && onboardingStatus !== 'approved' && (
                    <Sheet padding={PX.SPACING.PX.L}>
                        <FlexCol gap={PX.SPACING.PX.M}>
                            <FlexRow justify="space-between">
                                <FONTS.H3>Onboarding Status</FONTS.H3>
                                <TextTag
                                    text={onboardingStatus.replace('_', ' ')}
                                />
                            </FlexRow>

                            <FONTS.P2>
                                We’re reviewing your onboarding packet. If any
                                additional materials are needed, we’ll contact
                                you at
                                <b>
                                    {' ' +
                                        (practitionerData?.user.email ??
                                            'email')}
                                </b>
                            </FONTS.P2>
                        </FlexCol>
                    </Sheet>
                )}

                {isFlowComplete && onboardingStatus === 'approved' && (
                    <Sheet padding={PX.SPACING.PX.L}>
                        <FlexCol gap={PX.SPACING.PX.M}>
                            <FlexRow justify="space-between">
                                <FONTS.H3>Onboarding Status</FONTS.H3>
                                <TextTag
                                    text={onboardingStatus.replace('_', ' ')}
                                />
                            </FlexRow>

                            <FONTS.P2>
                                Your application has been reviewed and you have
                                been approved to join Loula!
                            </FONTS.P2>

                            <FONTS.P2>
                                We are now ready to begin your credentialing
                                process with the Managed Care Plans. When you
                                have been credentialed with your first Managed
                                Care Plan, we will notify you via email so you
                                can start seeing clients for that plan right
                                away!
                            </FONTS.P2>
                        </FlexCol>
                    </Sheet>
                )}

                <StepReviewInfo
                    isComplete={
                        (isStepComplete && steps && isStepComplete(steps[0])) ??
                        false
                    }
                    step={steps?.[0]}
                    onClickEdit={goToStep?.(0)}
                    isFlowComplete={isFlowComplete}
                >
                    <StepField fieldName="Legal First Name">
                        {formData?.firstName && (
                            <FONTS.P1>{formData?.firstName}</FONTS.P1>
                        )}
                    </StepField>
                    <StepField fieldName="Legal Last Name">
                        {formData?.lastName && (
                            <FONTS.P1>{formData?.lastName}</FONTS.P1>
                        )}
                    </StepField>
                    <StepField isRequired={false} fieldName="Middle Name">
                        {formData?.middleName && (
                            <FONTS.P1>{formData?.middleName}</FONTS.P1>
                        )}
                    </StepField>
                    <StepField isRequired={false} fieldName="Preferred Name">
                        {formData?.preferredName && (
                            <FONTS.P1>{formData?.preferredName}</FONTS.P1>
                        )}
                    </StepField>
                    <StepField fieldName="Pronouns">
                        {Object.entries(formData?.pronouns ?? {}).filter(
                            ([, selected]) => !!selected,
                        ).length > 0 && (
                            <FONTS.P1>
                                {Object.entries(formData?.pronouns ?? {})
                                    .filter(([, selected]) => !!selected)
                                    .map(([key]) => key)
                                    .join(', ')}
                            </FONTS.P1>
                        )}
                    </StepField>
                    <StepField fieldName="Legal Last Name">
                        {formData?.lastName && (
                            <FONTS.P1>{formData?.lastName}</FONTS.P1>
                        )}
                    </StepField>
                    <StepField fieldName="Date of Birth">
                        {formData?.dob && <FONTS.P1>{formData?.dob}</FONTS.P1>}
                    </StepField>
                    <StepField fieldName="Social Security Number">
                        {formData?.tin && (
                            <HiddenTextField
                                rawText={formData?.tin.toString()}
                            />
                        )}
                    </StepField>
                    <StepField fieldName="NPI Number">
                        {formData?.npi && <FONTS.P1>{formData?.npi}</FONTS.P1>}
                    </StepField>
                    <StepField fieldName="Phone Number">
                        {formData?.phone && (
                            <FONTS.P1>{formData?.phone}</FONTS.P1>
                        )}
                    </StepField>

                    <StepField fieldName="Personal Mailing Address">
                        {(formData?.personalAddress?.address1?.length ?? 0) >
                            0 && (
                            <FONTS.P1>
                                {generateAddressInReadableString(
                                    formData?.personalAddress,
                                )}
                            </FONTS.P1>
                        )}
                    </StepField>
                </StepReviewInfo>

                <StepReviewInfo
                    isComplete={
                        (isStepComplete && steps && isStepComplete(steps[1])) ??
                        false
                    }
                    step={steps?.[1]}
                    onClickEdit={goToStep?.(1)}
                    isFlowComplete={isFlowComplete}
                >
                    <StepField fieldName="Business Name">
                        {formData?.isNameSameAsPersonal ? (
                            <FONTS.P1>
                                {formData?.firstName} {formData?.lastName}
                            </FONTS.P1>
                        ) : (
                            <FONTS.P1>{formData?.businessName}</FONTS.P1>
                        )}
                    </StepField>

                    <StepField fieldName="TIN or EIN">
                        {formData?.hasNoBusinessTin ? (
                            <FONTS.P1>I do not have a TIN or EIN</FONTS.P1>
                        ) : (formData?.businessTIN?.length ?? 0) > 0 ? (
                            <FONTS.P1>{formData?.businessTIN}</FONTS.P1>
                        ) : null}
                    </StepField>

                    <StepField fieldName="Business Mailing Address">
                        {formData?.isAddressSameAsPersonal
                            ? generateAddressInReadableString(
                                  formData.personalAddress,
                              )
                            : generateAddressInReadableString(
                                  formData?.businessAddress,
                              ) && (
                                  <FONTS.P1>
                                      {generateAddressInReadableString(
                                          formData?.businessAddress,
                                      )}
                                  </FONTS.P1>
                              )}
                    </StepField>
                </StepReviewInfo>

                <StepReviewInfo
                    isComplete={
                        (isStepComplete && steps && isStepComplete(steps[2])) ??
                        false
                    }
                    step={steps?.[2]}
                    onClickEdit={goToStep?.(2)}
                    isFlowComplete={isFlowComplete}
                >
                    <StepField fieldName="Counties serving" flexBasis="100%">
                        <FlexRow gap={PX.SPACING.PX.S} wrap="wrap">
                            {formData?.serviceCounties?.map(
                                (countyName, idx) => (
                                    <TextTag
                                        key={`county_${countyName}_${idx}`}
                                        width="fit-content"
                                        text={countyName}
                                    />
                                ),
                            )}
                        </FlexRow>
                    </StepField>

                    <StepField fieldName="Managed Care Plans Selected for Credentialing">
                        {formData?.contractedPayerIds && (
                            <FlexCol gap={PX.SPACING.PX.XS}>
                                {Object.entries(formData?.contractedPayerIds)
                                    .filter(([, selected]) => selected)
                                    .map(([payerId]) => (
                                        <FONTS.P1 key={payerId}>
                                            {allPayers[payerId]?.name}
                                        </FONTS.P1>
                                    ))}
                            </FlexCol>
                        )}
                    </StepField>

                    <StepField fieldName="Existing Contracts Managed Care Plan">
                        {formData?.preExistingPayerIds &&
                        formData?.preExistingPayerIds.length > 0 ? (
                            <FlexCol gap={PX.SPACING.PX.XS}>
                                {formData?.preExistingPayerIds?.map(
                                    (payerId) => (
                                        <FONTS.P1 key={`payer_name_${payerId}`}>
                                            {allPayers[payerId]?.name}
                                        </FONTS.P1>
                                    ),
                                )}
                            </FlexCol>
                        ) : (
                            <FlexCol gap={PX.SPACING.PX.XS}>
                                <FONTS.P1>
                                    {'I do not have any existing contracts'}
                                </FONTS.P1>
                            </FlexCol>
                        )}
                    </StepField>
                </StepReviewInfo>

                <StepReviewInfo
                    isComplete={
                        (isStepComplete && steps && isStepComplete(steps[3])) ??
                        false
                    }
                    step={steps?.[3]}
                    onClickEdit={goToStep?.(3)}
                    isFlowComplete={isFlowComplete}
                >
                    <SubStepReviewInfo
                        subStep={steps?.[3].subSteps?.[0]}
                        onClickEdit={goToSubStep?.(3, 0)}
                        isFlowComplete={isFlowComplete}
                    >
                        <StepField
                            isUpload={true}
                            fieldName="Medi-Cal Provider Enrollment Approval Letter"
                        >
                            {formData?.mediCalEnrollmentFile &&
                                formData?.mediCalEnrollmentFile.map(
                                    (
                                        {
                                            originalFileName,
                                            fileSize,
                                            temporaryFileName,
                                        },
                                        idx,
                                    ) => (
                                        <UploadFileCard
                                            key={`upload_card_${temporaryFileName}_${idx}`}
                                            fileName={originalFileName}
                                            fileSize={fileSize}
                                            tempFileName={temporaryFileName}
                                        />
                                    ),
                                )}
                        </StepField>

                        <StepField isUpload={true} fieldName="CPR Certificate">
                            {formData?.cprCertificate &&
                                formData?.cprCertificate.map(
                                    (
                                        {
                                            originalFileName,
                                            fileSize,
                                            temporaryFileName,
                                        },
                                        idx,
                                    ) => (
                                        <UploadFileCard
                                            key={`upload_card_${temporaryFileName}_${idx}`}
                                            tempFileName={temporaryFileName}
                                            fileName={originalFileName}
                                            fileSize={fileSize}
                                        />
                                    ),
                                )}
                        </StepField>
                    </SubStepReviewInfo>

                    <SubStepReviewInfo
                        subStep={steps?.[3].subSteps?.[1]}
                        onClickEdit={goToSubStep?.(3, 1)}
                        isFlowComplete={isFlowComplete}
                    >
                        <StepField
                            isUpload={false}
                            fieldName="Pathway"
                            flexBasis="100%"
                        >
                            {formData?.proofOfTrainingPathway && (
                                <FONTS.P1>
                                    {
                                        OnboardingProofOfTrainingPathway[
                                            formData?.proofOfTrainingPathway ??
                                                ''
                                        ]
                                    }
                                </FONTS.P1>
                            )}
                        </StepField>

                        {formData?.proofOfTrainingPathway && (
                            <StepField
                                isUpload={true}
                                fieldName="Documentation"
                                flexBasis="100%"
                            >
                                {(formData?.proofOfTrainingPathway === 'xp'
                                    ? formData?.lettersOfRecommendation
                                    : formData?.syllabus
                                )?.map(
                                    (
                                        {
                                            originalFileName,
                                            fileSize,
                                            temporaryFileName,
                                        },
                                        idx,
                                    ) => (
                                        <UploadFileCard
                                            key={`upload_card_${temporaryFileName}_${idx}`}
                                            tempFileName={temporaryFileName}
                                            fileName={originalFileName}
                                            fileSize={fileSize}
                                        />
                                    ),
                                )}
                            </StepField>
                        )}
                    </SubStepReviewInfo>

                    <SubStepReviewInfo
                        subStep={steps?.[3].subSteps?.[2]}
                        onClickEdit={goToSubStep?.(3, 2)}
                        isFlowComplete={isFlowComplete}
                    >
                        <StepField
                            isUpload={true}
                            fieldName="Professional Liability Coverage"
                        >
                            {formData?.proofOfLiabilityInsurance &&
                                formData?.proofOfLiabilityInsurance.map(
                                    (
                                        {
                                            originalFileName,
                                            fileSize,
                                            temporaryFileName,
                                        },
                                        idx,
                                    ) => (
                                        <UploadFileCard
                                            key={`upload_card_${temporaryFileName}_${idx}`}
                                            tempFileName={temporaryFileName}
                                            fileName={originalFileName}
                                            fileSize={fileSize}
                                        />
                                    ),
                                )}
                        </StepField>
                        <StepField isUpload={true} fieldName="HIPAA Training">
                            {formData?.proofOfHIPAATraining &&
                                formData?.proofOfHIPAATraining.map(
                                    (
                                        {
                                            originalFileName,
                                            fileSize,
                                            temporaryFileName,
                                        },
                                        idx,
                                    ) => (
                                        <UploadFileCard
                                            key={`upload_card_${temporaryFileName}_${idx}`}
                                            tempFileName={temporaryFileName}
                                            fileName={originalFileName}
                                            fileSize={fileSize}
                                        />
                                    ),
                                )}
                        </StepField>
                    </SubStepReviewInfo>
                </StepReviewInfo>

                <StepReviewInfo
                    isComplete={
                        (isStepComplete && steps && isStepComplete(steps[4])) ??
                        false
                    }
                    step={steps?.[4]}
                    onClickEdit={goToStep?.(4)}
                    isFlowComplete={isFlowComplete}
                >
                    {(formData?.resumeFile?.length ?? 0) > 0 && (
                        <StepField isUpload={true} fieldName="Resume">
                            {formData?.resumeFile &&
                                formData?.resumeFile.map(
                                    (
                                        {
                                            originalFileName,
                                            fileSize,
                                            temporaryFileName,
                                        },
                                        idx,
                                    ) => (
                                        <UploadFileCard
                                            key={`upload_card_${temporaryFileName}_${idx}`}
                                            tempFileName={temporaryFileName}
                                            fileName={originalFileName}
                                            fileSize={fileSize}
                                        />
                                    ),
                                )}
                        </StepField>
                    )}
                    {(formData?.resumeItems?.length ?? 0) > 0 && (
                        <FlexRow width="100%" gap={PX.SPACING.PX.S} wrap="wrap">
                            {formData?.resumeItems?.map((resumeItem, idx) => (
                                <ResumeItemCard
                                    key={`resume_item_card_${resumeItem.title}_${idx}`}
                                    flex={1}
                                    flexBasis="100%"
                                    item={resumeItem}
                                />
                            ))}
                        </FlexRow>
                    )}
                    {(formData?.resumeFile?.length ?? 0) === 0 &&
                        (formData?.resumeItems?.length ?? 0) === 0 && (
                            <ValueMissingWarning />
                        )}
                </StepReviewInfo>

                <StepReviewInfo
                    isComplete={
                        (isStepComplete && steps && isStepComplete(steps[5])) ??
                        false
                    }
                    step={steps?.[5]}
                    onClickEdit={goToStep?.(5)}
                    isFlowComplete={isFlowComplete}
                >
                    <FlexRow gap={PX.SPACING.PX.XS}>
                        {formData?.identityVerificationStatus &&
                        formData?.identityVerificationStatus !==
                            'needs_input' &&
                        formData?.identityVerificationStatus !== 'abandoned' ? (
                            <ICONS.CheckMarkIcon
                                boxSize={5}
                                color={COLORS.UTIL.Green[700]}
                            />
                        ) : (
                            <ICONS.WarningXIcon
                                boxSize={6}
                                color={COLORS.UTIL.Red[700]}
                            />
                        )}
                        <FONTS.P2>
                            {formData?.identityVerificationStatus ===
                                'verified' && 'Identity verified via Stripe'}
                            {formData?.identityVerificationStatus ===
                                'needs_input' &&
                                'Identity verification needs input'}
                            {formData?.identityVerificationStatus ===
                                'initiated' &&
                                'Identity verification initiated'}
                            {(!formData?.identityVerificationStatus ||
                                formData?.identityVerificationStatus ===
                                    'abandoned') &&
                                'Identity not yet verified via Stripe'}
                        </FONTS.P2>
                    </FlexRow>
                </StepReviewInfo>
            </FlexCol>
        </Flex>
    );
};

const ValueMissingWarning = ({ isUpload = false }: { isUpload?: boolean }) => {
    return (
        <FlexRow gap={PX.SPACING.PX.XS} align="center">
            <ICONS.WarningIcon color={COLORS.UTIL.Red[700]} boxSize={5} />
            <FONTS.P1 color={COLORS.UTIL.Red[700]}>
                {isUpload ? 'Upload' : 'Response'} Required
            </FONTS.P1>
        </FlexRow>
    );
};

const StepField = ({
    fieldName,
    isRequired = true,
    children,
    isUpload = false,
    ...props
}: FlexProps & {
    isUpload?: boolean;
    fieldName: string;
    isRequired?: boolean;
}) => {
    return (
        <FlexCol
            minHeight={PX.SPACING.PX.XXL}
            flex={1}
            flexBasis={'40%'}
            gap={PX.SPACING.PX.XS}
            {...props}
        >
            <FONTS.P2 color={COLORS.STROKES.HEAVY}>
                {fieldName} {isRequired ? '' : '(optional)'}
            </FONTS.P2>
            {isRequired && !children ? (
                <ValueMissingWarning isUpload={isUpload} />
            ) : (
                children
            )}
        </FlexCol>
    );
};

const StepReviewInfo = ({
    isComplete,
    step,
    onClickEdit,
    children,
    isFlowComplete = false,
    ...props
}: BoxProps & {
    onClickEdit: (() => void) | undefined;
    isComplete: boolean;
    step: ProgressableStep | undefined;
    isFlowComplete?: boolean;
}) => {
    const hasSubsteps = step?.subSteps?.length ?? 0 > 0;
    return (
        <Sheet padding={PX.SPACING.PX.L} {...props}>
            <FlexCol gap={PX.SPACING.PX.M}>
                {!isComplete && (
                    <FlexRow
                        background={COLORS.UTIL.Red[200]}
                        borderRadius={PX.RADII.MD}
                        padding={PX.SPACING.PX.M}
                        gap={PX.SPACING.PX.M}
                    >
                        <ICONS.WarningIcon
                            color={COLORS.UTIL.Red[700]}
                            boxSize={5}
                        />
                        <FONTS.P1 color={COLORS.UTIL.Red[800]}>
                            This section is incomplete
                        </FONTS.P1>
                    </FlexRow>
                )}

                <FlexRow justify="space-between">
                    <FONTS.H3>{step?.title}</FONTS.H3>
                    {!hasSubsteps && !isFlowComplete && (
                        <SecondaryButton onClick={onClickEdit}>
                            Edit
                        </SecondaryButton>
                    )}
                </FlexRow>

                <FlexRow wrap="wrap" rowGap={PX.SPACING.PX.M}>
                    {children}
                </FlexRow>
            </FlexCol>
        </Sheet>
    );
};

const SubStepReviewInfo = ({
    subStep,
    onClickEdit,
    children,
    isFlowComplete = false,
    ...props
}: BoxProps & {
    onClickEdit: (() => void) | undefined;
    subStep: ProgressableStep | undefined;
    isFlowComplete?: boolean;
}) => {
    if (!subStep) return null;

    return (
        <FlexCol gap={PX.SPACING.PX.L} width="100%" {...props}>
            <FlexRow align="center" justify="space-between">
                <FONTS.H4>{subStep.title}</FONTS.H4>
                {!isFlowComplete && (
                    <SecondaryButton onClick={onClickEdit}>
                        Edit
                    </SecondaryButton>
                )}
            </FlexRow>

            <FlexRow wrap="wrap" gap={PX.SPACING.PX.M}>
                {children}
            </FlexRow>
        </FlexCol>
    );
};

export const HiddenTextField = ({ rawText }: { rawText: string }) => {
    const [isShowing, setIsShowing] = useState(false);
    const hiddenText = rawText.replaceAll(/[^\s]/g, '•');

    return (
        <FlexRow align="center" gap={PX.SPACING.PX.S}>
            <FONTS.P1>{isShowing ? rawText : hiddenText}</FONTS.P1>
            <FONTS.P2
                cursor="pointer"
                textDecorationLine="underline"
                color={COLORS.UTIL.PRIMARY.Blue}
                onClick={() => setIsShowing((prev) => !prev)}
            >
                {isShowing ? 'Hide' : 'View'}
            </FONTS.P2>
        </FlexRow>
    );
};
