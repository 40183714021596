import { useAuth0 } from '@auth0/auth0-react';
import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { authorize } from '../api/API';
import MainLayout from '../layouts/MainLayout';
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    HStack,
    Skeleton,
    Spacer,
    Switch,
    VStack,
    useColorModeValue,
    useToast,
} from '@chakra-ui/react';
import { FiPlusCircle } from 'react-icons/fi';
import { DataTable } from '../components/DataTable';
import { createColumnHelper } from '@tanstack/react-table';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Link as ChakraLink } from '@chakra-ui/react';
import { getNameToShow } from '../util/Utils';
import PanelHeading from '../components/PanelHeading';
import dayjs from 'dayjs';
import { PatientReturnType } from '../models/schemas';

/**
 * This is an example of a generic "Resource Display" page.
 * It uses the Medplum `<ResourceTable>` component to display a resource.
 * @returns A React component that displays a resource.
 */
export function Patients(): JSX.Element | null {
    //console.log('render');
    const { getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();
    const [patients, setPatients] = useState<PatientReturnType[] | null>(null);
    const [patientsTableData, setPatientsTableData] = useState<any[]>([]);

    const token = getAccessTokenSilently();
    const [searchParams] = useSearchParams();
    const toast = useToast();

    const patientCreatedToastID = 'patient-created-toast';

    useEffect(() => {
        // please fix this!
        if (token == null) {
            return;
        }

        (async () => {
            const token = await getAccessTokenSilently({});
            //console.log('token ', token);
            authorize(token)
                .get(`/patients`)
                .then((res): void => {
                    const patients = res.data;
                    //console.log('patients ', patients);
                    setPatients(patients);
                    setPatientsTableData(
                        patients.filter(
                            (patient: PatientReturnType) => patient.isActive,
                        ),
                    );
                });
        })();
    }, []);

    useEffect(() => {
        if (!patients) {
            return;
        }
        if (
            searchParams.get('created') &&
            !toast.isActive(patientCreatedToastID)
        ) {
            const createdPatient = patients.slice(-1)[0];
            toast({
                id: patientCreatedToastID,
                title: `Patient created: ${getNameToShow(createdPatient.user)}`,
                status: 'success',
                duration: 9000,
                isClosable: true,
            });
        }
    }, [searchParams, patients]);

    const columnHelper = createColumnHelper<PatientReturnType>();

    const columns = [
        columnHelper.accessor(
            (patient: PatientReturnType) => getNameToShow(patient.user),
            {
                id: 'fullName',
                header: 'Name',
                cell: (props) => (
                    <ChakraLink
                        as={ReactRouterLink}
                        to={props.row.original.userId + '/overview'}
                    >
                        {props.getValue()}
                    </ChakraLink>
                ),
            },
        ),
        columnHelper.accessor((patient) => patient.user.email, {
            id: 'email',
            header: 'Email',
        }),
        columnHelper.accessor(
            (patient) => (patient.isActive ? 'Active' : 'Inactive'),
            {
                id: 'status',
                header: 'Status',
            },
        ),
        columnHelper.accessor(
            (patient) =>
                patient.deliveryDate
                    ? dayjs(patient.deliveryDate).format('MM/DD/YYYY')
                    : '--',
            {
                id: 'deliveryDate',
                header: 'Delivery Date',
            },
        ),
    ];

    const handleShowInactive = (event: ChangeEvent<HTMLInputElement>) => {
        //console.log(event.target.checked);

        if (patients) {
            event.target.checked
                ? setPatientsTableData(patients)
                : setPatientsTableData(
                    patients?.filter(
                        (patient: PatientReturnType) => patient.isActive,
                    ) ?? [],
                );
        }
    };

    return (
        <MainLayout>
            <HStack width={'100%'}>
                <PanelHeading>Clients</PanelHeading>
                <Spacer />
                <HStack gap={6}>
                    <FormControl display="flex" alignItems="center">
                        <FormLabel htmlFor="email-alerts" mb="0">
                            Show Inactive
                        </FormLabel>
                        <Switch
                            id="show-inactive"
                            onChange={handleShowInactive}
                        />
                    </FormControl>
                    <Box>
                        {' '}
                        <Button
                            leftIcon={<FiPlusCircle />}
                            onClick={() => navigate('/clients/create')}
                            colorScheme="teal"
                            variant="solid"
                            data-cy="add-patient-button"
                        >
                            Add Client
                        </Button>
                    </Box>
                </HStack>
            </HStack>
            <VStack
                bg={useColorModeValue('white', 'gray.900')}
                border={'1px'}
                p={8}
                borderColor={useColorModeValue('gray.200', 'gray.700')}
                align={'start'}
                mb={6}
                mt={4}
            >
                <Box width={'100%'}>
                    <Flex flexDirection={'column'} gap={4}>
                        <HStack
                            direction="row"
                            spacing={4}
                            justify="right"
                        ></HStack>
                        <Skeleton isLoaded={patients !== undefined}>
                            <DataTable
                                data-hj-suppress
                                columns={columns}
                                data={patientsTableData ?? []}
                            />
                        </Skeleton>
                    </Flex>
                </Box>
            </VStack>
        </MainLayout>
    );
}
