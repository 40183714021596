import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { authorize } from '../api/API';
import MainLayout from '../layouts/MainLayout';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    HStack,
    Heading,
    SkeletonText,
    Text,
    VStack,
    useColorModeValue,
} from '@chakra-ui/react';
import PanelHeading from '../components/PanelHeading';
import { getNameToShow, getTotalChargesString } from '../util/Utils';
import { AdonisClaim, ClaimReturnType } from '../models/schemas';
import ReactJson from 'react-json-view';

export function ClaimDetail(): JSX.Element {
    const { getAccessTokenSilently } = useAuth0();
    // const [claims, setClaims] = useState<any[] | undefined>(undefined);

    const token = getAccessTokenSilently();

    const [claim, setClaim] = useState<ClaimReturnType | null>(null);
    const [adonisFormattedClaim, setAddonisFormattedClaim] =
        useState<AdonisClaim | null>(null);
    const [adonisResponse, setAdonisResponse] = useState<any | null>(null);
    const { claimId } = useParams();

    useEffect(() => {
        // please fix this!
        if (token == null) {
            return;
        }

        (async () => {
            const token = await getAccessTokenSilently({});
            authorize(token)
                .get(`/claims/${claimId}`)
                .then((res): void => {
                    const claim = res.data;
                    //console.log('claim ', claim);
                    setClaim(res.data);
                });
        })();
    }, []);

    const getAdonisFormattedClaim = async () => {
        const token = await getAccessTokenSilently({});
        authorize(token)
            .post('/claims/convertBaseClaimToAdonisClaim', claim)
            .then((res): void => {
                //console.log('RES OF SENDING CLAIM PART', res);
                setAddonisFormattedClaim(res.data);
            });
    };

    const sendClaimToAdonis = async () => {
        const token = await getAccessTokenSilently({});
        authorize(token)
            .post('/claims/submitTestClaim', adonisFormattedClaim)
            .then((res): void => {
                setAdonisResponse(res.data);
            });
    };

    return (
        <MainLayout>
            <VStack
                bg={useColorModeValue('white', 'gray.900')}
                border={'1px'}
                p={8}
                borderColor={useColorModeValue('gray.200', 'gray.700')}
                align={'start'}
            >
                <HStack width="100%">
                    <PanelHeading>Claim: {claim?.id}</PanelHeading>
                </HStack>

                <SkeletonText isLoaded={!!claim} width="100%">
                    <HStack width="100%" mt={4}>
                        <Card width="100%">
                            <CardHeader>
                                <Heading size={'md'}>
                                    Client:{' '}
                                    {getNameToShow(claim?.patient?.user)}
                                </Heading>
                            </CardHeader>
                            <CardBody>
                                <Text>Status: {claim?.status}</Text>
                                <Text>
                                    Total Charges:{' '}
                                    {getTotalChargesString(claim?.serviceLines)}
                                </Text>
                                {claim?.serviceLines.map((line, index) => (
                                    <VStack
                                        alignItems="start"
                                        key={`service_line_${index}`}
                                    >
                                        <Text
                                            key={`service_line_date_${index}`}
                                        >
                                            Date Of Service Start For Service
                                            Line {index + 1}:{' '}
                                            {line.dateOfService}
                                        </Text>
                                        <Text
                                            key={`service_location_date_${index}`}
                                        >
                                            Place of Procedure Code Line{' '}
                                            {index + 1}: {line.placeOfService}
                                        </Text>
                                    </VStack>
                                ))}
                            </CardBody>
                        </Card>
                    </HStack>
                </SkeletonText>

                <Button onClick={getAdonisFormattedClaim}>
                    Format for Adonis
                </Button>

                {adonisFormattedClaim && (
                    <SkeletonText
                        isLoaded={!!adonisFormattedClaim}
                        width="100%"
                    >
                        <HStack width="100%" mt={4}>
                            <Card width="100%">
                                <CardBody>
                                    <ReactJson
                                        src={adonisFormattedClaim}
                                        onEdit={(edit: any) =>
                                            setAddonisFormattedClaim(
                                                edit.updated_src,
                                            )
                                        }
                                    />
                                </CardBody>
                            </Card>
                        </HStack>
                    </SkeletonText>
                )}
                {adonisFormattedClaim && (
                    <Button onClick={sendClaimToAdonis}>Send to Adonis</Button>
                )}

                {adonisResponse && (
                    <SkeletonText isLoaded={!!adonisResponse} width="100%">
                        <HStack width="100%" mt={4}>
                            <Card width="100%">
                                <CardBody>
                                    <ReactJson
                                        src={adonisResponse}
                                        onEdit={(edit: any) =>
                                            setAdonisResponse(edit.updated_src)
                                        }
                                    />
                                </CardBody>
                            </Card>
                        </HStack>
                    </SkeletonText>
                )}
            </VStack>
        </MainLayout>
    );
}
