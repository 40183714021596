import { useAuth0 } from '@auth0/auth0-react';
import {
    Button,
    Center,
    Text,
    VStack,
    useColorModeValue,
} from '@chakra-ui/react';
import { authorize } from '../../api/API';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export function PaymentTab(): JSX.Element {
    const { getAccessTokenSilently } = useAuth0();
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);

    const getProfileFn = (token: string) =>
        authorize(token)
            .get(`/providers/me`)
            .then((res) => {
                return res.data;
            });

    const handleSetupPayments = async () => {
        const token = await getAccessTokenSilently();

        setLoading(true);

        const user = await getProfileFn(token);
        const type = user.user.stripePayoutsEnabled
            ? 'account_update'
            : 'account_onboarding';

        return authorize(token)
            .get(`/stripe/onboard?type=${type}`)
            .then((res): void => {
                const url = res.data;
                //console.log(url);
                window.location.href = url;
            })
            .catch((err): void => {
                console.error(err);
                setLoading(false);
            });
    };
    return (
        <VStack
            bg={useColorModeValue('white', 'gray.900')}
            border={'1px'}
            p={8}
            borderColor={useColorModeValue('gray.200', 'gray.700')}
            align={'start'}
            w={'full'}
            alignItems={'center'}
        >
            {searchParams.get('action') == 'return' ? (
                <Text>Thanks, that's all we need for now.</Text>
            ) : (
                <VStack gap={4}>
                    <Text>
                        We use Stripe to securely manage your payments. You'll
                        be redirected to Stripe to complete the setup.
                    </Text>
                    <Button
                        colorScheme="teal"
                        onClick={handleSetupPayments}
                        isLoading={loading}
                    >
                        Setup Payments
                    </Button>
                </VStack>
            )}
        </VStack>
    );
}
