/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement, useMemo, useRef, useState } from 'react';
import COLORS from 'ui/colors';
import { TextInput, TextInputProps } from 'ui/components/TextInput';
import FONTS from 'ui/fonts';
import { FlexCol, FlexRow } from 'ui/loulaFlex';
import PX from 'ui/px';
import SHADOWS from 'ui/shadows';
import { useClickOutside } from 'hooks/useClickOutside';
import { FlexProps } from '@chakra-ui/react';

export type AutoCompletePossibility = {
    label: string;
    value: any;
    disabled?: boolean;
};

export const AutoComplete = ({
    possibilities,
    onClickItem,
    filterFn,
    sortFn,
    clearOnClick = true,
    label,
    subLabel,
    isLoading,
    searchIconOnRight = false,
    noResultsHeader = 'All Options',
    onInputChange,
    ...props
}: TextInputProps & {
    isLoading?: boolean;
    possibilities: AutoCompletePossibility[];
    onClickItem?: (_itemValue: any) => void;
    filterFn?: (_value: string, _item: string) => boolean;
    sortFn?: (
        _a: AutoCompletePossibility,
        _b: AutoCompletePossibility,
    ) => number;
    clearOnClick?: boolean;
    searchIconOnRight?: boolean;
    noResultsHeader?: string;
    onInputChange?: (_value: string) => void;
}): React.ReactElement => {
    const [focusedIdx, setFocusedIdx] = useState<number | null>(null);

    const [showSuggestions, setShowSuggestions] = useState(false);
    const [value, setValue] = useState('');

    const ref = useRef<HTMLDivElement>(null);
    useClickOutside(ref, () => setShowSuggestions(false));

    const suggestions: { label: string; value: string }[] = useMemo(() => {
        if (value.length === 0) {
            setShowSuggestions(false);
            return [];
        }

        const simplifiedValue = value.toLowerCase().trim();

        const standardFilterFn = (_value: string, item: string) =>
            item.toLowerCase().includes(simplifiedValue);

        const standardSortFn = (
            a: AutoCompletePossibility,
            b: AutoCompletePossibility,
        ) =>
            a.value.toLowerCase().indexOf(simplifiedValue) >
            b.value.toLowerCase().indexOf(simplifiedValue)
                ? 1
                : -1;

        return possibilities
            .filter((item) => {
                setShowSuggestions(true);
                return !!filterFn
                    ? filterFn?.(value, item.label)
                    : standardFilterFn(value, item.label);
            })
            .sort(!!sortFn ? sortFn : standardSortFn);
    }, [filterFn, possibilities, sortFn, value]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onClickSuggestion = (item: AutoCompletePossibility) => () => {
        setShowSuggestions(false);
        setValue(item.label);
        onClickItem?.(item.value);
        clearOnClick && setValue('');
    };

    const onInputBlur = () => {
        if (focusedIdx === null) {
            setShowSuggestions(false);
        }
    };

    const onInputFocus = () => {
        setShowSuggestions(true);
    };

    return (
        <FlexCol
            position="relative"
            width="100%"
            height="fit-content"
            gap={PX.SPACING.REM.S}
        >
            {label && <FONTS.P1 fontWeight={500}>{label}</FONTS.P1>}
            <FlexCol position="relative" className="inputContainer">
                <TextInput
                    onBlur={onInputBlur}
                    value={value}
                    onChange={(e) => {
                        onInputChange?.(e.target.value);
                        setValue(e.target.value);
                    }}
                    onFocus={onInputFocus}
                    iconLeft={searchIconOnRight ? undefined : 'SearchIcon'}
                    iconRight={searchIconOnRight ? 'SearchIcon' : undefined}
                    position="relative"
                    width="100%"
                    {...props}
                />
                {showSuggestions && (
                    <FlexCol
                        position="absolute"
                        top="42px"
                        className="autoCompleteSuggestions"
                        maxHeight={240}
                        overflowY="scroll"
                        width="100%"
                        zIndex={2}
                        boxShadow={SHADOWS.md}
                        onMouseLeave={() => setFocusedIdx(null)}
                    >
                        {!isLoading &&
                            suggestions.length === 0 &&
                            value.length > 0 && (
                                <>
                                    <SuggestionItem
                                        item={{
                                            label: 'No results found',
                                            value: '',
                                        }}
                                        labelColor={COLORS.UTIL.PRIMARY.Grey}
                                        idx={-1}
                                        setFocusedIdx={setFocusedIdx}
                                        borderBottom={`1px solid ${COLORS.UTIL.Gray[500]}`}
                                    />
                                    <SuggestionItem
                                        label={
                                            <FONTS.H6
                                                color={COLORS.UTIL.PRIMARY.Grey}
                                            >
                                                {noResultsHeader}
                                            </FONTS.H6>
                                        }
                                        labelColor={COLORS.UTIL.PRIMARY.Grey}
                                        idx={-1}
                                        setFocusedIdx={setFocusedIdx}
                                    />
                                    {possibilities.map((item, idx) => (
                                        <SuggestionItem
                                            key={`suggestion_${item.label}_${idx}`}
                                            item={item}
                                            idx={idx}
                                            onClickSuggestion={
                                                onClickSuggestion
                                            }
                                            setFocusedIdx={setFocusedIdx}
                                        />
                                    ))}
                                </>
                            )}
                        {!isLoading &&
                            suggestions.map((item, idx) => (
                                <SuggestionItem
                                    key={`item_${item.label}_${idx}`}
                                    item={item}
                                    idx={idx}
                                    onClickSuggestion={onClickSuggestion}
                                    setFocusedIdx={setFocusedIdx}
                                />
                            ))}
                        {isLoading && (
                            <SuggestionItem
                                item={{ value: '', label: 'Loading...' }}
                                idx={0}
                                setFocusedIdx={setFocusedIdx}
                            />
                        )}
                    </FlexCol>
                )}
            </FlexCol>
            {subLabel && <FONTS.P2>{subLabel}</FONTS.P2>}
        </FlexCol>
    );
};

const SuggestionItem = ({
    labelColor,
    label,
    item,
    onClickSuggestion,
    setFocusedIdx,
    idx,
    ...props
}: FlexProps & {
    labelColor?: string;
    label?: ReactElement;
    item?: AutoCompletePossibility;
    setFocusedIdx: (_val: number) => void;
    onClickSuggestion?: (_item: AutoCompletePossibility) => () => void;
    idx: number;
}) => {
    const isInteractive = !item?.disabled && !!onClickSuggestion;

    return (
        <FlexRow
            key={`suggestion-auto-complete-${idx}`}
            alignItems="start"
            onMouseEnter={() => setFocusedIdx(idx)}
            background={
                item?.disabled ? COLORS.UTIL.Gray[300] : COLORS.UTIL.Gray.LIGHT
            }
            boxShadow={SHADOWS.md}
            padding={`${PX.SPACING.REM.S} ${PX.SPACING.REM.M}`}
            transition="all 0.2s ease-in"
            cursor={isInteractive ? 'pointer' : 'default'}
            _hover={
                isInteractive
                    ? { background: COLORS.UTIL.Gray[400] }
                    : undefined
            }
            _active={
                isInteractive
                    ? {
                          background: COLORS.UTIL.Gray[500],
                          boxShadow: SHADOWS.lg,
                      }
                    : undefined
            }
            onClick={
                !!item && !item?.disabled
                    ? onClickSuggestion?.(item)
                    : undefined
            }
            {...props}
        >
            {label || (
                <FONTS.P1
                    color={isInteractive ? labelColor : COLORS.UTIL.Gray[700]}
                >
                    {item?.label}
                </FONTS.P1>
            )}
        </FlexRow>
    );
};
