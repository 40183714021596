import { Flex } from '@chakra-ui/react';
import COLORS from 'ui/colors';
import FONTS from 'ui/fonts';
import PX from 'ui/px';
import React from 'react';
import { NumBadge } from 'ui/components/NumBadge';

export const NumCompleteTracker = ({
    numComplete,
    numTotal,
}: {
    numComplete: number;
    numTotal: number;
}): React.ReactElement => {
    return (
        <Flex
            minHeight={[PX.SPACING.REM.XL, null, PX.SPACING.REM.XXL]}
            border={`1px solid ${COLORS.UTIL.Gray[500]}`}
            padding={[
                `${PX.SPACING.PX.XS} ${PX.SPACING.PX.SM}`,
                null,
                PX.SPACING.PX.M,
            ]}
            background={COLORS.UTIL.Gray.WARM}
            borderRadius={PX.RADII.LG}
            gap={PX.SPACING.REM.XS}
            alignItems="center"
        >
            <NumBadge num={numComplete} />
            <FONTS.H4 color={COLORS.UTIL.PRIMARY.Blue}>
                of {numTotal} sections completed
            </FONTS.H4>
        </Flex>
    );
};
